<h1 mat-dialog-title class="title"> {{ translationBaseKey + '.' + data.titleResourceKey | translate:{parameter:
  data.toBeMatchedValue} }} </h1>
<div mat-dialog-content class="content">
  <p>{{ translationBaseKey + '.ConfirmInstruction' | translate:{'parameter': data.toBeMatchedName} }}:</p>

  <mat-form-field appearance="outline">
    <input matInput [(ngModel)]="enteredValue" (ngModelChange)="enteredValue_Changed($event)">
  </mat-form-field>

</div>

<div mat-dialog-actions>
  <button mat-button (click)="onYesClick()" [disabled]="yesButtonDisabled">{{ translationBaseKey + '.YesButton'
    | translate }}</button>
  <button mat-button (click)="onNoClick()" cdkFocusInitial>{{ translationBaseKey + '.NoButton' | translate
    }}</button>
</div>