import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TitleService {

  private titleTranslateKeySubject: BehaviorSubject<string> = new BehaviorSubject<string>('App.Title');

  public titleTranslateKeyChanges: Observable<string> = this.titleTranslateKeySubject.asObservable();

  public updateTitleTranslateKey(newTitleTranslateKey: string): void {
    this.titleTranslateKeySubject.next(newTitleTranslateKey);
  }
}
