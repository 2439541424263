import { PagingInformation } from './common.service.models';

export interface User {
  userName?: string;
  displayName: string;
  givenName: string;
  surname: string;
  email: string;
  phone?: string;
  requestedOrganization?: string;
  requestedFacility?: string;
  userDetailId: number;
  externalObjectId: string;
  isApproved?: boolean;
  createdDate: Date;
  b2cCreatedDate: Date;
  lastModifiedDate: string;
  group?: Role;
  organizationId?: number;
  additionalOrganizationIds?: number[];
  facilityId?: number;
  lastModifiedBy?: string;
  facility?: Facility;
  organization?: Organization;
  additionalOrganizations?: Organization[];
  notes?:string;
}

export interface Role {
  objectId: string;
  displayName: string;
}

export interface Address {
  addressId: number;
  streetAddress1?: string;
  streetAddress2?: string;
  streetAddress3?: string;
  city?: string;
  zipCode?: string;
  state_StateId?: number;
  county_CountyId?: number;
  country_CountryId?: number;
  lastModifiedBy?: string;
  state?: State;
  country?: Country;
  county?: County;
}

export interface AddressSuggestion {
  text: string;
  street: string;
  city: string;
  state_StateId: number;
  state?: State;
}

export interface AddressSuggestionRequest {
  streetAddress: string;
}

export interface AddressRequest {
  streetAddress1: string;
  city: string;
  zipCode: string;
  countyName?: string;
  countryCode: string;
  stateCode: string;
}

export interface Organization {
  organizationId: number;
  name: string;
  description?: string;
  phone?: string;
  contactName?: string;
  mobilePhone?: string;
  email?: string;
  address?: Address;
  address_AddressId?: number;
  rejectPatientId: boolean;
  rejectStudyCodes: boolean;
  nationalMapSubscription: boolean;
  rejectOrderNumbers: boolean;
  organizationDistributor_OrganizationDistributorId?: number;
  lastModifiedDate: Date;
  lastModifiedBy: string;
  shareNational: boolean;
  shareState: boolean;
}

export interface Facility {
  facilityId: number;
  name: string;
  address_AddressId?: number;
  address?: Address;
  organization_OrganizationId: number;
  facilityDescription_FacilityDescriptionId: number;
  contactName: string;
  contactEmail: string;
  contactWorkPhone: string;
  contactMobilePhone: string;
  crmId?: string;
  lastModifiedBy: string;
  lastModifiedDate: Date;
  organization?: Organization;
  facilityDescription?: FacilityDescription;
  latitude?: number;
  longitude?: number;
}

export interface FacilityDescription {
  facilityDescriptionId: number;
  sortOrder: number;
  description: string;
}

export interface TestType {
  testTypeId: number;
  testName: string;
  color?: string;
  symbol?: string;
  symbolRotation?: number;
}

export interface SampleType {
  sampleTypeId: number;
  code: string;
  name: string;
  color: string;
}

export enum SampleTypeId {
  Patient = 1,
  QualityControl = 2,
  Calibration = 3
}

export interface Country {
  countryId: number;
  name: string;
  code: string;
}

export interface State {
  stateId: number;
  name: string;
  country_CountryId?: number;
  abbreviation?: string;
}

export interface County {
  countyId: number;
  name: string;
}

export interface CountiesSelection {
  [key: number]: County[];
}

export interface FacilitiesSelection {
  [key: number]: Facility[];
}

export interface InitDataSelectionLists {
  Analytes: AnalyteDetail[];
  TestTypes: TestType[];
  SampleTypes: SampleType[];
  InstrumentTypes: InstrumentType[];
  Organizations: Organization[];
  Countries: Country[];
}

export interface Features {
  WriteEnabled: boolean;
  FunctionImportEnabled: boolean;
  B2CModificationsEnabled: boolean;
  TestResultsDeleteEnabled: boolean;
}

export interface OrganizationDistributor {
  organizationDistributorId: string;
  sortOrder?: number;
  distributor: string;
}

export interface InstrumentSearchResultsPage {
  pagingInformation: PagingInformation;
  totalResults: number;
  instruments: Instrument[];
}

export interface Instrument {
  instrumentId: number;
  registrationDate?: unknown;
  serialNumber: string;
  comment?: string;
  active: boolean;
  contactName?: string;
  contactEmail?: string;
  contactPhone?: string;
  zipCodeOverrides?: string;
  instrumentType_InstrumentTypeId?: number;
  facility_FacilityId?: number;
  pairedDevice_PairedDeviceId?: number;
  instrumentType?: InstrumentType;
  facility?: Facility;
  pairedDevice?: PairedDevice;
}

export interface InstrumentType {
  instrumentTypeId: number;
  name: string;
  code: string;
  serialNumberPrefix?: string;
  description: string;
}

export enum InstrumentTypeId {
  Sofia = 1,
  Solana = 2,
  Savana = 3,
  Sofia2 = 4,
  QuickVue = 5,
  SofiaQ = 6
}

export interface InstrumentResultOrphanSummary {
  instrumentSerialNumber: string;
  originatingDeviceAddress: string;
  zipCode: string;
  zipCodeOverrides?: string;
  facilityZipCode?: string;
  organizationName?: string;
  resultCount: number;
}

export interface InstrumentResultOrphan {
  instrumentResultOrphanId: number;
  resultOrigin: string;
  storageDateUtc: Date;
  instrumentSerialNumber: string;
  instrumentTypeCode: string;
  originatingDeviceAddress: string;
  zipCode: string;
  resultBlobUri: string;
  errorCode: string;
  orphanReason: string;
  errorMessage?: string;
  instrument?: Instrument;
  pairedDevice?: PairedDevice;
}

export interface IdbRecord {
  loadDate: Date;
  instrumentItemNumber: string;
  instrumentDescription?: string;
  instrumentProductClass?: string;
  serialNumber: string;
  installationGroup?: string;
  status?: string;
  hardwareItemNumber?: string;
  hardwareDescription?: string;
  softwareItemNumber?: string;
  softwareDescription?: string;
  addressCode?: string;
  shippingSalutation?: string;
  shippingStreetNumber?: string;
  shippingStreetName?: string;
  shippingCity?: string;
  shippingState?: string;
  shippingZipCode?: string;
  shippingCountry?: string;
  shippingDate?: Date;
  imei?: string;
  iccId?: string;
  devId?: string;
  wmId?: string;
  emId?: string;
  slsOrd?: string;
  slsPos?: number;
  crmId?: string;
  optIn?: number;
  actSts?: number;
  contId?: string;
  shippingBuilding?: string;
  shippingUnit?: string;
}

export interface PairedDevice {
  pairedDeviceId: number;
  deviceAddress: string;
  pairedDeviceType_PairedDeviceTypeId?: number;
  macAddress?: string;
  description?: string;
  facility_FacilityId?: number;
  pairedDeviceType?: PairedDeviceType;
  facility?: Facility;
}

export interface PairedDeviceSelection {
  pairedDeviceId: number;
  deviceAddress: string;
}

export interface PairedDeviceType {
  pairedDeviceTypeId: number;
  name: string;
}

export enum PairedDeviceTypeId {
  WR21 = 1,
  WR11 = 2,
  Sofia2 = 3
}

export interface AssayType {
  assayTypeId: number;
  code: string;
  description: string;
}

export interface LoincCode {
  loincCodeId: number;
  code: string;
  description: string;
}

export interface SnomedCode {
  snomedCodeId: number;
  code: string;
  description: string;
}

export interface Analyte {
  analyteId: number;
  analyteName: string;
  positiveSnomed: string;
  negativeSnomed: string;
  invalidSnomed: string;
}

export interface AnalyteResultCode {
  analyteResultCodeId: number;
  analyteId: number;
  resultCodeId: number;
  snomedCode_SnomedCodeId: number;
  snomedCode: SnomedCode;
}

export interface AnalyteDetail {
  analyteId: number;
  name: string;
  testType_TestTypeId: number;
  positiveColor: string;
  negativeColor: string;
  symbol?: string;
  symbolRotation?: number;
  analyteResultCodes: AnalyteResultCode[];
}

export interface PatientSampleTypesResponse {
  testTypeId: number;
  testName: string;
  assayType_AssayTypeId: number;
  sampleType_SampleTypeId: number;
  loincCode_LoincCodeId: number;
  exportToPublicHealth: boolean;
  color: string;
  symbol: string;
  borderColor: string;
  assayType: AssayType;
  sampleType: SampleType;
  loincCode: LoincCode;
  symbolRotation?: number;
  analytes: AnalyteDetail[];
}

export interface PatientSampleType {
  testTypeId: number;
  testName: string;
  assayTypeId: number;
  assayTypeCode: string;
  loincCodeId: number;
  loincCode: string;
  exportToPublicHealth: boolean;
  analytes: Analyte[];
}

export interface UserFavorite {
  userFavoriteId?: number;
  name: string;
  created?: Date;
  userDetail_UserDetailId: number;
  shareWithOrg: boolean;
  filterState: FilterState;
  lastModifiedDate?: Date;
  schedule?: UserFavoriteSchedule;
}

export interface UserFavoriteSchedule {
  userFavoriteScheduleId?: number;
  userFavorites_UserFavoriteId?: number;
  userDetail_UserDetailId: number;
  lastScheduledRunDate?: Date;
  scheduleFrequency: string;
  emails: string;
  outputTypes: string;
  lastModifiedDate?: Date;
}

export interface FilterState {
  schemaVersion: string;
  dates?: FilterStateDates;
  assaysIds?: number[];
  resultsIds?: number[];
  orgFac?: FilterStateOrgFac;
  orgsFacs?: FilterStateOrgFac[];
  geo?: FilterStateGeo;
  operator?: string;
  zipCode?: string;
  serial?: string;
}

export interface FilterStateDates {
  lastDays?: FilterStateDatesLast;
  fixed?: FilterStateDatesFixed;
}

export interface FilterStateDatesLast {
  units: number;
  timeRange: string;
}

export interface FilterStateDatesFixed {
  dateFrom: Date;
  dateTo: Date;
}

export interface FilterStateGeo {
  countryId: number;
  statesIds?: number[];
  countiesIds?: { [key: number]: number[] };
}

export interface FilterStateOrgFac {
  orgId: number;
  facilitiesIds?: number[];
}

// export interface FilterStateOrgsFacs
// {
//   orgFacs: FilterStateOrgFac[]
// }

export enum UpdateInstrumentTestResultsType {
  StorageDate,
  RunDate
}

export enum UpdateTestResultsRequestType {
  Instrument,
  Facility,
  Organization
}

export interface UpdateTestResultsRequest {
  id: number;
  startTime: Date;
  endTime?: Date;
  updateType: UpdateInstrumentTestResultsType;
  updateRequestType: UpdateTestResultsRequestType;
}

export interface ResultsApiIdentifier {
  resultsApiIdentifierId: string;
  name: string;
  facility: Facility;
  myVirenaEnabled: boolean;
  comment?: string;
  token?: string;
  instrumentTypeIds?: number[];
  containerExpiration?: number;
  allowPHI?: boolean;
}

export interface InsertApplicationIdentifierRequest {
  name: string;
  facilityId: number;
  myVirenaEnabled: boolean;
  comment?: string;
  instrumentTypeIds?: number[];
  containerExpiration?: number;
  allowPHI?: boolean;
}

export interface UpdateApplicationIdentifierRequest {
  resultsApiId: string;
  name: string;
  facilityId: number;
  myVirenaEnabled: boolean;
  comment?: string;
  instrumentTypeIds?: number[];
  containerExpiration?: number;
  allowPHI?: boolean;
}

export interface SaveNotesRequest {
  id: string;
  notes? : string;
}

export interface InstrumentPairedDevice {
  instrumentId?: number;
  serialNumber: string;
  instrument_FacilityId?: number;
  instrument_FacilityName: string;
  pairedDeviceId?: number;
  deviceAddress: string;
  pairedDevice_FacilityId?: number;
  pairedDevice_FacilityName: string;
}
