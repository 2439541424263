<div *ngIf="isOpened" class="main-container">

    <div class="container">
        <div class="title">
            <span>{{'FilterCustomGeo.Country' | translate}}</span>
            <i class="material-icons clear flipped" (click)="clearCountryFilter()">refresh</i>
        </div>

        <cdk-virtual-scroll-viewport itemSize="48" class="inner-scrollbar-container">
            <ng-container *cdkVirtualFor="let i of countries">

                <div *ngIf="!i.isHidden" class="submenu-item filter-option">
                    <div (click)="selectCountry(i)" class="submenu-item-container">
                        <div class="label">
                            {{ i.getLabel() }}
                        </div>
                        <div class="submenu-item-state" [class.has-filter-selected]="i.isSelected">
                        </div>
                    </div>
                </div>

            </ng-container>
        </cdk-virtual-scroll-viewport>
    </div>

    <div class="container" style="width: 200px;" *ngIf="availableStates && availableStates.length > 0">
        <div class="title no-left-border">
            <span>{{'FilterCustomGeo.State' | translate}}</span>
            <i class="material-icons clear flipped" (click)="clearStateFilter()">refresh</i>
        </div>

        <cdk-virtual-scroll-viewport itemSize="48" class="inner-scrollbar-container no-left-border">
            <ng-container *cdkVirtualFor="let i of availableStates">

                <div *ngIf="!i.isHidden" class="submenu-item filter-option">
                    <div (click)="selectState(i)" class="submenu-item-container sub-container">
                        <div class="label">
                            {{ i.getLabel() }}
                        </div>
                        <div class="submenu-item-state" [class.has-filter-selected]="i.isSelected">
                        </div>
                    </div>
                </div>

            </ng-container>
        </cdk-virtual-scroll-viewport>

    </div>

    <div class="container" style="width: 200px;" *ngIf="availableCounties && availableCounties.size > 0">
        <div class="title no-left-border">
            <span>{{'FilterCustomGeo.County' | translate}}</span>
            <i class="material-icons clear flipped" (click)="clearCountyFilter()">refresh</i>
        </div>

        <div class="inner-scrollbar-container county-container no-left-border">
            <ng-container *ngFor="let entry of availableCounties | keyvalue">
                <p class="county-group">{{entry.key}}</p>
                <ng-container *ngFor="let i of entry.value">
                    <div *ngIf="!i.isHidden" class="submenu-item filter-option">
                        <div (click)="selectCounty(i)" class="submenu-item-container sub-container">
                            <div class="label">
                                {{ i.getLabel() }}
                            </div>
                            <div class="submenu-item-state" [class.has-filter-selected]="i.isSelected">
                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div>

</div>
