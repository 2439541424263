import { MatPaginatorIntl } from '@angular/material/paginator';
import { Injectable } from '@angular/core';

@Injectable()
export class TestResultsPaginator extends MatPaginatorIntl {
    getRangeLabel = (page, pageSize, length) => {
        if (length === 0 || pageSize === 0) {
            return `0 of ${length}`;
        }

        length = Math.max(length, 0);

        const startIndex = page * pageSize;
        const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

        const lengthDisplay = length > 10000 ? `>${Number(10000).toLocaleString()}` : length.toLocaleString();

        return `${startIndex + 1} – ${endIndex} of ${lengthDisplay}`;
    }
}
