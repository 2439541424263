<div *ngIf="isOpened" class="main-container">

    <div class="container">
        <div class="title">
            <span>{{'FilterCustomOrgsFacilities.Organizations' | translate}}</span>
            <i class="material-icons clear flipped" (click)="clearOrganizationFilter()">refresh</i>
        </div>

        <div class="search-container">
            <input class="search" placeholder="Search..." (input)="onOrgSearchChanged($event.target.value)" />
        </div>

        <div class="selected-org-toggle-container" *ngIf="query.getSelectedOrganizations()?.length > 0">
            <div class="submenu-item filter-option">
                <div style="display: table-cell; vertical-align: middle; text-align: center">
                    <div class="submenu-item-container">
                            <mat-slide-toggle
                                class="label"
                                color="primary"
                                [checked]="filterSelectedOnly"
                                (change)="toggleFilterSelectedOnly()">
                                {{'FilterCustomOrgsFacilities.ViewSelectedOnly' | translate}}
                            </mat-slide-toggle>
                    </div>
                </div>
            </div>
        </div>

        <cdk-virtual-scroll-viewport itemSize="48" class="inner-scrollbar-container">
            <ng-container *cdkVirtualFor="let i of availableOrganizations">

                <div *ngIf="!i.isHidden" class="submenu-item filter-option">
                    <div (click)="selectOrganization(i)" class="submenu-item-container sub-container">
                        <div class="label">
                            {{ i.getLabel() }}
                        </div>
                        <div class="submenu-item-state" [class.has-filter-selected]="i.isSelected">
                        </div>
                    </div>
                </div>

            </ng-container>
        </cdk-virtual-scroll-viewport>

    </div>

    <div class="container" *ngIf="availableFacilities && availableFacilities.size > 0">
        <div class="title no-left-border">
            <span>{{'FilterCustomOrgsFacilities.Facilities' | translate}}</span>
            <i class="material-icons clear flipped" (click)="clearFacilityFilter()">refresh</i>
        </div>

        <div class="inner-scrollbar-container county-container no-left-border">
            <ng-container *ngFor="let entry of availableFacilities | keyvalue">
                <p class="facility-group">{{entry.key}}</p>
                <!-- Question: show the group name if no facilities exist for this Org? *ngIf="entry.value && entry.value.size > 0"-->
                <ng-container *ngFor="let i of entry.value">
                    <div *ngIf="!i.isHidden" class="submenu-item filter-option">
                        <div (click)="selectFacility(i)" class="submenu-item-container sub-container">
                            <div class="label">
                                {{ i.getLabel() }}
                            </div>
                            <div class="submenu-item-state" [class.has-filter-selected]="i.isSelected">
                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div>

</div>
