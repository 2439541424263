import { IGetLabel, UIQuerySimpleFilter, FilterElement, IGetId, IEquatable } from '../FilterElement';
import { Injectable, OnDestroy } from '@angular/core';

export class ZipCodeItem implements IGetLabel, IGetId, IEquatable<ZipCodeItem> {

  zipCode: string;

  constructor() {
    this.zipCode = '';
  }

  getLabel(): string {
    return '';
  }

  getId(): number {
    return 1;
  }

  equals(other: ZipCodeItem): boolean {
    return this.zipCode === other.zipCode;
  }

  toString(): string {
    return this.zipCode;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ZipCodeUIQuery extends UIQuerySimpleFilter<ZipCodeItem> implements OnDestroy {

  constructor() {
    super();

    this.initialize();
  }

  public getZipCode(): FilterElement<ZipCodeItem> {
    return this.items[0];
  }

  protected initialize(): void {
    this.items = [
      FilterElement.Build(new ZipCodeItem())
    ];

    this.setDefaults();
  }

  public reset(): void {
    this.initialize();
    this.resetOccurred.next();
  }

  protected setDefaults(selectedItems: FilterElement<ZipCodeItem>[] = null): void {
    this.setDefaultsItems(selectedItems);

    this.defaultItems.forEach(i => {
      if (i) {
        if (i.item) {
          const zipCode = i.item.zipCode;
          i.item = new ZipCodeItem();
          i.item.zipCode = zipCode;
        }
      }
    });
  }

  public setDefaultState(zipCodeValue: string): void {

    const zipCode = this.getZipCode();

    if (zipCodeValue) {
      zipCode.isSelected = true;
      zipCode.item.zipCode = zipCodeValue;
    } else {
      zipCode.isSelected = false;
      zipCode.item.zipCode = '';
    }

    this.setDefaults();
  }

  public serialize(): string {
    const zipCode = this.getZipCode();
    if (zipCode && zipCode.isSelected) {
      return zipCode.item.toString();
    } else {
      return undefined;
    }
  }
}
