import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription, Subject, Observable } from 'rxjs';
import { AssaysUIQuery } from '../components/filters/filter-custom-assays/filter-custom-assays.model';
import { ResultTypesUIQuery } from '../components/filters/result-types-custom-filter/result-types-custom-filter.model';
import { GeoUIQuery } from '../components/filters/filter-custom-geo/filter-custom-geo.model';
import { DateUIQuery } from '../components/filters/filter-custom-date/filter-custom-date.model';
import { OperatorUIQuery } from '../components/filters/filter-custom-operator/filter-custom-operator.model';
import { ZipCodeUIQuery } from '../components/filters/filter-custom-zipcode/filter-custom-zipcode.model';
import { SerialNumberUIQuery } from '../components/filters/filter-custom-serial-number/filter-custom-serial-number.model';
import { FavoritesUIQuery, FavoriteItem } from '../components/filters/filter-custom-favorites/filter-custom-favorites.model';
import { FilterState, SampleTypeId } from './metadata.service.models';
import { ConfirmationModalInput, ConfirmationModalComponent } from '../components/confirmation-modal/confirmation-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { ReportKeys } from '../utils/report.keys';
import { OrgsFacilitiesUIQuery } from '../components/filters/filter-custom-orgs-facilities/filter-custom-orgs-facilities.model';

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  private favoritesStream: BehaviorSubject<FavoritesUIQuery> = new BehaviorSubject<FavoritesUIQuery>(undefined);
  private dateStream: BehaviorSubject<DateUIQuery> = new BehaviorSubject<DateUIQuery>(undefined);
  private assaysStream: BehaviorSubject<AssaysUIQuery> = new BehaviorSubject<AssaysUIQuery>(undefined);
  private resultTypesStream: BehaviorSubject<ResultTypesUIQuery> = new BehaviorSubject<ResultTypesUIQuery>(undefined);
  private orgsFacilitiesStream: BehaviorSubject<OrgsFacilitiesUIQuery> = new BehaviorSubject<OrgsFacilitiesUIQuery>(undefined);
  private geoStream: BehaviorSubject<GeoUIQuery> = new BehaviorSubject<GeoUIQuery>(undefined);
  private operatorStream: BehaviorSubject<OperatorUIQuery> = new BehaviorSubject<OperatorUIQuery>(undefined);
  private zipCodeStream: BehaviorSubject<ZipCodeUIQuery> = new BehaviorSubject<ZipCodeUIQuery>(undefined);
  private serialNumberStream: BehaviorSubject<SerialNumberUIQuery> = new BehaviorSubject<SerialNumberUIQuery>(undefined);
  private filtersChangedSubject: Subject<any> = new Subject<any>();

  public filtersChanged: Observable<any> = this.filtersChangedSubject.asObservable();

  private areOrgsDirtyInternal = false;
  private areFacsDirtyInternal = false;

  constructor(
    dateQuery: DateUIQuery,
    assaysQuery: AssaysUIQuery,
    resultTypesQuery: ResultTypesUIQuery,
    orgsFacilitiesQuery: OrgsFacilitiesUIQuery,
    geoQuery: GeoUIQuery,
    operatorQuery: OperatorUIQuery,
    zipCodeQuery: ZipCodeUIQuery,
    serialNumberQuery: SerialNumberUIQuery,
    favoritesQuery: FavoritesUIQuery,
    private dialog: MatDialog
  ) {
    favoritesQuery.setFilterService(this);
    orgsFacilitiesQuery.setFilterService(this);
    this.updateFavoritesQuery(favoritesQuery);
    this.updateDateQuery(dateQuery);
    this.updateAssaysQuery(assaysQuery);
    this.updateResultTypesQuery(resultTypesQuery);
    this.updateOrgsFacilities(orgsFacilitiesQuery);
    this.updateGeoQuery(geoQuery);
    this.updateOperatorQuery(operatorQuery);
    this.updateZipCodeQuery(zipCodeQuery);
    this.updateSerialNumberQuery(serialNumberQuery);
  }

  areOrgsDirty(): boolean {
    return this.areOrgsDirtyInternal;
  }

  setOrgsDirty(dirty: boolean): void {
    this.areOrgsDirtyInternal = dirty;
  }

  areFacilitiesDirty(): boolean {
    return this.areFacsDirtyInternal;
  }

  setFacilitiesDirty(dirty: boolean): void {
    this.areFacsDirtyInternal = dirty;
  }

  areFiltersDirty(): boolean {

    return this.getAssaysUIQuery().isDirty() ||
      this.getDateUIQuery().isDirty() ||
      this.getResultTypesQuery().isDirty() ||
      this.getOrgsFacilitiesQuery().isDirty() ||
      this.getGeoQuery().isDirty() ||
      this.getOperatorQuery().isDirty() ||
      this.getZipCodeQuery().isDirty() ||
      this.getSerialNumberQuery().isDirty();
  }

  getDateUIQuery(): DateUIQuery {

    return this.dateStream.value;
  }

  getAssaysUIQuery(): AssaysUIQuery {

    return this.assaysStream.value;
  }

  getResultTypesQuery(): ResultTypesUIQuery {
    return this.resultTypesStream.value;
  }

  getOrgsFacilitiesQuery(): OrgsFacilitiesUIQuery {
    return this.orgsFacilitiesStream.value;
  }

  getGeoQuery(): GeoUIQuery {
    return this.geoStream.value;
  }

  getOperatorQuery(): OperatorUIQuery {
    return this.operatorStream.value;
  }

  getZipCodeQuery(): ZipCodeUIQuery {
    return this.zipCodeStream.value;
  }

  getSerialNumberQuery(): SerialNumberUIQuery {
    return this.serialNumberStream.value;
  }

  getFavoritesQuery(): FavoritesUIQuery {
    return this.favoritesStream.value;
  }

  updateDateQuery(query: DateUIQuery): void {
    this.dateStream.next(query);
    this.filtersChangedSubject.next();
  }

  updateAssaysQuery(query: AssaysUIQuery): void {
    this.assaysStream.next(query);
    this.filtersChangedSubject.next();
  }

  updateResultTypesQuery(query: ResultTypesUIQuery): void {
    this.resultTypesStream.next(query);
    this.filtersChangedSubject.next();
  }

  updateOrgsFacilities(query: OrgsFacilitiesUIQuery): void {
    this.orgsFacilitiesStream.next(query);
    this.filtersChangedSubject.next();
  }

  updateGeoQuery(query: GeoUIQuery): void {
    this.geoStream.next(query);
    this.filtersChangedSubject.next();
  }

  updateOperatorQuery(query: OperatorUIQuery): void {
    this.operatorStream.next(query);
    this.filtersChangedSubject.next();
  }

  updateZipCodeQuery(query: ZipCodeUIQuery): void {
    this.zipCodeStream.next(query);
    this.filtersChangedSubject.next();
  }

  updateSerialNumberQuery(query: SerialNumberUIQuery): void {
    this.serialNumberStream.next(query);
    this.filtersChangedSubject.next();
  }

  updateFavoritesQuery(query: FavoritesUIQuery): void {
    this.favoritesStream.next(query);
    this.filtersChangedSubject.next();
  }

  subscribeDateQuery(callback: (query: DateUIQuery) => void): Subscription {
    return this.dateStream.subscribe(s => {
      callback(s);
    }
    );
  }

  subscribeAssaysQuery(callback: (query: AssaysUIQuery) => void): Subscription {
    return this.assaysStream.subscribe(s => {
      callback(s);
    }
    );
  }

  subscribeResultTypesQuery(callback: (query: ResultTypesUIQuery) => void): Subscription {
    return this.resultTypesStream.subscribe(s => {
      callback(s);
    }
    );
  }

  subscribeOrgsFacilitiesQuery(callback: (query: OrgsFacilitiesUIQuery) => void): Subscription {
    return this.orgsFacilitiesStream.subscribe(s => {
      callback(s);
    }
    );
  }

  subscribeGeoQuery(callback: (query: GeoUIQuery) => void): Subscription {
    return this.geoStream.subscribe(s => {
      callback(s);
    }
    );
  }

  subscribeOperatorQuery(callback: (query: OperatorUIQuery) => void): Subscription {
    return this.operatorStream.subscribe(s => {
      callback(s);
    }
    );
  }

  subscribeZipCodeQuery(callback: (query: ZipCodeUIQuery) => void): Subscription {
    return this.zipCodeStream.subscribe(s => {
      callback(s);
    }
    );
  }

  subscribeSerialNumberQuery(callback: (query: SerialNumberUIQuery) => void): Subscription {
    return this.serialNumberStream.subscribe(s => {
      callback(s);
    }
    );
  }

  subscribeFavoritesQuery(callback: (query: FavoritesUIQuery) => void): Subscription {
    return this.favoritesStream.subscribe(s => {
      callback(s);
    }
    );
  }

  async serializeState(): Promise<FilterState> {

    const assaysQuery = this.getAssaysUIQuery();
    const serializedAssays = assaysQuery.serialize();

    const resultTypesQuery = this.getResultTypesQuery();
    const serializedResults = resultTypesQuery.serialize();

    const operatorQuery = this.getOperatorQuery();
    const serializedOperator = operatorQuery.serialize();

    const zipCodeQuery = this.getZipCodeQuery();
    const serializedZipCode = zipCodeQuery.serialize();

    const serialNumberQuery = this.getSerialNumberQuery();
    const serializedSerial = serialNumberQuery.serialize();

    const dateQuery = this.getDateUIQuery();
    const serializedDate = dateQuery.serialize();


    const orgsFacilitiesQuery = this.getOrgsFacilitiesQuery();
    const serialzedOrgsFacilities = orgsFacilitiesQuery.serialize();

    const geoQuery = this.getGeoQuery();
    const serializedGeo = await geoQuery.serialize();

    const filterState: FilterState = {
      schemaVersion: '1.0',
      dates: serializedDate,
      operator: serializedOperator,
      zipCode: serializedZipCode,
      serial: serializedSerial,
      orgsFacs: serialzedOrgsFacilities,
      geo: serializedGeo
    };

    if (serializedAssays && serializedAssays.length > 0) {
      filterState.assaysIds = serializedAssays;
    }

    if (serializedResults && serializedResults.length > 0) {
      filterState.resultsIds = serializedResults;
    }

    return filterState;
  }

  deserializeStateFrom(favorite: FavoriteItem): void {
    const deserializedState: FilterState = favorite.getFilterState();

    console.debug('deserializeStateFrom');
    console.debug(deserializedState);

    void this.resetFilters(true);

    const assaysQuery = this.getAssaysUIQuery();
    assaysQuery.setDefaultState(deserializedState.assaysIds);

    const dateQuery = this.getDateUIQuery();
    dateQuery.setDefaultState(deserializedState.dates);

    const resultTypesQuery = this.getResultTypesQuery();
    resultTypesQuery.setDefaultState(deserializedState.resultsIds);


    if (deserializedState.orgFac) {
      deserializedState.orgsFacs = [];
      deserializedState.orgsFacs.push(deserializedState.orgFac);
    }
    
    const orgsFacilitiesQuery = this.getOrgsFacilitiesQuery();
    void orgsFacilitiesQuery.setDefaultState(deserializedState.orgsFacs);

    const geoQuery = this.getGeoQuery();
    void geoQuery.setDefaultState(deserializedState.geo);

    const operatorQuery = this.getOperatorQuery();
    operatorQuery.setDefaultState(deserializedState.operator);

    const zipCodeQuery = this.getZipCodeQuery();
    zipCodeQuery.setDefaultState(deserializedState.zipCode);

    const serialNumberQuery = this.getSerialNumberQuery();
    serialNumberQuery.setDefaultState(deserializedState.serial);
  }

  async resetFilters(ignoreFavoritesDirty = false): Promise<void> {
    const favoriteQuery = this.getFavoritesQuery();
    if (!ignoreFavoritesDirty && favoriteQuery.isDirty()) {
      const selectedFavorite = favoriteQuery.getSelectedFavorite();
      const confirmed = await this.showConfirmationDialog({ titleResourceKey: 'ConfirmFavoriteDirtyReset', parameter: selectedFavorite.getLabel() });
      if (!confirmed) {
        return;
      }
    }
    favoriteQuery.reset();
    this.updateFavoritesQuery(favoriteQuery);

    const dateQuery = this.getDateUIQuery();
    dateQuery.reset();
    this.updateDateQuery(dateQuery);

    const assaysQuery = this.getAssaysUIQuery();
    assaysQuery.reset();
    this.updateAssaysQuery(assaysQuery);

    const resultTypesQuery = this.getResultTypesQuery();
    resultTypesQuery.reset();
    this.updateResultTypesQuery(resultTypesQuery);


    const orgsFacilitiesQuery = this.getOrgsFacilitiesQuery();
    orgsFacilitiesQuery.reset();
    this.updateOrgsFacilities(orgsFacilitiesQuery);

    const geoQuery = this.getGeoQuery();
    geoQuery.reset();
    this.updateGeoQuery(geoQuery);

    const operatorQuery = this.getOperatorQuery();
    operatorQuery.reset();
    this.updateOperatorQuery(operatorQuery);

    const zipCodeQuery = this.getZipCodeQuery();
    zipCodeQuery.reset();
    this.updateZipCodeQuery(zipCodeQuery);

    const serialNumberQuery = this.getSerialNumberQuery();
    serialNumberQuery.reset();
    this.updateSerialNumberQuery(serialNumberQuery);
  }

  private showConfirmationDialog(inputData: ConfirmationModalInput): Promise<boolean> {

    const modalWidth = 0.25 * window.outerWidth;
    const confirmationDialogRef = this.dialog.open<ConfirmationModalComponent, ConfirmationModalInput, boolean>(ConfirmationModalComponent, {
      width: `${modalWidth}px`,
      data: inputData
    });

    const result = confirmationDialogRef.afterClosed().toPromise();
    return result;
  }

  isOnlyPatientSelected(): boolean {
    const resultTypesQuery = this.getResultTypesQuery();
    const resultTypes = resultTypesQuery.getSelectedItems();

    return resultTypes != null &&
      resultTypes.length === 1 &&
      resultTypes[0].sampleType.sampleTypeId === SampleTypeId.Patient;
  }

  hasOrg(): boolean {
    const orgsFacilitiesQuery = this.getOrgsFacilitiesQuery();
    const orgs = orgsFacilitiesQuery.getSelectedOrganizations();

    return orgs != null && orgs.length === 1 && orgs[0].item != null;
  }

  isOnlyPatientSelectedAndHasOrg(): boolean {
    return this.isOnlyPatientSelected() && this.hasOrg();
  }

  isOnlyPatientSelectedHasOrgAndAssay(): boolean {
    if (this.isOnlyPatientSelectedAndHasOrg()) {
      const assaysQuery = this.getAssaysUIQuery();

      if (assaysQuery != null) {
        const assays = assaysQuery.getSelectedItems();

        if (assays != null && assays.length > 0) {
          return true;
        }
      }
    }

    return false;
  }

  isOnlyPatientSelectedAndHasOneAssay(): boolean {
    if (this.isOnlyPatientSelected()) {
      const assaysQuery = this.getAssaysUIQuery();

      if (assaysQuery != null) {
        const assays = assaysQuery.getSelectedItems();

        if (assays != null && assays.length === 1) {
          return true;
        }
      }
    }

    return false;
  }

  isOnlyQCAndHasOrg(): boolean {
    const resultTypesQuery = this.getResultTypesQuery();
    const resultTypes = resultTypesQuery.getSelectedItems();

    return resultTypes != null &&
      resultTypes.length === 1 &&
      resultTypes[0].sampleType.sampleTypeId === SampleTypeId.QualityControl &&
      this.hasOrg();
  }

  isReportEnabled(reportKey: string): boolean {
    switch (reportKey) {
      case ReportKeys.PatientsByRunDate:
      case ReportKeys.PatientsByAssay:
      case ReportKeys.PatientsByResult:
      case ReportKeys.PatientResultTrends:
      case ReportKeys.PercentPositiveResults:
        return this.isOnlyPatientSelected();
        break;
      case ReportKeys.QualityControlReport:
        return this.isOnlyQCAndHasOrg();
        break;
      case ReportKeys.PatientsByFacilityAssay:
        return this.isOnlyPatientSelectedAndHasOrg();
        break;
      case ReportKeys.PatientsByFacilityResult:
        return this.isOnlyPatientSelectedHasOrgAndAssay();
        break;
      case ReportKeys.PatientCoInfections:
        return this.isOnlyPatientSelectedAndHasOneAssay();
        break;
      default:
        return true;
    }
  }

}
