

import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { LegendComponent } from '../legend/legend.component';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { Configuration } from '../../services/configuration.service.models';
import { ConfigurationService } from '../../services/configuration.service';

export enum MarkerType {
  County,
  Facility
}

export interface IMarker {
  lat: number;
  lng: number;
  descriptions: string[];
  type: MarkerType;
  testsPercentage?: number;
  testsNumber?: number;

}

@Component({
  selector: 'core-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, AfterViewInit, OnChanges {

  // https://angular.io/guide/static-query-migration
  @ViewChild('map', { static: true }) private mapContainer: ElementRef<Element>;
  @ViewChild('legendDiv', { static: true }) private legendContainer: ElementRef<HTMLDivElement>;
  @ViewChild('legend', { static: true }) private legend: LegendComponent;

  @Input() public markers: IMarker[] = [];

  private map: google.maps.Map;

  private mapMarkerHeight = 16;
  private mapMarkerWidth = 10;

  private existingMarkers: google.maps.Marker[] = [];
  private existingCountyCircles: google.maps.Circle[] = [];

  private isInitialized = false;

  private configuration: Configuration;

  constructor(
    private googleAnalyticsService: GoogleAnalyticsService, configurationService: ConfigurationService
  ) {
    this.configuration = configurationService.getConfiguration();
  }

  ngOnInit(): void {
    const mapProperties: google.maps.MapOptions = {
      center: new google.maps.LatLng(31.644784, -84.246702),
      zoom: 5,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };
    this.map = new google.maps.Map(this.mapContainer.nativeElement, mapProperties);

    this.map.addListener('click', (event: google.maps.MapMouseEvent) => {
      this.fetchCountyName(event.latLng.lat(), event.latLng.lng());
    });

    this.addLegend();
  }

  ngAfterViewInit(): void {
    this.addMarkers();
    this.fitMapBounds();

    this.isInitialized = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.isInitialized) {
      return;
    }

    if (changes.markers) {
      this.refreshMapMarkers();
    }
  }

  private refreshMapMarkers() {
    this.clearAll();
    this.addMarkers();
    this.fitMapBounds();
  }

  clearAll(): void {
    this.clearMarkers();
    this.clearCountyCircles();
  }

  private addMarkers() {

    this.markers.forEach(m => {
      if (m) {
        if (m.type === MarkerType.County) {
          this.createCountyMarker(m.lat, m.lng, m.descriptions);
          if (m.testsNumber && m.testsPercentage) {
            this.createCountyCircle(m.lat, m.lng, m.testsPercentage, m.testsNumber);
          }
        } else if (m.type === MarkerType.Facility) {
          this.createFacilityMarker(m.lat, m.lng, m.descriptions);
        }
      }
    });

  }

  private fitMapBounds() {

    if ((this.existingMarkers && this.existingMarkers.length > 0)
      || (this.existingCountyCircles && this.existingCountyCircles.length > 0)) {
      const bounds = new google.maps.LatLngBounds();
      this.existingMarkers.forEach(m => {
        bounds.extend(m.getPosition());
      });

      this.existingCountyCircles.forEach(c => {
        bounds.extend(c.getBounds().getCenter());
        bounds.extend(c.getBounds().getNorthEast());
        bounds.extend(c.getBounds().getSouthWest());
      });

      this.map.fitBounds(bounds);
      this.map.panToBounds(bounds);
    } else {
      // Using geographical center of the Contiguous US if nothing to display
      // https://stackoverflow.com/a/43577944/10752002
      this.map.setCenter(new google.maps.LatLng(39.8097343, -98.5556199));
      this.map.setZoom(4.5);
    }
  }

  private clearCountyCircles() {
    if (this.existingCountyCircles) {
      this.existingCountyCircles.forEach(c => {
        c.setMap(null);
      });
      this.existingCountyCircles = [];
    }
  }

  private clearMarkers() {
    if (this.existingMarkers) {
      this.existingMarkers.forEach(markers => {
        markers.setMap(null);
      });
      this.existingMarkers = [];
    }
  }

  private createCountyCircle(lat: number, lng: number, testsPercentage: number, testsNumber: number) {
    const radius = this.calculateRadius(testsNumber);
    const circleOptions: google.maps.CircleOptions = {
      strokeWeight: .5,
      strokeOpacity: 0.6,
      fillColor: '#' + this.legend.percentToColor(testsPercentage),
      fillOpacity: 0.60,
      map: this.map,
      center: new google.maps.LatLng(lat, lng),
      radius
    };
    const circle = new google.maps.Circle(circleOptions);

    this.existingCountyCircles.push(circle);
  }

  private createMarker(lat: number, lng: number, popupDescriptions: string[], iconUrl: string) {
    const iconMarkerSize = new google.maps.Size(this.mapMarkerWidth, this.mapMarkerHeight);
    const markerIcon: google.maps.Icon = { url: iconUrl, scaledSize: iconMarkerSize };
    const marker = new google.maps.Marker({ position: { lat, lng }, map: this.map, icon: markerIcon });

    let formattedDescription = '';
    if (popupDescriptions) {
      formattedDescription = popupDescriptions.join('<br />');
    }

    const infoWindow = new google.maps.InfoWindow({
      content: formattedDescription
    });

    marker.addListener('click', () => {
      infoWindow.open(this.map, marker);
    });

    this.existingMarkers.push(marker);
  }

  private createCountyMarker(lat: number, lng: number, popupDescriptions: string[]) {
    this.createMarker(lat, lng, popupDescriptions, '/assets/images/pin.png');
  }

  private createFacilityMarker(lat: number, lng: number, popupDescriptions: string[]) {
    this.createMarker(lat, lng, popupDescriptions, '/assets/images/facility-pin.png');
  }

  //
  // Calculate the circle radius based on the number of tests
  //
  private calculateRadius(n: number) {
    switch (true) {
      case (n > 0 && n <= 50):
        // 5 Miles
        return this.milesToMeters(5);
      case (n > 50 && n <= 250):
        // 15 Miles
        return this.milesToMeters(15);
      case (n > 250 && n <= 500):
        // 25 Miles
        return this.milesToMeters(25);
      case (n > 500 && n < 750):
        // 35 Miles
        return this.milesToMeters(35);
      case (n >= 750):
        // 45 Miles
        return this.milesToMeters(45);
      default:
        // zero miles indicates no tests (should be impossible)
        return 0;
    }
  }

  //
  // Convert miles passed in to meters and return
  //
  private milesToMeters(miles: number) {
    return miles * 1609.344;
  }

  private addLegend() {
    const legendDiv = this.legendContainer.nativeElement;
    if (this.map != null && this.map.controls != null) {
      this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(legendDiv);
    }
  }

  private fetchCountyName(latitude: number, longitude: number): void {
    const endpoint = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${this.configuration.GoogleMapApiKey}`;

    fetch(endpoint)
      .then(response => response.json())
      .then((data: { results: google.maps.GeocoderResult[]; status: google.maps.GeocoderStatus }) => {
        if (data.status === 'OK') {
          const addressComponents: google.maps.GeocoderAddressComponent[] = data.results[0].address_components;
          const countyObj = addressComponents.find(component => component.types.includes('administrative_area_level_2'));

          if (countyObj) {
            this.googleAnalyticsService.log('Map', countyObj.long_name, 'CountyClicked');
          } else {
            console.log('County not found in the response.');
          }
        }
        else {
          console.error('Geocoding API error:', data.status);
        }
      })
      .catch(error => {
        console.error('Error fetching county:', error);
      });
  }
}
