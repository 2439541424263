import { Injectable } from '@angular/core';
import * as cssVars from 'css-vars-ponyfill';

export const summaryTheme = {
  'theme-background-color': '#7FB4C2',
  'theme-highlight-color': '#68697C',
  'theme-text-color': '#000'
};

export const testResultsTheme = {
  'theme-background-color': '#A4BE75',
  'theme-highlight-color': '#008dc9',
  'theme-text-color': '#000'
};

export const mapTheme = {
  'theme-background-color': '#AA74AC',
  'theme-highlight-color': '#678975',
  'theme-text-color': '#000'
};

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  enableSummaryTheme(): void {
    this.setTheme(summaryTheme);
  }

  enableTestResultsTheme(): void {
    this.setTheme(testResultsTheme);
  }

  enableMapTheme(): void {
    this.setTheme(mapTheme);
  }

  private setTheme(theme: Record<string, string>) {
    Object.keys(theme).forEach(k =>
      document.documentElement.style.setProperty(`--${k}`, theme[k])
    );

    cssVars.default({ updateDOM: true, shadowDOM: true, variables: theme });
  }
}
