<div class="core-header-div">
  <core-header-bar class="core-header"></core-header-bar>
</div>

<div class="pending-div">
        <div class="mat-headline">Welcome to myvirena.com</div>
        <div class="mat-subheading-2">
                Unable to login. Please ensure that your browser is not blocking 3rd party cookies and reload the site.
        </div>
</div>
