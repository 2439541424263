<mat-toolbar color="primary" class="crm-navigation-bar">

  <mat-toolbar-row>

    <a routerLink="/" class="quidel-logo"><img class="quidel-logo-img" src="/assets/images/header/Quidel_Virena.jpg" /></a>

    <div class="title-container">
      {{title | translate}}
    </div>
    <div class="filters-applied-message-container">
      <div *ngIf="filtersApplied" class="filters-applied-message">Filters Applied</div>
    </div>

    <div fxFlex fxLayoutAlign="flex-end" class="menu-container">

      <ul fxLayout fxLayoutGap="45px" class="navigation-items">

        <li fxHide.xs>
          <button mat-icon-button *ngIf="showAdminControls" [matMenuTriggerFor]="admin">
            <div class="admin-icon-container">
              <div class="admin-icon-top">
                <img src="/assets/images/header/Admin logo.png" />
              </div>
              <div class="admin-icon-bottom">
                {{'HeaderBar.Admin' | translate}}
              </div>
            </div>
          </button>
          <mat-menu #admin>
            <button mat-menu-item routerLink="{{ orgSearchFullPath }}" *ngIf="hasOrgSearchAccess">
              <span>{{'HeaderBar.TitleOrganizationLink' | translate}}</span>
            </button>
            <button mat-menu-item routerLink="{{ facSearchFullPath }}"
              *ngIf="hasOrgFacilitySearchAccess">
              <span>{{'HeaderBar.TitleFacilityLink' | translate}}</span>
            </button>
            <button mat-menu-item routerLink="{{ devicesSearchFullPath }}"
              *ngIf="hasRouterAccess">
              <span>{{'HeaderBar.TitleRouterLink' | translate}}</span>
            </button>
            <button mat-menu-item routerLink="{{ instrumentsSearchFullPath }}"
              *ngIf="hasInstrumentSearchAccess">
              <span>{{'HeaderBar.TitleInstrumentLink' | translate}}</span>
            </button>
            <button mat-menu-item routerLink="{{ usersSearchFullPath }}" *ngIf="hasUserSearchAccess">
              <span>{{'HeaderBar.TitleUserManagementLink' | translate}}</span>
            </button>
            <button mat-menu-item routerLink="{{ usersUnapprovedFullPath }}"
              *ngIf="hasUserUnapprovedAccess">
              <span>{{'HeaderBar.TitleUserUnapprovedRouteLink' | translate}}</span>
            </button>
            <button mat-menu-item routerLink="{{ assaysListFullPath }}" *ngIf="hasAssaysAccess">
              <span>{{'HeaderBar.TitleAssayLink' | translate}}</span>
            </button>
            <button mat-menu-item routerLink="{{ orphansListFullPath }}"
              *ngIf="hasOrphanAccess">
              <span>{{'HeaderBar.TitleOrphanLink' | translate}}</span>
            </button>
            <button mat-menu-item routerLink="{{ resultsApiListFullPath }}"
              *ngIf="hasResultsApiAccess">
              <span>{{'HeaderBar.TitleResultsApiLink' | translate}}</span>
            </button>

          </mat-menu>
        </li>

        <li style="margin-left: 20px">
          <button mat-icon-button [matMenuTriggerFor]="auth">
            <div class="logout-icon-container">
              <div class="admin-icon-top">
                <img src="/assets/images/header/User logo.png" />
              </div>
              <div class="admin-icon-bottom" matTooltip="{{userDisplayName}}">
                {{userDisplayName}}
              </div>
            </div>
            <mat-menu #auth="matMenu">
              <button mat-menu-item (click)="logout()">
                <mat-icon>exit_to_app</mat-icon>
                <span>Sign out</span>
              </button>
              <button mat-menu-item (click)="showAbout()">
                <mat-icon>info</mat-icon>
                <span>About</span>
              </button>
            </mat-menu>
          </button>
        </li>

      </ul>

    </div>

  </mat-toolbar-row>

</mat-toolbar>
