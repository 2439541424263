import { Component, OnInit, OnDestroy } from '@angular/core';
import { FilterElement } from '../FilterElement';
import { UIFilterBase } from '../UIFilterBase';
import { FilterService } from '../../../services/filter.service';
import { TranslateService } from '@ngx-translate/core';
import { AssaysUIQuery, AssayItem } from './filter-custom-assays.model';

@Component({
  selector: 'core-filter-custom-assays',
  templateUrl: './filter-custom-assays.component.html',
  styleUrls: ['./filter-custom-assays.component.scss']
})
export class FilterCustomAssaysComponent extends UIFilterBase<AssaysUIQuery> implements OnInit, OnDestroy {

  private static FilterCustomAssaysKey = 'FilterCustomAssays';

  constructor(filterService: FilterService, translateService: TranslateService) {
    super(filterService, translateService, FilterCustomAssaysComponent.FilterCustomAssaysKey);
  }

  ngOnInit(): void {
    this.initialize();
  }

  ngOnDestroyInternal(): void {
    // Required by base component
  }

  private initialize() {
    const query = this.filterService.getAssaysUIQuery();
    this.initializeQuery(query);
    setTimeout(() => query.refreshData());
  }

  selectItem(item: FilterElement<AssayItem>): void {

    item.toggleSelected();
    this.filterService.updateAssaysQuery(this.query);
  }

  clearFilter(): void {
    this.query.reset();
    this.filterService.updateAssaysQuery(this.query);
  }
}
