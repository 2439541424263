import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'displayPhoneNumber' })
export class DisplayPhoneNumberPipe implements PipeTransform {
  transform(phone: string): string {
    const cleaned = ('' + phone).replace(/\D/g, '');
    const match = /^(\d{3})(\d{3})(\d{4})$/.exec(cleaned);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return phone;
  }
}
