import { InjectionToken } from '@angular/core';

export const defaultErrors = {
  required: (error: string): string => 'ValidationErrors.Required',
  email: (error: string): string => 'ValidationErrors.Email',
  pattern: (error: string): string => 'ValidationErrors.Pattern',
  maxlength: (error: string): string => 'ValidationErrors.MaxLength',
  min: (error: string): string => 'ValidationErrors.Min',
  minlength: (error: string): string => 'ValidationErrors.Min',
  invalidSerialNumber: (error: string): string => 'ValidationErrors.InvalidSerial'
};

export const FORM_ERRORS = new InjectionToken('FORM_ERRORS', {
  providedIn: 'root',
  factory: () => defaultErrors
});
