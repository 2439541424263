export class ApiStatusDescription {
  public static readonly SearchCriteriaTooBroad = 'SearchCriteriaTooBroad';
}

export enum SortDirection {
  Ascending = 0,
  Descending = 1
}

export enum PageDirection {
  Invalid = 0,
  FirstPage = 1,
  PreviousPage = 2,
  NextPage = 3,
  LastPage = 4
}

export interface PagingInformation {
  pageNumber: number;
  pageSize: number;
  sortColumn: string;
  sortDirection: SortDirection;
}

export interface SearchResultsPage<T> {
  pagingInformation: PagingInformation;
  totalResults: number;
  results: T[];
}

export interface ApiError {
  statusCode: string;
  statusDescription: string;
  message: string;
}
