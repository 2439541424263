<div *ngIf="isOpened" class="container">

    <div class="title">
        <span>{{'FilterCustomZipCode.Title' | translate}}</span>
        <i class="material-icons clear flipped" (click)="clearFilter()">refresh</i>
    </div>

    <div *ngFor="let i of query.items" (click)="selectItem(i)" class="submenu-item filter-option">
        <div class="submenu-item-container">
            <div>
                <div class="submenu-item">
                    <form [formGroup]="zipCodeFormGroup">
                        <mat-form-field class="mat-form-field-width" floatLabel="never">
                            <input matInput (focusout)="zipCodeChanged($event)" [(value)]="zipCodeFilter.item.zipCode"
                                formControlName="zipCode" id="zipCode" noCustomValidation="true"
                                placeholder="{{ 'FilterCustomZipCode.ZipCodeHeader' | translate }}">
                            <div class="error" *ngIf="hasError('maxlength')">
                                {{ 'FilterCustomZipCode.ZipCodeMaxLengthValidation' | translate }}</div>
                        </mat-form-field>
                    </form>
                </div>
            </div>
            <div class="submenu-item-state" [class.has-filter-selected]="i.isSelected">
            </div>
        </div>
    </div>

</div>
