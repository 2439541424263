import { IGetLabel, UIQuerySimpleFilter, FilterElement, IGetId, IEquatable } from '../FilterElement';
import { Injectable, OnDestroy } from '@angular/core';
import { MetadataService } from '../../../services/metadata.service';
import { Observable } from 'rxjs';

export class SerialNumberItem implements IGetLabel, IGetId, IEquatable<SerialNumberItem> {

  serialNumber: string;

  constructor() {
    this.serialNumber = '';
  }

  getLabel(): string {
    return '';
  }

  getId(): number {
    return 1;
  }

  equals(other: SerialNumberItem): boolean {
    return this.serialNumber === other.serialNumber;
  }

  toString(): string {
    return this.serialNumber;
  }
}

@Injectable({
  providedIn: 'root'
})
export class SerialNumberUIQuery extends UIQuerySimpleFilter<SerialNumberItem> implements OnDestroy {

  constructor(private metadataService: MetadataService) {
    super();

    this.initialize();
  }

  protected initialize(): void {

    this.items = [
      FilterElement.Build(new SerialNumberItem())
    ];

    this.setDefaults();
  }

  public reset(): void {
    this.initialize();
    this.resetOccurred.next();
  }

  public getSerialNumber(): FilterElement<SerialNumberItem> {
    return this.items[0];
  }

  public searchMatchingSerialNumber(searchText: string): Observable<string[]> {
    return this.metadataService.getSearchInstrumentSerialNumbers(searchText);
  }

  protected setDefaults(selectedItems: FilterElement<SerialNumberItem>[] = null): void {
    this.setDefaultsItems(selectedItems);

    this.defaultItems.forEach(i => {
      if (i) {
        if (i.item) {
          const serialNumber = i.item.serialNumber;
          i.item = new SerialNumberItem();
          i.item.serialNumber = serialNumber;
        }
      }
    });
  }

  public setDefaultState(serialValue: string): void {

    const serial = this.getSerialNumber();

    if (serialValue) {
      serial.isSelected = true;
      serial.item.serialNumber = serialValue;
    } else {
      serial.isSelected = false;
      serial.item.serialNumber = '';
    }

    this.setDefaults();
  }

  public serialize(): string {
    const serial = this.getSerialNumber();
    if (serial && serial.isSelected) {
      return serial.item.toString();
    } else {
      return undefined;
    }
  }

}

