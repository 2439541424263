import { Component, OnInit, OnDestroy } from '@angular/core';
import { FilterService } from '../../../services/filter.service';
import { UIFilterBase } from '../UIFilterBase';
import { FilterElement } from '../FilterElement';
import { TranslateService } from '@ngx-translate/core';
import { ResultTypesUIQuery, ResultTypesItem } from './result-types-custom-filter.model';

@Component({
  selector: 'core-filter-result-types',
  templateUrl: './result-types-custom-filter.component.html',
  styleUrls: ['./result-types-custom-filter.component.scss']
})
export class FilterCustomResultTypesComponent extends UIFilterBase<ResultTypesUIQuery> implements OnInit, OnDestroy {

  private static FilterCustomResultTypesKey = 'FilterCustomResultTypes';

  constructor(filterService: FilterService, translateService: TranslateService) {
    super(filterService, translateService, FilterCustomResultTypesComponent.FilterCustomResultTypesKey);
  }

  ngOnInit(): void {
    this.initialize();
  }

  ngOnDestroyInternal(): void {
    // Required by base component
  }

  private initialize() {
    const query = this.filterService.getResultTypesQuery();
    this.initializeQuery(query);
  }

  selectItem(item: FilterElement<ResultTypesItem>): void {

    item.toggleSelected();
    this.filterService.updateResultTypesQuery(this.query);
  }

  clearFilter(): void {
    this.query.reset();
    this.filterService.updateResultTypesQuery(this.query);
  }
}
