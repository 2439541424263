<div *ngIf="isOpened" class="container">
    <div class="title">
        <span>{{'FilterCustomResultTypes.Title' | translate}}</span>
        <i class="material-icons clear flipped" (click)="clearFilter()">refresh</i>
    </div>

    <div class="inner-scrollbar-container">

        <div *ngFor="let i of query.items" (click)="selectItem(i)" class="submenu-item filter-option">
            <div class="submenu-item-container">
                <div class="label">
                    {{ i.getLabel() }}
                </div>
                <div class="submenu-item-state" [class.has-filter-selected]="i.isSelected">
                </div>
            </div>
        </div>

    </div>

</div>
