<div class="legend noselect" cdkDrag cdkDragBoundary=".gm-style">

    <div class="legendTitle">
        <i class="material-icons drag-icon" cdkDragHandle>drag_indicator</i>
        <span>{{ 'Legend.Title' | translate }}</span>
        <i class="material-icons expand-icon"
            (click)="toggleExpand()">{{ isExpanded ? 'arrow_drop_down' : 'arrow_right' }}</i>
    </div>
    <div class="legend-content" [class.hidden]="!isExpanded"> 
        <div class="legendSubTitle legendSubTitle-symbols">{{ 'Legend.Symbols' | translate }}</div>
        <div class="legendItem legendItem-symbols">
            <div class="legendImage legendImage-symbols"><img src="/assets/images/pin.png"></div>
            <div class="legendImage legendImage-symbols"><img src="/assets/images/facility-pin.png">
            </div>
        </div>
        <div class="legendItem legendItem-symbols-name"><br>
            <div class="legendText legendText-name">{{ 'Legend.County' | translate }}</div>
            <div class="legendText legendText-name">{{ 'Legend.Facility' | translate }}</div>
        </div>
        <div class="legendSubTitle">{{ 'Legend.PercentagePositive' | translate }}</div>
        <div class="legendItem">
            <div class="legendText">{{ 'Legend.0To5' | translate }}</div>
            <div class="legendImage legendImage-size" [ngStyle]="{ 'background-color': '#' + percentToColor(0)}"></div>
        </div>
        <div class="legendItem">
            <div class="legendText">{{ 'Legend.6To10' | translate }}</div>
            <div class="legendImage legendImage-size" [ngStyle]="{ 'background-color': '#' + percentToColor(6)}"></div>
        </div>
        <div class="legendItem">
            <div class="legendText">{{ 'Legend.11To15' | translate }}</div>
            <div class="legendImage legendImage-size" [ngStyle]="{ 'background-color': '#' + percentToColor(11)}"></div>
        </div>
        <div class="legendItem">
            <div class="legendText">{{ 'Legend.16To19' | translate }}</div>
            <div class="legendImage legendImage-size" [ngStyle]="{ 'background-color': '#' + percentToColor(16)}"></div>
        </div>
        <div class="legendItem">
            <div class="legendText">{{ 'Legend.20To100' | translate }}</div>
            <div class="legendImage legendImage-size" [ngStyle]="{ 'background-color': '#' + percentToColor(20)}"></div>
        </div>
        <div class="legendSubTitle">{{ 'Legend.NumberOfTests' | translate }}</div>
        <div class="legendItem legendItem-tests">
            <div class="legendImage"><img src="/assets/images/circle1.png"></div>
            <div class="legendText">{{ 'Legend.Tests1To50' | translate }}</div>
        </div>
        <div class="legendItem" style="height: 8px;">
            <div class="legendImage" style="height: 16px;"><img src="/assets/images/circle2.png"></div>
            <div class="legendText" style="padding-top: 0px;">{{ 'Legend.Tests51To250' | translate }}</div>
        </div>
        <div class="legendItem" style="height: 14px;">
            <div class="legendImage" style="height: 21px;"><img src="/assets/images/circle3.png"></div>
            <div class="legendText" style="padding-top: 0px;">{{ 'Legend.Tests251To500' | translate }}</div>
        </div>
        <div class="legendItem" style="height: 20px;">
            <div class="legendImage" style="height: 22px;"><img src="/assets/images/circle4.png"></div>
            <div class="legendText" style="padding-top: 2px;">{{ 'Legend.Tests501To750' | translate }}</div>
        </div>
        <div class="legendItem" style="height: 30px;">
            <div class="legendImage" style="height: 25px;"><img src="/assets/images/circle5.png"></div>
            <div class="legendText" style="padding-top: 4px;">{{ 'Legend.TestsMoreThan750' | translate }}</div>
        </div>
    </div>
</div>