import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import {
  NavigationKeys, BaseComponent, QueryService, FilterService, SearchService, TestResultsQuery, ReportKeys,
  FixedDatesFilterItem, LastDaysFilterItem, RoleAccessService, UserGroup
} from '../core';
import { Router, NavigationEnd } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { MatRipple } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { ExportFileModalComponent, ExportFileType } from '../modals/export-file/export-file-modal/export-file-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { GoogleAnalyticsService } from '../core/services/google-analytics.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild(MatRipple, { static: true }) ripple: MatRipple;

  routes = NavigationKeys;
  hasActiveFilters = false;
  filters: TestResultsQuery;
  dateRange: string;
  dateTooltip: string;
  assays: string[] = [];
  results: string[] = [];
  organizations: string[] = [];
  organizationTooltip: string;
  totalGeoSubItems: number;
  location: string;
  locationTooltip: string;
  resultsCount = 0;
  filtersChanged = false;
  intervalRef: any = null;

  isPatientsByRunDateEnabled: boolean;
  isPatientsByAssayEnabled: boolean;
  isPatientsByFacilityAssayEnabled: boolean;
  isPatientsByFacilityResultEnabled: boolean;
  isPatientsByResultEnabled: boolean;
  isPatientResultTrendsEnabled: boolean;
  isPatientsCoInfectionsEnabled: boolean;
  isPercentPositiveResultEnabled: boolean;
  isQualityControlReportEnabled: boolean;
  isTestVolumeByTypeReportEnabled: boolean;

  isQualityControlReportVisible: boolean;

  ExportFileType = ExportFileType;
  exportButtonsState: string
  enabledExportButtons: boolean;
  value: string
  ReportKeys = ReportKeys;


  constructor(
    private queryService: QueryService,
    private filterService: FilterService,
    private searchService: SearchService,
    private roleAccessService: RoleAccessService,
    private router: Router,
    private dialog: MatDialog,
    private translate: TranslateService,
    private googleAnalyticsService : GoogleAnalyticsService,
  ) {
    super();
    this.value = '';
    this.enabledExportButtons = false;
    this.exportButtonsState = 'not-allowed';
  }

  ngOnInit(): void {
    this.enabledExportButtons = this.shouldEnableExportButtons(this.router.url);   

    this.subscription.add(this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map((data: NavigationEnd) => this.shouldEnableExportButtons(data.url))
      ).subscribe((isTestResultView) => {
        this.resultsCount = 0;
        this.enabledExportButtons = isTestResultView && 
          this.roleAccessService.getRole() !== UserGroup.TechSupportAdmin &&
          this.roleAccessService.getRole() !== UserGroup.SalesAdmin;
        if (this.enabledExportButtons) {
          this.exportButtonsState = 'pointer';
        } else {
          this.exportButtonsState = 'not-allowed';
        }
        if (this.roleAccessService.getRole()=== UserGroup.NetworkOrganizationAdmin)
        {
          this.isQualityControlReportVisible = false;
        }
        else
        {
          this.isQualityControlReportVisible = true;
        }
      }));

    this.subscription.add(this.searchService.searchResultsCompleted.subscribe(count => {
      this.resultsCount = count;
    }));

    this.subscription.add(this.queryService.filtersApplied.subscribe((filtersApplied: boolean) => {
      setTimeout(() => {
        this.updateBreadcrumbs(filtersApplied);
        this.filtersChanged = false;
        if (this.intervalRef) {
          clearInterval(this.intervalRef);
          this.intervalRef = null;
        }
      });
    }));

    this.registerForReportPermissions();
  }

  ngOnDestroyInternal(): void {
    // Required by base component
  }

  onSearch(): void {
    this.queryService.search();
  }

  resetFilters(): void {
    void this.filterService.resetFilters();
  }

  getReportLink(reportKey: string): string {
    return `${NavigationKeys.Reports}/${reportKey}`;
  }

  truncate(value: string, length: number): string {
    return (value.length > length) ? value.substr(0, length - 3) + '...' : value;
  }

  exportFileDialog(fileType: ExportFileType): void {
    if (!this.enabledExportButtons) {
      return;
    }
    console.debug(`exportFile:${fileType}`);
    if ( this.router.url.includes('results') ) {
      this.googleAnalyticsService.log('Export Dialog Opened', 'Test Results','Export Dialog Opened');
    }
    else if ( this.router.url.includes('mapping') ) {
      this.googleAnalyticsService.log('Export Dialog Opened', 'Map','Export Dialog Opened');     
    }  
        
    const value = '';
    const isTestResultExport = true;
    const modalWidth = 0.27 * window.outerWidth;
    this.dialog.open(ExportFileModalComponent, {
      width: `${modalWidth}px`,
      data: {fileType, value, isTestResultExport}
    });
  }

  public generateReport(route: string): void {
    // This causes the current filters to be applied, but NOT execute a separate search
    this.queryService.getCurrentQuery(true);
    void this.router.navigate([route]);
  }

  private shouldEnableExportButtons(url: string): boolean {
    return (url.includes(NavigationKeys.Results) || (url.includes(NavigationKeys.Mapping) && this.roleAccessService.getRole() === UserGroup.SystemAdmin));
  }

  private updateBreadcrumbs(filtersApplied: boolean) {
    this.hasActiveFilters = filtersApplied;
    this.filters = this.queryService.getCurrentQuery(false);

    this.updateDateBreadcrumbs();

    this.assays = this.filters.testTypes;
    this.updateResultTypeBreadcrumbs();

    // Intentionally NOT awaiting
    void this.updateOrgFacBreadcrumbs();

    void this.updateGeoBreadcrumbs();
  }

  registerForReportPermissions(): void {
    this.subscription.add(this.filterService.filtersChanged.subscribe(() => {
      setTimeout(() => {
        this.isPatientResultTrendsEnabled = this.filterService.isReportEnabled(ReportKeys.PatientResultTrends);
        this.isPatientsCoInfectionsEnabled = this.filterService.isReportEnabled(ReportKeys.PatientCoInfections);
        this.isPatientsByAssayEnabled = this.filterService.isReportEnabled(ReportKeys.PatientsByAssay);
        this.isPatientsByFacilityAssayEnabled = this.filterService.isReportEnabled(ReportKeys.PatientsByFacilityAssay);
        this.isPatientsByFacilityResultEnabled = this.filterService.isReportEnabled(ReportKeys.PatientsByFacilityResult);
        this.isPatientsByResultEnabled = this.filterService.isReportEnabled(ReportKeys.PatientsByResult);
        this.isPatientsByRunDateEnabled = this.filterService.isReportEnabled(ReportKeys.PatientsByRunDate);
        this.isPercentPositiveResultEnabled = this.filterService.isReportEnabled(ReportKeys.PercentPositiveResults);
        this.isQualityControlReportEnabled = this.filterService.isReportEnabled(ReportKeys.QualityControlReport);
        this.isTestVolumeByTypeReportEnabled = true;

        if (this.filtersChanged === false) {
          this.filtersChanged = true;
          setTimeout(() => this.rippleSearchButton(), 250);
          this.intervalRef = setInterval(() => this.rippleSearchButton(), 5000);
        }
      });
    }));
  }

  rippleSearchButton(): void {
    const background = this.hasActiveFilters ? '#ffffff' : getComputedStyle(document.body).getPropertyValue('--theme-background-color');

    const rippleRef = this.ripple.launch({ centered: true, radius: 15, persistent: true, color: background });
    setTimeout(() => rippleRef.fadeOut(), 750);
  }

  private async updateOrgFacBreadcrumbs() {
    if (this.filters.orgsFacilities) {
      const orgFacFilter = this.filterService.getOrgsFacilitiesQuery();
      const selectedOrgs = orgFacFilter.getSelectedOrganizations();
      this.organizations = selectedOrgs.map(o => o.item?.getLabel());

      const selectedFacilities = await orgFacFilter.getSelectedFacilities();

      if (selectedFacilities) {
        this.organizationTooltip = 'Organizations:\r\n' + selectedOrgs.map(o => o.getLabel()).join('\r\n') + '\r\n\r\nFacilities:\r\n' + selectedFacilities.map(f => f.getLabel()).join('\r\n');
      } else {
        this.organizationTooltip = selectedOrgs.map(o => o.getLabel()).join('\r\n');
      }

    } else {
      this.organizations = undefined;
      this.organizationTooltip = '';
    }
  }

  private updateDateBreadcrumbs() {
    const dateFilter = this.filterService.getDateUIQuery().getSelected();

    if (dateFilter.item.getLabel().includes('FixResult')) {
      const lastDaysFilterItem = dateFilter.item as LastDaysFilterItem;
      this.dateRange = `${lastDaysFilterItem.getDescription()} ${this.translate.instant(lastDaysFilterItem.getDescriptionTranslate()) as string}`;
      this.dateTooltip = undefined;
    } else {
      const fixedDatesFilterItem = dateFilter.item as FixedDatesFilterItem;
      this.dateRange = fixedDatesFilterItem.getDateRangeDescription();
      this.dateTooltip = `Start: ${fixedDatesFilterItem.getFormattedStartDate()}\r\nEnd:\t${fixedDatesFilterItem.getFormattedEndDate()}`;
    }
  }

  private async updateGeoBreadcrumbs() {

    let totalGeoSubItemsTemp = 0;
    if (this.filters.geo) {

      const geoFilter = this.filterService.getGeoQuery();
      const selectedCountry = geoFilter.getSelectedCountry();
      const selectedStates = await geoFilter.getSelectedStates();
      const selectedCounties = await geoFilter.getSelectedCounties();

      let stateCodes: string[];
      if (selectedStates) {
        totalGeoSubItemsTemp += selectedStates.length;
        stateCodes = selectedStates.map(e => e.item.state.abbreviation);
      }

      let countyNames: string[];
      if (selectedCounties) {
        totalGeoSubItemsTemp += selectedCounties.length;
        countyNames = selectedCounties.map(e => e.item.getLabel());
      }

      const countryCode = selectedCountry.item.country.code;
      let tooltip = countryCode;

      if (stateCodes) {
        tooltip += '\r\nStates:\r\n' + stateCodes.join('\r\n');
      }

      if (countyNames) {
        tooltip += '\r\nCounties:\r\n' + countyNames.join('\r\n');
      }

      this.locationTooltip = tooltip;
      this.location = this.truncate(countryCode, 20);
    } else {
      this.locationTooltip = '';
      this.location = undefined;
    }

    this.totalGeoSubItems = totalGeoSubItemsTemp;

  }

  private updateResultTypeBreadcrumbs() {

    const selectedResultTypes = this.filterService.getResultTypesQuery().getSelectedItems();

    if (selectedResultTypes) {
      this.results = selectedResultTypes.map(i => i.getLabel());
    } else {
      this.results = undefined;
    }
  }
}
