import { Injectable } from '@angular/core';

import { MatDrawerToggleResult, MatSidenav } from '@angular/material/sidenav';

@Injectable({
  providedIn: 'root'
})
export class SidenavService {

  private sidenav: MatSidenav;
  private opened = false;

  public setSidenav(sidenav: MatSidenav): void {
    this.sidenav = sidenav;
  }

  public open(): Promise<MatDrawerToggleResult> {
    this.opened = true;
    return this.sidenav.open();
  }

  public close(): Promise<MatDrawerToggleResult> {
    this.opened = false;
    return this.sidenav.close();
  }

  public toggle(): void {
    this.opened = !this.opened;
    void this.sidenav.toggle();
  }

  public isOpen(): boolean {
    return this.opened;
  }

}
