<div class="container">
    <ul class="tabrow" [class.selected]="this.selectedTab !== -1">
        <li [class.selected]="this.selectedTab === 0" (click)="onTabSelected(0)">
            <h3>Patient Tests</h3>
            <div class="tabcontent noselect">
                <div>
                    <p class="largecontent" matTooltip="{{testsSummary.Total | number: '1.0-0' }}">{{testsSummary.Total | number: '1.0-0' | truncateNumber}}</p>
                    <div class="subcontent"><span *ngIf="testsSummary.Invalid > 0">invalid</span> <span
                            *ngIf="testsSummary.Invalid > 0"
                            class="mediumfont">{{testsSummary.Invalid | number: '1.0-0' | truncateNumber}}</span></div>
                </div>
            </div>
            <div class="expander"><span>expand</span><i class="material-icons">expand_more</i></div>
        </li>
        <li [class.selected]="this.selectedTab === 1" (click)="onTabSelected(1)">
            <h3>Instruments Reporting Data</h3>
            <span class="subheading">(based on filters/criteria selected)</span>
            <div class="tabcontent noselect">
                <div>
                    <p class="largecontent">
                        {{this.instrumentsSummary.PercentageReporting.toFixed(0) | number: '1.0-0' }}%
                    </p>
                    <div class="subcontent"><span
                            class="mediumfont" matTooltip="{{this.instrumentsSummary.Reporting | number: '1.0-0'}}">{{this.instrumentsSummary.Reporting | number: '1.0-0' | truncateNumber}}</span>of<span
                            class="mediumfont" matTooltip="{{this.instrumentsSummary.Total | number: '1.0-0'}}">{{this.instrumentsSummary.Total | number: '1.0-0' | truncateNumber}}</span></div>
                </div>
            </div>
            <div class="expander"><span>expand</span><i class="material-icons">expand_more</i></div>
        </li>
        <li [class.selected]="this.selectedTab === 2" (click)="onTabSelected(2)">
            <h3>News</h3>
            <div class="tabcontent noselect">
                <div class="tabcontent textcontent" [innerHTML]="this.news.header">
                </div>
            </div>
            <div class="expander"><span>expand</span><i class="material-icons">expand_more</i></div>

        </li>
    </ul>
    <div class="content-container" [class.hidden]="this.selectedTab === -1">
        <div class="maincontent" *ngIf="selectedTab === 0">
            <div class="table-grid header noselect">
                <span (click)="onTestsColumnSort('testName', $event)">Assay Type <i
                        class="material-icons">{{this.testsSort['testName'] === 1 ? 'arrow_drop_up' : this.testsSort['testName'] === 2 ? 'arrow_drop_down' : ''}}</i></span>
                <span (click)="onTestsColumnSort('totalTests', $event)" class="right-align">Tests <i
                        class="material-icons">{{this.testsSort['totalTests'] === 1 ? 'arrow_drop_up' : this.testsSort['totalTests'] === 2 ? 'arrow_drop_down' : ''}}</i></span>
                <span (click)="onTestsColumnSort('totalPositive', $event)" class="right-align" style="padding-bottom: 38px;">
                    Positive <i class="material-icons">{{this.testsSort['totalPositive'] === 1 ? 'arrow_drop_up' :
                        this.testsSort['totalPositive'] === 2 ? 'arrow_drop_down' : ''}}</i>
                    <mat-icon matTooltip="{{'Summary.DualPositiveTooltip' | translate}}">info</mat-icon>
                </span>
                <span (click)="onTestsColumnSort('totalNegative', $event)" class="right-align" matTooltip="{{'Summary.PercentTooltip' | translate}}">Negative <i
                        class="material-icons">{{this.testsSort['totalNegative'] === 1 ? 'arrow_drop_up' : this.testsSort['totalNegative'] === 2 ? 'arrow_drop_down' : ''}}</i></span>
                <span (click)="onTestsColumnSort('totalInvalid', $event)" class="right-align">Invalid <i
                        class="material-icons">{{this.testsSort['totalInvalid'] === 1 ? 'arrow_drop_up' : this.testsSort['totalInvalid'] === 2 ? 'arrow_drop_down' : ''}}</i></span>
                <span (click)="onTestsColumnSort('instrumentsTransmitting', $event)" class="right-align">Instruments
                    Transmitting <i
                        class="material-icons">{{this.testsSort['instrumentsTransmitting'] === 1 ? 'arrow_drop_up' : this.testsSort['instrumentsTransmitting'] === 2 ? 'arrow_drop_down' : ''}}</i></span>
            </div>
            <div class="table-grid content">
                <div class="table-row" *ngFor="let test of testsSummary.Tests; index as i" (click)="onRowSelected(i)"
                    [class.row-expanded]="this.expandedRows.includes(i) && test.analytes.length > 1"
                    [style.height]="(this.expandedRows.includes(i) && test.analytes.length > 1 ? 48 * (test.analytes.length + 1) : 48) + 'px'">
                    <span><i class="material-icons expand-icon"
                            *ngIf="test.analytes.length > 1">{{ this.expandedRows.includes(i) ? 'arrow_drop_down' : 'arrow_right'}}</i>{{test.testName}}</span>
                    <span class="right-align">{{test.totalTests | number: '1.0-0'}}</span>
                    <span
                        class="right-align">{{((test.totalPositive / (test.totalPositive + test.totalNegative)) * 100) | number: '1.0-1'}}%
                        ({{test.totalPositive | number: '1.0-0'}})</span>
                    <span
                        class="right-align">{{((test.totalNegative / (test.totalPositive + test.totalNegative)) * 100) | number: '1.0-1'}}%
                        ({{test.totalNegative | number: '1.0-0'}})</span>
                    <span class="right-align">{{test.totalInvalid | number: '1.0-0'}}</span>
                    <span class="right-align">{{test.instrumentsTransmitting | number: '1.0-0'}}</span>
                    <div class="table-row" *ngFor="let analyte of test.analytes; index as ind"
                        [style.grid-row-start]="2 + ind" [style.grid-row-end]="'auto'" [style.padding-left]="0"
                        [style.-ms-grid-row]="2 + ind"
                        [class.hidden]="!this.expandedRows.includes(i) || (this.expandedRows.includes(i) && test.analytes.length <= 1)">
                        <span>{{analyte.analyteName}}</span>
                        <span class="right-align">{{test.totalTests | number: '1.0-0'}}</span>
                        <span
                            class="right-align">{{((analyte.totalPositive / (test.totalPositive + test.totalNegative)) * 100) | number: '1.0-1'}}%
                            ({{analyte.totalPositive | number: '1.0-0'}})</span>
                        <span
                            class="right-align">{{((analyte.totalNegative / (test.totalPositive + test.totalNegative)) * 100) | number: '1.0-1'}}%
                            ({{analyte.totalNegative | number: '1.0-0'}})</span>
                        <span class="right-align">{{analyte.totalInvalid | number: '1.0-0'}}</span>
                        <span class="right-align">{{test.instrumentsTransmitting | number: '1.0-0'}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="maincontent" *ngIf="selectedTab === 1">
            <div class="table-grid header instrument-grid noselect" [style.padding-left]="0">
                <span (click)="onInstrumentsColumnSort('isReporting', $event)" class="center-align">Instruments
                    Reporting <i
                        class="material-icons">{{this.instrumentsSort['isReporting'] === 1 ? 'arrow_drop_up' : this.instrumentsSort['isReporting'] === 2 ? 'arrow_drop_down' : ''}}</i></span>
                <span (click)="onInstrumentsColumnSort('serialNumber', $event)">Serial Number <i
                        class="material-icons">{{this.instrumentsSort['serialNumber'] === 1 ? 'arrow_drop_up' : this.instrumentsSort['serialNumber'] === 2 ? 'arrow_drop_down' : ''}}</i></span>
                <span (click)="onInstrumentsColumnSort('facilityName', $event)">Facility Name <i
                        class="material-icons">{{this.instrumentsSort['facilityName'] === 1 ? 'arrow_drop_up' : this.instrumentsSort['facilityName'] === 2 ? 'arrow_drop_down' : ''}}</i></span>
                <span (click)="onInstrumentsColumnSort('streetAddress1', $event)">Street Address <i
                        class="material-icons">{{this.instrumentsSort['streetAddress1'] === 1 ? 'arrow_drop_up' : this.instrumentsSort['streetAddress1'] === 2 ? 'arrow_drop_down' : ''}}</i></span>
                <span (click)="onInstrumentsColumnSort('stateCode', $event)">State <i
                        class="material-icons">{{this.instrumentsSort['stateCode'] === 1 ? 'arrow_drop_up' : this.instrumentsSort['stateCode'] === 2 ? 'arrow_drop_down' : ''}}</i></span>
                <span (click)="onInstrumentsColumnSort('zipCode', $event)">ZIP <i
                        class="material-icons">{{this.instrumentsSort['zipCode'] === 1 ? 'arrow_drop_up' : this.instrumentsSort['zipCode'] === 2 ? 'arrow_drop_down' : ''}}</i></span>
            </div>
            <div class="table-grid content">
                <div class="table-row instrument-grid"
                    *ngFor="let instrument of instrumentsSummary.Instruments; index as i" [style.padding-left]="0">
                    <span class="center-align" [ngClass]="instrument.isReporting ? 'green' : 'red'"><i
                            class="material-icons"
                            [style.line-height.px]="48">{{instrument.isReporting ? 'check_circle' : 'remove_circle'}}</i></span>
                    <span>{{instrument.serialNumber}}</span>
                    <span matTooltip="{{instrument.facilityName}}">{{instrument.facilityName}}</span>
                    <span matTooltip="{{instrument.streetAddress1}}">{{instrument.streetAddress1}}</span>
                    <span>{{instrument.stateCode}}</span>
                    <span>{{instrument.zipCode}}</span>
                </div>
            </div>
            <div class="paginator">
                <mat-paginator [length]="instrumentsSummary.Total" [pageSize]="rowsPerPage"
                    [pageSizeOptions]="pageSizeOptions" showFirstLastButtons="true"
                    (page)="loadInstrumentsLazy($event)">
                </mat-paginator>
            </div>
        </div>
        <div class="maincontent" *ngIf="selectedTab === 2">
            <div class="news-body" [innerHTML]="this.news.body"></div>
        </div>
    </div>
</div>
