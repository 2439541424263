<mat-list>
    <mat-list-item class="mat-list-item">
      {{ 'FavortiesAddEditDialog.SelectedFiltersDates' | translate }} {{ getDatesDescription() }}
      {{ getDatesDescriptionTranslate() | translate }}
    </mat-list-item >
    <mat-list-item class="mat-list-item" *ngIf="getAssaysDescription() != noneDescription">
      {{ 'FavortiesAddEditDialog.SelectedFiltersAssays' | translate }} {{ getAssaysDescription() | translate }}
    </mat-list-item>
    <mat-list-item  class="mat-list-item" *ngIf="getResultsDescription() != noneDescription">
      {{ 'FavortiesAddEditDialog.SelectedFiltersResults' | translate }}
      {{ getResultsDescription() | translate }}
    </mat-list-item>
    <mat-list-item class="mat-list-item" *ngIf="(orgsDescription | async) != noneDescription ">
      <div class="hierarchy">
        <div class="item">
          {{ 'FavortiesAddEditDialog.SelectedFiltersOrg' | translate }} {{ orgsDescription | async | translate }}
        </div>

        <div class="item" *ngIf="(facilitiesDescription | async) != noneDescription ">
            {{ 'FavortiesAddEditDialog.SelectedFiltersFac' | translate }} {{ facilitiesDescription | async | translate }}
        </div>
      </div>
    </mat-list-item>
    <mat-list-item class="mat-list-item" *ngIf="getCountry() != noneDescription">

      <div class="hierarchy">
        <div class="item">
            {{ 'FavortiesAddEditDialog.SelectedFiltersLocation' | translate }} {{ getCountry() | translate  }}
        </div>

        <div class="item" *ngIf="(statesDescription | async) != noneDescription">
            {{ 'FavortiesAddEditDialog.SelectedFiltersLocationStates' | translate }} {{ statesDescription | async | translate  }} 
        </div>

        <div class="item" *ngIf="(countiesDescritpion | async) != noneDescription">
            {{ 'FavortiesAddEditDialog.SelectedFiltersLocationCounties' | translate }} {{ countiesDescritpion | async | translate  }}
        </div>

      </div>

    </mat-list-item>
    <mat-list-item class="mat-list-item" *ngIf="getOperatorDescription() != noneDescription">
      {{ 'FavortiesAddEditDialog.SelectedFiltersOperator' | translate }}
      {{ getOperatorDescription() | translate }}
    </mat-list-item>
    <mat-list-item class="mat-list-item" *ngIf="getZipCodeDescription() != noneDescription">
      {{ 'FavortiesAddEditDialog.SelectedFiltersZipCode' | translate }}
      {{ getZipCodeDescription() | translate }}
    </mat-list-item>
    <mat-list-item class="mat-list-item" *ngIf="getSerialDescription() != noneDescription">
      {{ 'FavortiesAddEditDialog.SelectedFiltersSerial' | translate }} {{ getSerialDescription() | translate }}
    </mat-list-item>
  </mat-list>