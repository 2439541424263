import { Component } from '@angular/core';
import { FilterService } from '../../services/filter.service';
import { DateUIQuery } from '../filters/filter-custom-date/filter-custom-date.model';
import { AssaysUIQuery } from '../filters/filter-custom-assays/filter-custom-assays.model';
import { ResultTypesUIQuery } from '../filters/result-types-custom-filter/result-types-custom-filter.model';
import { OperatorUIQuery } from '../filters/filter-custom-operator/filter-custom-operator.model';
import { ZipCodeUIQuery } from '../filters/filter-custom-zipcode/filter-custom-zipcode.model';
import { SerialNumberUIQuery } from '../filters/filter-custom-serial-number/filter-custom-serial-number.model';
import { GeoUIQuery } from '../filters/filter-custom-geo/filter-custom-geo.model';
import { AddEditModalComponent } from '../filters/filter-custom-favorites/modals/add-edit-modal/add-edit-modal/add-edit-modal.component';
import { OrgsFacilitiesUIQuery } from '../filters/filter-custom-orgs-facilities/filter-custom-orgs-facilities.model';

@Component({
  selector: 'core-filters-summary',
  templateUrl: './filters-summary.component.html',
  styleUrls: ['./filters-summary.component.scss']
})
export class FiltersSummaryComponent {

  private dateUIQuery: DateUIQuery;
  private assaysUIQuery: AssaysUIQuery;
  private resultsUIQuery: ResultTypesUIQuery;
  private operatorUIQuery: OperatorUIQuery;
  private zipCodeUIQuery: ZipCodeUIQuery;
  private serialNumberUIQuery: SerialNumberUIQuery;
  private orgsFacilityUIQuery: OrgsFacilitiesUIQuery;
  private geoUIQuery: GeoUIQuery;

  private separator = ', ';
  public noneDescription = `${AddEditModalComponent.FavortiesAddEditDialogKey}.NoneDescription`;

  orgsDescription: Promise<string>;
  facilitiesDescription: Promise<string>;
  statesDescription: Promise<string>;
  countiesDescritpion: Promise<string>;

  constructor(
    private filterService: FilterService,
  ) {

    this.dateUIQuery = this.filterService.getDateUIQuery();
    this.assaysUIQuery = this.filterService.getAssaysUIQuery();
    this.resultsUIQuery = this.filterService.getResultTypesQuery();
    this.operatorUIQuery = this.filterService.getOperatorQuery();
    this.zipCodeUIQuery = this.filterService.getZipCodeQuery();
    this.serialNumberUIQuery = this.filterService.getSerialNumberQuery();
    this.orgsFacilityUIQuery = this.filterService.getOrgsFacilitiesQuery();
    this.geoUIQuery = this.filterService.getGeoQuery();

    this.orgsDescription = this.getOrganizations();
    this.facilitiesDescription = this.getFacilities();

    this.statesDescription = this.getStates();
    this.countiesDescritpion = this.getCounties();

  }

  getDatesDescription(): string {
    return this.dateUIQuery.getSelected().item.getDescription();
  }

  getDatesDescriptionTranslate(): string {
    return this.dateUIQuery.getSelected().item.getDescriptionTranslate();
  }

  getAssaysDescription(): string {
    const assays = this.assaysUIQuery.getSelectedItems();

    if (assays && assays.length > 0) {
      return assays.join(this.separator);
    }

    return this.noneDescription;
  }

  getResultsDescription(): string {
    const results = this.resultsUIQuery.getSelectedItems();

    if (results && results.length > 0) {
      return results.join(this.separator);
    }

    return this.noneDescription;
  }

  getOperatorDescription(): string {
    const operator = this.operatorUIQuery.getOperator().item.toString();

    if (operator) {
      return operator;
    }

    return this.noneDescription;
  }

  getZipCodeDescription(): string {
    const zipCode = this.zipCodeUIQuery.getZipCode().item.toString();

    if (zipCode) {
      return zipCode;
    }

    return this.noneDescription;
  }

  getSerialDescription(): string {
    const serialNumber = this.serialNumberUIQuery.getSerialNumber().item.toString();

    if (serialNumber) {
      return serialNumber;
    }

    return this.noneDescription;
  }

  private async getOrganizations(): Promise<string> {

    const orgs = this.orgsFacilityUIQuery.getSelectedOrganizations();
    let orgsDescription = this.noneDescription;
    if (orgs && orgs.length > 0) {
      orgsDescription = orgs.map(f => f.getLabel()).join(this.separator);
    }

    return Promise.resolve(orgsDescription);
  }

  private async getFacilities(): Promise<string> {

    const facilities = await this.orgsFacilityUIQuery.getSelectedFacilities();
    let facilitiesDescription = this.noneDescription;
    if (facilities) {
      facilitiesDescription = facilities.map(f => f.getLabel()).join(this.separator);
    }

    return Promise.resolve(facilitiesDescription);
  }

  getCountry(): string {
    const country = this.geoUIQuery.getSelectedCountry();
    let countryDescription = this.noneDescription;
    if (country) {
      countryDescription = country.getLabel();
    }

    return countryDescription;
  }

  private async getStates(): Promise<string> {
    const states = await this.geoUIQuery.getSelectedStates();
    let statesDescription = this.noneDescription;
    if (states) {
      statesDescription = states.map(s => s.getLabel()).join(this.separator);
    }

    return Promise.resolve(statesDescription);
  }

  private async getCounties(): Promise<string> {
    const counties = await this.geoUIQuery.getSelectedCounties();
    let countiesDescription = this.noneDescription;
    if (counties) {
      countiesDescription = counties.map(c => c.getLabel()).join(this.separator);
    }

    return countiesDescription;
  }


}
