<ng-template #dropdown>
    <div class="list-container">
        <div class="search-container" *ngIf="showSearch">
            <input class="search" placeholder="Search..." [value]="searchText" (keydown)="listKeyDown($event)" (input)="onSearchChanged($event.target.value)"/>
            <i class="material-icons clear-icon" (click)="clearSearch()">clear</i>
        </div>
        <cdk-virtual-scroll-viewport [itemSize]="itemSize" class="virtual-list" [style.height.px]="256" (keydown)="listKeyDown($event)">
            <mat-option *cdkVirtualFor="let obj of filteredItems" [value]="getValue(obj)" (keydown)="itemKeyDown($event, obj)" (click)="itemSelected(obj)"
                [class.mat-selected]="value === getValue(obj)">
                {{getLabel(obj)}}
            </mat-option>
        </cdk-virtual-scroll-viewport>
    </div>
</ng-template>

<div class="container" #origin (click)="open(dropdown, origin)" [class.disabled]="isDisabled">
    <span class="label">{{getDisplayValue()}}</span>
    <i class="material-icons icon">arrow_drop_down</i>
</div>
