import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { RoleAccessService } from '../services/role-access.service';
import { MsalGuard } from '@azure/msal-angular';

@Injectable({
  providedIn: 'root'
})
export class NavigationGuard implements CanActivate {

  constructor(
    private accessService: RoleAccessService,
    private router: Router,
    private msalGuard: MsalGuard
  ) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

    const isAuthenticated = await this.msalGuard.canActivate(route, state).toPromise() as boolean;
    if (!isAuthenticated) {
      return false;
    }

    const routes: string[] = [];

    route.pathFromRoot.forEach(p => {
      if (p.routeConfig) {
        routes.push(p.routeConfig.path);
      }
    });

    const path = routes.join('/');
    const canAccess = await this.accessService.checkAccess(path);

    return canAccess;
  }

}
