import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ConfigurationService } from './configuration.service';
import { Observable } from 'rxjs';
import { TestResultResponse } from './testresults.service.models';

@Injectable({
  providedIn: 'root'
})
export class TestResultsService {

  private apiUrl: string;
  private instrumentResultsV1BaseUrl = 'api/v1.0/TestResults';
  private getUrl = this.instrumentResultsV1BaseUrl + '/get';
  private getNewsUrl = 'api/v1.0/News/Get';

  constructor(private httpClient: HttpClient, configurationService: ConfigurationService) {

    const config = configurationService.getConfiguration();

    this.apiUrl = config.testResultsServiceEndpoint;
    if (!this.apiUrl.endsWith('/')) {
      this.apiUrl = `${this.apiUrl}/`;
    }
  }

  private getFullUrl(subPath: string) {
    return this.apiUrl + subPath;
  }

  public getNews(): Observable<{ header: string, body: string }> {
    return this.httpClient.get<{ header: string, body: string }>(this.getFullUrl(this.getNewsUrl));
  }

  public getTestResults(id: string): Observable<TestResultResponse> {
    return this.httpClient.get<TestResultResponse>(this.getFullUrl(this.getUrl),
      {
        params: new HttpParams()
          .set('idKey', id)
      });
  }
}
