<div class="chartContainer">
    <google-chart *ngIf="showChart"
    #chart id="chart"
    [width]="chartWidth"
    [height]="chartHeight"
    [title]="chartTitle"
    [type]="chartType"
    [data]="chartData"
    [columns]="chartColumnNames"
    [options]="chartOptions"
    (error)="chartError($event)"
    (ready)="chartReady()">
    </google-chart>
</div>
