import { NgModule } from '@angular/core';
import { PatientsAssayComponent } from './patients-assay/patients-assay.component';
import { PatientsFacilityAssayComponent } from './patients-facility-assay/patients-facility-assay.component';
import { PatientsFacilityResultComponent } from './patients-facility-result/patients-facility-result.component';
import { PatientsRunDateComponent } from './patients-run-date/patients-run-date.component';
import { PatientsCoInfectionComponent } from './patients-coinfection/patients-coinfection.component';
import { GoogleChartsModule } from 'angular-google-charts';
import { CommonModule } from '@angular/common';
import { PatientsResultComponent } from './patients-result/patients-result.component';
import { TestVolumeTypeComponent } from './test-volume-type/test-volume-type.component';
import { PercentPositiveRunDateComponent } from './percent-positive-run-date/percent-positive-run-date.component';
import { PatientsResultTrendsComponent } from './patients-result-trends/patients-result-trends.component';
import { PatientsResultTrendsDetailComponent } from './patients-result-trends-detail/patients-result-trends-detail.component';


@NgModule({
  declarations: [PatientsAssayComponent,
    PatientsFacilityAssayComponent,
    PatientsFacilityResultComponent,
    PatientsRunDateComponent,
    PatientsCoInfectionComponent,
    PatientsResultComponent,
    TestVolumeTypeComponent,
    PercentPositiveRunDateComponent,
    PatientsResultTrendsComponent,
    PatientsResultTrendsDetailComponent],
  imports: [
    CommonModule,
    GoogleChartsModule.forRoot(),
  ],
  exports: [
    PatientsAssayComponent,
    PatientsFacilityAssayComponent,
    PatientsFacilityResultComponent,
    PatientsRunDateComponent,
    PatientsCoInfectionComponent,
    PatientsResultComponent,
    TestVolumeTypeComponent,
    PercentPositiveRunDateComponent,
    PatientsResultTrendsComponent,
    PatientsResultTrendsDetailComponent
  ]
})
export class ReportsCoreModule { }
