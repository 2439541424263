import { Component, OnInit, OnDestroy, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ReportBaseComponent } from '../report-base.component';
import { TestResult_PatientsCoInfectionRow } from '../../../services/search.service.testresult.models';
import { MetadataService } from '../../../services/metadata.service';
import { TestType, InitDataSelectionLists } from '../../../services/metadata.service.models';
import { BusyService } from '../../../services/busy.service';

@Component({
  selector: 'core-patients-coinfection',
  templateUrl: './patients-coinfection.component.html',
  styleUrls: ['./patients-coinfection.component.scss']
})
export class PatientsCoInfectionComponent extends ReportBaseComponent implements OnInit, OnDestroy {

  @Input() data: TestResult_PatientsCoInfectionRow[];
  showChart: boolean;
  chartType: string;
  chartTitle: string;
  chartData: Array<Array<string | number>>;
  chartOptions: Record<string, unknown>;
  chartRoles: Array<{ type: string; role: string; index?: number }>;
  chartColumnNames: Array<any>;
  testTypes: TestType[];
  public testName: string;
  public totalTestCount: number;
  public totalPositivesCount: number;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    translateService: TranslateService,
    elementRef: ElementRef,
    busyService: BusyService,
    private metadataService: MetadataService) {
    super(changeDetectorRef, translateService, 'PatientsCoInfections', busyService, elementRef);
    this.showChart = false;
  }

  ngOnInit(): void {

    this.subscription.add(this.metadataService.fetchInitDataSelectionLists().subscribe((initData: InitDataSelectionLists) => {
      this.testTypes = initData.TestTypes;

      if (this.data) {
        this.setData(this.data);
      }
    }));

    this.chartType = 'BarChart';
    this.chartOptions = {
      legend: 'none',
      bar: { groupWidth: '75%' },
      hAxis: {
        title: this.getText('HAxis'),
        slantedText: true,
        slantedTextAngle: 90,
        showTextEvery: 1,
        minValue: 0,
        maxValue: 10 // This helps ensure only whole numbers appear on the hAxis when the data set contains only small numbers. It has no effect on larger data sets.
      },
      vAxis: {
        title: this.getText('VAxis')
      },
      chartArea: { left: 150, right: 250, top: 40, bottom: 150 }
    };
    this.chartColumnNames = ['ColumnName', this.getText('PatientCount'), { role: 'style' }];
    this.chartData = [[]];
  }

  ngOnDestroyInternal(): void {
    // Required by base component
  }

  onTranslateResourcesLoaded(): void {
    this.setTitle();
  }

  onTranslateLanguageChanged(): void {
    this.setTitle();
  }

  private setTitle() {
    this.chartTitle = this.getText('Title');
  }

  public setData(data: TestResult_PatientsCoInfectionRow[]): void {
    this.beginSetData();
    this.data = data;
    this.totalTestCount = 0;
    this.totalPositivesCount = 0;

    const testType = this.testTypes.find(t => t.testName === this.testName);
    const testColor = testType ? testType.color : '#98999b';
    const convData: (string | number)[][] = [];

    data.forEach((row) => {
      if (row.analyteSet) {
        if (!row.testCount) {
          convData.push([row.analyteSet, 0, testColor]);
        } else {
          convData.push([row.analyteSet, row.testCount, testColor]);
          this.totalPositivesCount += row.testCount;
        }
      }

      this.totalTestCount += row.testCount ? row.testCount : 0;
    });

    convData.sort((a, b) => (<number>b[1]) - (<number>a[1]));

    this.chartData = convData;
    this.showChart = true;
  }
}
