<div *ngIf="isOpened" class="container">

    <div class="title">
        <span>{{'FilterCustomSerialNumber.Title' | translate}}</span>
        <i class="material-icons clear flipped" (click)="clearFilter()">refresh</i>
    </div>

    <div *ngFor="let i of query.items" (click)="selectItem(i)" class="submenu-item filter-option">
        <div class="submenu-item-container">
            <div>
                <div class="submenu-item">
                    <form [formGroup]="serialNumberFormGroup">
                        <mat-form-field class="mat-form-field-width" floatLabel="never">
                            <input matInput (focusout)="serialNumberChanged($event)" formControlName="serialNumber"
                                id="serialNumber"
                                placeholder="{{ 'FilterCustomSerialNumber.SerialNumberHeader' | translate }}"
                                noCustomValidation="true"
                                [matAutocomplete]="auto">
                            <mat-autocomplete #auto="matAutocomplete">
                                <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                                    {{option}}
                                </mat-option>
                            </mat-autocomplete>
                            <div class="error" *ngIf="hasError('maxlength')">
                                {{ 'FilterCustomSerialNumber.SerialNumberMaxLengthValidation' | translate }}</div>
                        </mat-form-field>
                    </form>
                </div>
            </div>
            <div class="submenu-item-state" [class.has-filter-selected]="i.isSelected">
            </div>
        </div>
    </div>

</div>
