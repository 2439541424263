<div class="container">

    <div class="menu-container">
        <div class="filter-heading" [class.filter-active]="hasActiveFilters" (click)="showAppliedFiltersStatus()">
            <span class="hasBadge" matBadge="i" matBadgeOverlap="false" matBadgeSize="small" [class.active-badge]="hasActiveFilters">
                {{(hasActiveFilters ? 'FilterCore.TitleApplied' : 'FilterCore.Title') | translate}}
            </span>

            <i class="material-icons" *ngIf="hasActiveFilters">expand_more</i>
        </div>
        <div *ngFor="let f of filters">
            <div class="menu-item filter-option" #menuEl (click)="tryToggle(f, menuEl)"
                [class.filter-option-open]="f.isOpened">
                <div class="menu-item-container">
                    <div class="label">
                        {{f.title}}
                    </div>
                    <div class="menu-item-filter" [class.has-filter-selected]="isFilterActive(f)">
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div #filtersContainer class="submenu">
    </div>

</div>