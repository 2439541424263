import { Injectable } from '@angular/core';
import { SettingsKeys } from '../utils/settings.keys';
import { Configuration, MsalConfigData } from './configuration.service.models';

@Injectable()
export class ConfigurationServiceStub {

  private static readonly notInitializedError = new Error('ConfigurationService must be initialized first.');

  private configuration: Configuration;
  private initialized = false;

  constructor() {
    const configuration = {
      metadataServiceEndpoint: 'http://localhost:55910/',
      testResultsServiceEndpoint: 'http://localhost:55911/',
      searchServiceEndpoint: 'http://localhost:55911/',
      msalConfig: {
        tenant: 'myvirenab2cdev.onmicrosoft.com',
        clientId: '71966b99-8feb-488f-9072-c392cba71d19',
        signInPolicy: 'B2C_1_SignInAndRegisterSPA',
        resetPwdPolicy: 'B2C_1_PasswordResetPolicySPA',
        redirectUri: 'http://localhost:4200',
        b2cScopes: ['https://myvirenab2cdev.onmicrosoft.com/metadataapi/user_impersonation']
      },
      appInsightsKey: '984fa615-88b9-46a1-9f9f-93c93cdf1ac3',
      GoogleAnalyticsTrackingID: 'G-VQ2XDB5C06',
      GoogleMapApiKey: 'AIzaSyDZz9hVJ9ZA7YhLTnrrDg9m0pLCpPtXT-o'
    };

    const appInsightsConfigKey = configuration.appInsightsKey;
    const msalConfig = configuration.msalConfig as MsalConfigData;

    this.configuration = {
      version: '1.0.0.0',
      production: true,
      metadataServiceEndpoint: configuration.metadataServiceEndpoint,
      testResultsServiceEndpoint: configuration.testResultsServiceEndpoint,
      searchServiceEndpoint: configuration.searchServiceEndpoint,
      appInsightsKey: appInsightsConfigKey,
      msalConfig: {
        tenant: msalConfig.tenant,
        clientId: msalConfig.clientId,
        signInPolicy: msalConfig.signInPolicy,
        resetPwdPolicy: msalConfig.resetPwdPolicy,
        redirectUri: msalConfig.redirectUri,
        b2cScopes: msalConfig.b2cScopes,
        authority: `https://${msalConfig.tenant}.b2clogin.com/${msalConfig.tenant}.onmicrosoft.com/${msalConfig.signInPolicy}`,
        resetAuthority: `https://${msalConfig.tenant}.b2clogin.com/${msalConfig.tenant}.onmicrosoft.com/${msalConfig.resetPwdPolicy}`
      },
      isLoaded: false,
      GoogleAnalyticsTrackingID: configuration.GoogleAnalyticsTrackingID,
      GoogleMapApiKey: configuration.GoogleMapApiKey
    };

    console.log(`Application Version: ${this.configuration.version}`);

    const healthCheckResult = {
      metadata: { version: '1.0.0.0', status: 'healthy' },
      testResults: { version: '1.0.0.0', status: 'healthy' },
      search: { version: '1.0.0.0', status: 'healthy' },
    };

    if (healthCheckResult) {
      this.configuration.metadataApiVersion = healthCheckResult.metadata.version;
      this.configuration.testResultsApiVersion = healthCheckResult.testResults.version;
      this.configuration.searchApiVersion = healthCheckResult.search.version;
      console.log(`Metadata API Version: ${this.configuration.metadataApiVersion}`);
      console.log(`TestResults API Version: ${this.configuration.testResultsApiVersion}`);
      console.log(`Search API Version: ${this.configuration.searchApiVersion}`);
    }

    this.initialized = true;
  }

  public initializeConfiguration(writeLogMessages: boolean = true): Promise<void> {
    return Promise.resolve();
  }

  public getConfiguration(): Configuration {
    if (!this.initialized) {
      throw ConfigurationServiceStub.notInitializedError;
    }

    return this.configuration;
  }

  public setFeatures(features: Record<string, boolean>): void {
    if (!this.initialized) {
      throw ConfigurationServiceStub.notInitializedError;
    }

    const keys = Object.keys(features);
    const featuresMap = new Map<string, boolean>();
    keys.forEach(k => {
      featuresMap.set(k, features[k]);
    });
    this.configuration.features = featuresMap;
  }

  public setSettings(settings: Record<string, string>): void {
    if (!this.initialized) {
      throw ConfigurationServiceStub.notInitializedError;
    }

    this.configuration.isUnauthorized = false;

    const keys = Object.keys(settings);
    const settingsMap = new Map<string, string>();
    keys.forEach(k => {
      settingsMap.set(k, settings[k]);

      if (k === SettingsKeys.isUnauthorized) {
        this.configuration.isUnauthorized = true;
      }
    });
    this.configuration.settings = settingsMap;
  }
}
