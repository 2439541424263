import { TranslateBaseComponent } from '../../translate/translate-base.component';
import { OnDestroy, ViewChild, ChangeDetectorRef, HostListener, ElementRef, AfterViewInit, Directive } from '@angular/core';
import jsPDF from 'jspdf';
import { GoogleChartComponent, ChartErrorEvent } from 'angular-google-charts';
import { saveAs } from 'file-saver';
import { TranslateService } from '@ngx-translate/core';
import { BusyService } from '../../services/busy.service';

// Using Directive decorator instead of @Component or @Injectable so that the there isn't any DI compatibility checks
// which causes the `translationKey` argument to fail compilation
// https://stackoverflow.com/a/63502246/10752002
@Directive()
export abstract class ReportBaseComponent extends TranslateBaseComponent implements OnDestroy, AfterViewInit {

  @ViewChild('chart') protected chart: GoogleChartComponent;

  public chartWidth: number;
  public chartHeight: number;

  baseWidth = 800;
  baseHeight = 600;
  public offsetHeight = 100;
  public offsetWidth = 75;

  constructor(private changeDetectorRef: ChangeDetectorRef, translateService: TranslateService, translationKey: string,
    private busyService: BusyService,
    private elementRef: ElementRef<HTMLElement>) {
    super(translateService, translationKey);
  }

  public saveAsPdf(fileName: string = 'chart.pdf'): void {
    setTimeout(() => { this.changeDetectorRef.detectChanges(); }, 500);
    const doc = new jsPDF('landscape', 'px', [this.chartWidth, this.chartHeight]);
    const docWidth = doc.internal.pageSize.getWidth();
    const docHeight = doc.internal.pageSize.getHeight();
    const renderableChart = this.chart.chartWrapper.getChart() as google.visualization.ChartBaseRenderable;
    doc.addImage(renderableChart.getImageURI(), 0, 0, docWidth, docHeight);
    doc.save(fileName);
  }

  public saveAsImage(fileName: string = 'chart.png'): void {
    setTimeout(() => { this.changeDetectorRef.detectChanges(); }, 500);
    const renderableChart = this.chart.chartWrapper.getChart() as google.visualization.ChartBaseRenderable;
    const image = renderableChart.getImageURI();
    saveAs(image, fileName);
  }

  ngAfterViewInit(): void {
    this.adjustSizing();
  }

  private adjustSizing() {
    let newWidth = this.baseWidth;
    let newHeight = this.baseHeight;

    if (this.elementRef && this.elementRef.nativeElement) {
      const container = this.elementRef.nativeElement.parentElement.parentElement;

      if (container) {
        const width = container.offsetWidth - this.offsetWidth;
        const height = container.offsetHeight - this.offsetHeight;

        newWidth = Math.max(newWidth, width);
        newHeight = Math.max(newHeight, height);
      }
    }

    this.chartHeight = newHeight;
    this.chartWidth = newWidth;
  }

  @HostListener('window:resize') onResize(): void {
    this.adjustSizing();
  }

  abstract setData(data: any[]): void;
  protected beginSetData(): void {
    console.log('beginSetData');
    this.busyService.busy();
  }

  public chartReady(): void {
    console.log('chartReady');
    setTimeout(() => this.busyService.idle(), 500);
  }

  public chartError(errorEvent: ChartErrorEvent): void {
    console.error(errorEvent);
    setTimeout(() => this.busyService.idle(), 500);
  }
}
