<div class="nav-container">
  <div class="nav-links">
    <a routerLink="{{routes.Summary}}" routerLinkActive="selected"><i matTooltip="{{'NavBar.SummaryTooltip' | translate}}" class="material-icons">speed</i></a>
    <a routerLink="{{routes.Results}}" routerLinkActive="selected"><i matTooltip="{{'NavBar.ResultsTooltip' | translate}}" class="material-icons">table_chart</i></a>
    <a routerLink="{{routes.Mapping}}" routerLinkActive="selected"><i matTooltip="{{'NavBar.MappingTooltip' | translate}}" class="material-icons">language</i></a>
  </div>
  <div class="filters" [class.filters-applied]="hasActiveFilters">
    <i matRipple [matRippleDisabled]="true" [matRippleUnbounded]="true" class="material-icons" matTooltip="{{'NavBar.SearchTooltip' | translate}}" [class.highlight-search]="filtersChanged" (click)="onSearch()">search</i>
    <i class="material-icons flipped" matTooltip="{{'NavBar.ResetFiltersTooltip' | translate}}" (click)="resetFilters()">refresh</i>

    <div class="breadcrumb">
      <span class="breadcrumb-title" *ngIf="!hasActiveFilters">{{'NavBar.Title' | translate}}</span>
      <div class="filter-crumb-container">
        <span matTooltip="{{dateTooltip}}" matBadge="i" matBadgeOverlap="false" matBadgeSize="small"
          [matBadgeHidden]="!dateTooltip || dateTooltip.length === 0">{{dateRange}}</span>
        <span [class.hidden]="!assays || assays?.length === 0">/</span>
        <span [class.hidden]="!assays || assays?.length === 0" matBadge="{{assays?.length}}" matBadgeOverlap="false"
          matBadgeSize="small" [matBadgeHidden]="!assays || assays.length <= 1"
          matTooltip="{{assays && assays.length > 1 ? assays.join(', ') : ''}}">{{assays ? assays.length > 1 ? '[Multiple Assays]' : assays[0] : '[Assay All]'}}</span>
        <span [class.hidden]="!results || results?.length === 0">/</span>
        <span [class.hidden]="!results || results?.length === 0" matBadge="{{results?.length}}" matBadgeOverlap="false"
          matBadgeSize="small" [matBadgeHidden]="!results || results.length <= 1"
          matTooltip="{{results && results.length > 1 ? results.join(', ') : ''}}">{{results ? results.length > 1 ? '[Multiple Results]' : results[0] : '[Results All]'}}</span>
        <span [class.hidden]="!organizations || organizations.length === 0">/</span>
        <span [class.hidden]="!organizations || organizations.length === 0" matBadge="{{filters?.orgsFacilities?.length}}"
          matBadgeOverlap="false" matBadgeSize="small"
          [matBadgeHidden]="!filters?.orgsFacilities || (filters?.orgsFacilities.length === 1 && filters?.orgsFacilities[0].facilityIds?.length === 0)"
          matTooltip="{{organizationTooltip}}">{{organizations && organizations.length === 1 ? truncate(organizations[0], 20) : '[Multiple Organizations]'}}</span>
        <span [class.hidden]="!filters?.geo">/</span>
        <span [class.hidden]="!filters?.geo" matBadge="{{totalGeoSubItems}}" matBadgeOverlap="false"
          matBadgeSize="small" [matBadgeHidden]="!filters?.geo || totalGeoSubItems === 0"
          matTooltip="{{locationTooltip}}">{{filters?.geo ? location : '[Location All]'}}</span>
        <span [class.hidden]="!filters?.operator">/</span>
        <span [class.hidden]="!filters?.operator"
          matTooltip="{{filters?.operator ? 'Operator' : ''}}">{{filters?.operator ? filters?.operator : '[Operator All]'}}</span>
        <span [class.hidden]="!filters?.zipCode">/</span>
        <span [class.hidden]="!filters?.zipCode"
          matTooltip="{{filters?.zipCode ? 'Zip Code' : ''}}">{{filters?.zipCode ? filters?.zipCode : '[Zip Code All]'}}</span>
        <span [class.hidden]="!filters?.serialNumber">/</span>
        <span [class.hidden]="!filters?.serialNumber"
          matTooltip="{{filters?.serialNumber ? 'Serial Number' : ''}}">{{filters?.serialNumber ? filters?.serialNumber : '[Serial All]'}}</span>
      </div>
    </div>

    <div class="right-side">
      <span class="results" [class.hidden]="resultsCount === 0 || !resultsCount">{{resultsCount?.toLocaleString()}}
        results</span>
      <mat-icon matTooltip="{{'NavBar.ReportListTooltip' | translate}}" [matMenuTriggerFor]="reportMenu">insert_chart_outlined</mat-icon>
      <mat-menu #reportMenu>
        <div matTooltip="{{'ReportMenu.RequirePatient' | translate}}" [matTooltipDisabled]='isPatientsByRunDateEnabled'>
          <button mat-menu-item (click)="generateReport(getReportLink(ReportKeys.PatientsByRunDate))"
            disabled="{{!isPatientsByRunDateEnabled}}">
            <span matBadge="i" matBadgeOverlap="false" matBadgeSize="small"
              [matBadgeHidden]='isPatientsByRunDateEnabled'
              class="report-menu-item">{{'ReportMenu.PatientsByRunDate' | translate}}</span>
          </button>
        </div>
        <div matTooltip="{{'ReportMenu.RequirePatient' | translate}}" [matTooltipDisabled]='isPatientsByAssayEnabled'>
          <button mat-menu-item (click)="generateReport(getReportLink(ReportKeys.PatientsByAssay))"
            disabled="{{!isPatientsByAssayEnabled}}">
            <span matBadge="i" matBadgeOverlap="false" matBadgeSize="small" [matBadgeHidden]='isPatientsByAssayEnabled'
              class="report-menu-item">{{'ReportMenu.PatientsByAssay' | translate}}</span>
          </button>
        </div>
        <div matTooltip="{{'ReportMenu.RequirePatientOrg' | translate}}"
          [matTooltipDisabled]='isPatientsByFacilityAssayEnabled'>
          <button mat-menu-item (click)="generateReport(getReportLink(ReportKeys.PatientsByFacilityAssay))"
            disabled="{{!isPatientsByFacilityAssayEnabled}}">
            <span matBadge="i" matBadgeOverlap="false" matBadgeSize="small"
              [matBadgeHidden]='isPatientsByFacilityAssayEnabled'
              class="report-menu-item">{{'ReportMenu.PatientsByFacilityAssay' | translate}}</span>
          </button>
        </div>
        <div matTooltip="{{'ReportMenu.RequirePatientOrgAssay' | translate}}"
          [matTooltipDisabled]='isPatientsByFacilityResultEnabled'>
          <button mat-menu-item (click)="generateReport(getReportLink(ReportKeys.PatientsByFacilityResult))"
            disabled="{{!isPatientsByFacilityResultEnabled}}">
            <span matBadge="i" matBadgeOverlap="false" matBadgeSize="small"
              [matBadgeHidden]='isPatientsByFacilityResultEnabled'
              class="report-menu-item">{{'ReportMenu.PatientsByFacilityResult' | translate}}</span>
          </button>
        </div>
        <div matTooltip="{{'ReportMenu.RequirePatient' | translate}}" [matTooltipDisabled]='isPatientsByResultEnabled'>
          <button mat-menu-item (click)="generateReport(getReportLink(ReportKeys.PatientsByResult))"
            disabled="{{!isPatientsByResultEnabled}}">
            <span matBadge="i" matBadgeOverlap="false" matBadgeSize="small" [matBadgeHidden]='isPatientsByResultEnabled'
              class="report-menu-item">{{'ReportMenu.PatientsByResult' | translate}}</span>
          </button>
        </div>
        <div matTooltip="{{'ReportMenu.RequirePatient' | translate}}"
          [matTooltipDisabled]='isPatientResultTrendsEnabled'>
          <button mat-menu-item (click)="generateReport(getReportLink(ReportKeys.PatientResultTrends))"
            disabled="{{!isPatientResultTrendsEnabled}}">
            <span matBadge="i" matBadgeOverlap="false" matBadgeSize="small"
              [matBadgeHidden]='isPatientResultTrendsEnabled'
              class="report-menu-item">{{'ReportMenu.PatientResultTrends' | translate}}</span>
          </button>
        </div>
        <div matTooltip="{{'ReportMenu.RequirePatientAssay' | translate}}"
          [matTooltipDisabled]='isPatientsCoInfectionsEnabled'>
          <button mat-menu-item (click)="generateReport(getReportLink(ReportKeys.PatientCoInfections))"
            disabled="{{!isPatientsCoInfectionsEnabled}}">
            <span matBadge="i" matBadgeOverlap="false" matBadgeSize="small"
              [matBadgeHidden]='isPatientsCoInfectionsEnabled'
              class="report-menu-item">{{'ReportMenu.PatientsCoInfections' | translate}}</span>
          </button>
        </div>
        <div matTooltip="{{'ReportMenu.RequirePatient' | translate}}"
          [matTooltipDisabled]='isPercentPositiveResultEnabled'>
          <button mat-menu-item (click)="generateReport(getReportLink(ReportKeys.PercentPositiveResults))"
            disabled="{{!isPercentPositiveResultEnabled}}">
            <span matBadge="i" matBadgeOverlap="false" matBadgeSize="small"
              [matBadgeHidden]='isPercentPositiveResultEnabled'
              class="report-menu-item">{{'ReportMenu.PercentPositiveResults' | translate}}</span>
          </button>
        </div>
        <div matTooltip="{{'ReportMenu.RequireQCOrg' | translate}}"
          [matTooltipDisabled]='isQualityControlReportEnabled'  *ngIf="isQualityControlReportVisible" >
          <button mat-menu-item (click)="generateReport(getReportLink(ReportKeys.QualityControlReport))"
            disabled="{{!isQualityControlReportEnabled}}">
            <span matBadge="i" matBadgeOverlap="false" matBadgeSize="small"
              [matBadgeHidden]='isQualityControlReportEnabled'
              class="report-menu-item">{{'ReportMenu.QualityControlReport' | translate}}</span>
          </button>
        </div>
        <button mat-menu-item (click)="generateReport(getReportLink(ReportKeys.TestVolumeByType))"
          disabled="{{!isTestVolumeByTypeReportEnabled}}">
          <span matBadge="i" matBadgeOverlap="false" matBadgeSize="small"
            [matBadgeHidden]='isTestVolumeByTypeReportEnabled'
            class="report-menu-item">{{'ReportMenu.TestVolumeByType' | translate}}</span>
        </button>
      </mat-menu>

      <mat-icon class="export-pdf" color="{{ exportButtonsState }}" [ngStyle]="{'cursor': exportButtonsState }"
        (click)="exportFileDialog(ExportFileType.PDF)">assignment</mat-icon>
      <mat-icon color="{{ exportButtonsState }}" [ngStyle]="{'cursor': exportButtonsState }"
        matTooltip="{{'NavBar.ExportCSVTooltip' | translate}}"
        (click)="exportFileDialog(ExportFileType.CSV)">grid_on</mat-icon>
    </div>
  </div>
</div>
