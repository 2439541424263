export class TranslateNotificationKeys {
  public static readonly Prefix = 'Notifications';
  public static readonly SearchFailure = 'SearchFailure';
  public static readonly LoadFailure = 'LoadFailure';
  public static readonly SaveFailure = 'SaveFailure';
  public static readonly SaveFailureAlreadyInUse = 'SaveFailureAlreadyInUse';
  public static readonly SaveSuccess = 'SaveSuccess';
  public static readonly DeleteFailure = 'DeleteFailure';
  public static readonly DeleteSuccess = 'DeleteSuccess';
  public static readonly RejectFailure = 'RejectFailure';
  public static readonly RejectSuccess = 'RejectSuccess';
  public static readonly ApproveFailure = 'ApproveFailure';
  public static readonly ApproveSuccess = 'ApproveSuccess';
  public static readonly ExportFailure = 'ExportFailure';
  public static readonly ExportSuccess = 'ExportSuccess';
  public static readonly MapLimitOneAssayTitle = 'MapLimitOneAssayTitle';
  public static readonly MapLimitOneAssayDescription = 'MapLimitOneAssayDescription';
  public static readonly UpdateInstrumentTestResultsSucceeded = 'UpdateInstrumentTestResultsSucceeded';
  public static readonly UpdateInstrumentTestResultsFailed = 'UpdateInstrumentTestResultsFailed';
  public static readonly SearchCriteriaTooBroadTitle = 'SearchCriteriaTooBroadTitle';
  public static readonly SearchCriteriaTooBroadWithGroupingMessage = 'SearchCriteriaTooBroadWithGroupingMessage';
  public static readonly SearchCriteriaTooBroadMessage = 'SearchCriteriaTooBroadMessage';
  public static readonly UserEditSuccessMessage = 'UserEditSuccessMessage';
  public static readonly MacAddressInUse = 'MacAddressInUse';
  public static readonly TestResultDetailNoAccessMessage = 'TestResultDetailNoAccessMessage';
  public static readonly InstrumentDetailNoAccessMessage = 'InstrumentDetailNoAccessMessage';
  public static readonly FailedToDownloadOrphanDetailsMessage = 'FailedToDownloadOrphanDetailsMessage';
}
