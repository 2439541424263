import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, NEVER } from 'rxjs';
import { InactivityService } from '../services/inactivity.service';

@Injectable()
export class ActivityInterceptor implements HttpInterceptor {

  constructor(private inactivityService: InactivityService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const inactive = this.inactivityService.checkForInactivityTimeout();
    if (inactive) {
      return NEVER;
    }

    this.inactivityService.activityNow();

    return next.handle(req);
  }
}
