import { Injectable } from '@angular/core';
import { ConfigurationService, RoleAccessService } from '..';

declare let gtag: (...args: any[]) => void;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {
  constructor(
    private configurationService: ConfigurationService,
    private roleAccessService: RoleAccessService) {
    this.init();
  }

  public init(): void {
    try {
      const script1 = document.createElement('script');
      script1.async = true;
      script1.src = 'https://www.googletagmanager.com/gtag/js?id=' + this.configurationService.getConfiguration().GoogleAnalyticsTrackingID;
      document.head.appendChild(script1);
      const script2 = document.createElement('script');

      script2.innerHTML = 'window.dataLayer = window.dataLayer || [];  \
      function gtag(){dataLayer.push(arguments);}    \
      gtag(\'js\', new Date());  \
      gtag(\'config\', \'' + this.configurationService.getConfiguration().GoogleAnalyticsTrackingID + '\'); ';
      document.head.appendChild(script2);
    } catch (ex) {
      console.error('Error appending google analytics');
      console.error(ex);
    }
  }

  public log(eventName: string, eventCategory: string, eventAction: string): void {
    const event_label = {};

    event_label['isQuidelEmployee'] = this.roleAccessService.isQuidelEmployee();

    gtag('event', eventName, {
      'event_category': eventCategory,
      'event_label': JSON.stringify(event_label),
      'event_action': eventAction
    });
  }

  public logWithLabels(
    eventName: string,
    eventCategory: string,
    eventAction: string,
    label1Name: string = null,
    label1: any = null,
    label2Name: string = null,
    label2: any = null): void {

    const event_label = {};
    if (label1 != null) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      event_label[label1Name] = label1;
    }

    if (label2 != null) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      event_label[label2Name] = label2;
    }

    event_label['isQuidelEmployee'] = this.roleAccessService.isQuidelEmployee();

    const str_event_label = JSON.stringify(event_label, (key, value) => {
      if (value !== null) {
        if (Array.isArray(value)) {
          if (value.length > 0) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return value;
          }
        }
        else {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          return value;
        }
      }
    });
    
    console.log(str_event_label);
    gtag('event', eventName, {
      'event_category': eventCategory,
      'event_label': str_event_label,
      'event_action': eventAction
    });
  }

  public getDateGroupType(groupTypeID: number): string {
    if (groupTypeID == 1) {
      return 'Days';
    }
    else if (groupTypeID == 2) {
      return 'Weeks';
    }
    else if (groupTypeID == 3) {
      return 'Months';
    }
    else if (groupTypeID == 4) {
      return 'Years';
    }
    else {
      return groupTypeID.toString();
    }
  }

  public getResultGroupType(groupTypeID: number): string {
    if (groupTypeID == 0) {
      return 'Assay';
    }
    else if (groupTypeID == 1) {
      return 'Analyte';
    }
    else {
      return groupTypeID ? groupTypeID.toString() : 'Empty';
    }
  }
}