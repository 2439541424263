import { UIQueryFilterBase } from './FilterElement';
import { TranslateBaseComponent } from '../../translate/translate-base.component';
import { FilterService } from '../../services/filter.service';
import { TranslateService } from '@ngx-translate/core';

export abstract class UIFilterBase<T extends UIQueryFilterBase> extends TranslateBaseComponent {
  public isOpened = false;
  public query: T;

  protected title;

  constructor(protected filterService: FilterService, translateService: TranslateService, componentTranslationKey: string) {
    super(translateService, componentTranslationKey);
  }

  onTranslateResourcesLoaded(): void {
    this.setTitle();
  }

  onTranslateLanguageChanged(): void {
    this.setTitle();
  }

  private setTitle() {
    this.title = this.getText('Title');
  }

  protected queryReset(): void {
    // Child classes can choose to override this if the default reset logic doesn't work.
  }

  public toggle(): void {
    this.isOpened = !this.isOpened;
  }

  public initializeQuery(query: T): void {
    if (this.query) {
      throw new Error('Query already initialized.');
    }

    this.query = query;
    this.subscription.add(this.query.resetOccurred$.subscribe(() => {
      this.queryReset();
    }));
  }

  public hasAtLeastOneFilterSelected(): boolean {
    if (!this.query) {
      return false;
    }

    return this.query.hasAtLeastOneFilterSelected();
  }
}
