import { Component, OnInit, OnDestroy } from '@angular/core';
import { FilterElement } from '../FilterElement';
import { UIFilterBase } from '../UIFilterBase';
import { FilterService } from '../../../services/filter.service';
import { TranslateService } from '@ngx-translate/core';
import { OrgsFacilitiesUIQuery, OrgItem, FacItem } from './filter-custom-orgs-facilities.model';

@Component({
  selector: 'core-filter-custom-orgs-facilities',
  templateUrl: './filter-custom-orgs-facilities.component.html',
  styleUrls: ['./filter-custom-orgs-facilities.component.scss']
})
export class FilterCustomOrgsFacilitiesComponent extends UIFilterBase<OrgsFacilitiesUIQuery> implements OnInit, OnDestroy {

  private static FilterCustomOrgsFacilitiesKey = 'FilterCustomOrgsFacilities';
  public color: string = getComputedStyle(document.body).getPropertyValue('--theme-background-color');
  public filterSelectedOnly = false;

  availableOrganizations: FilterElement<OrgItem>[];
  availableFacilities: Map<string, FilterElement<FacItem>[]>;

  selectedOrganizations: FilterElement<OrgItem>[];
  orgSearchText: string;

  constructor(filterService: FilterService, translateService: TranslateService) {
    super(filterService, translateService, FilterCustomOrgsFacilitiesComponent.FilterCustomOrgsFacilitiesKey);
  }

  ngOnInit(): void {
    this.initialize();
  }

  ngOnDestroyInternal(): void {
    // Required by base component
  }

  protected initialize(): void {
    const query = this.filterService.getOrgsFacilitiesQuery();

    this.initializeQuery(query);

    this.subscription.add(this.query.rootItemsLoaded$.subscribe(orgs => {
      this.setOrganizations();
    }));
    this.setOrganizations();

    void this.updateHeaders();
  }

  protected queryReset(): void {
    void this.updateHeaders();
  }

  selectOrganization(organization: FilterElement<OrgItem>): void {
    organization.toggleSelected();
    if (!organization.isSelected) {
      this.deselectFacilities(organization);
    }

    this.updateCachedFacilitiesVisibility(organization);

    void this.loadFacilities();
  }

  private async loadFacilities() {
    const selectedOrganizations = this.query.getSelectedOrganizations();
    if (selectedOrganizations && selectedOrganizations.length > 0) {
      await this.updateFacilitiesVisibility(selectedOrganizations);
    }
    else {
      this.filterSelectedOnly = false;
      this.filterOrgs();
    }
    await this.refreshAfterSelection();
  }

  selectFacility(facility: FilterElement<FacItem>): void {
    facility.toggleSelected();

    this.filterService.updateOrgsFacilities(this.query);
  }

  async updateFacilitiesVisibility(organizations: FilterElement<OrgItem>[]): Promise<void> {
    const organizationsFacilities = await this.query.getFacilities(organizations);
    if (organizationsFacilities) {
      organizationsFacilities.forEach(f => {
        f.isHidden = !f.isSelected;
      });
    }
  }

  updateCachedFacilitiesVisibility(organization: FilterElement<OrgItem>): void {
    const organizationFacilities = this.query.getCachedFacilities(organization);
    if (organizationFacilities) {
      organizationFacilities.forEach(f => {
        f.isHidden = !organization.isSelected;
      });
    }
  }

  private deselectFacilities(organization: FilterElement<OrgItem>) {
    const organizationFacilities = this.query.getCachedFacilities(organization);
    if (organizationFacilities) {
      organizationFacilities.forEach(f => {
        f.isSelected = false;
      });
    }
  }

  private deselectOrganizations() {
    const organizations = this.query.getSelectedOrganizations();
    if (organizations && organizations.length > 0) {
      organizations.forEach(s => {
        s.isSelected = false;
        this.deselectFacilities(s);
      });
    }
  }

  private async refreshAfterSelection() {
    this.filterService.updateOrgsFacilities(this.query);
    await this.updateHeaders();
  }

  private async updateHeaders() {
    this.selectedOrganizations = this.query.getSelectedOrganizations();
    if (this.selectedOrganizations && this.selectedOrganizations.length > 0) {
      const map = new Map<string, FilterElement<FacItem>[]>();

      const promises: Promise<{ organization: string, facilities: FilterElement<FacItem>[] }>[] = [];

      this.selectedOrganizations.sort((s1, s2) => s1.getLabel().localeCompare(s2.getLabel())).forEach(s => {
        // eslint-disable-next-line no-async-promise-executor
        promises.push(new Promise<{ organization: string, facilities: FilterElement<FacItem>[] }>(async (res) => {
          const facilities = await this.query.getFacilities([s]);
          res({ organization: s.getLabel(), facilities });
        }));
      });

      const results = await Promise.all(promises);

      results.forEach(r => {
        map.set(r.organization, r.facilities);
      });

      this.selectedOrganizations.forEach(s => this.updateCachedFacilitiesVisibility(s));
      this.availableFacilities = map;
    } else {
      this.availableFacilities = new Map<string, FilterElement<FacItem>[]>();
    }
  }

  clearOrganizationFilter(): void {
    this.filterSelectedOnly = false;
    this.filterOrgs();
    this.deselectOrganizations();
    this.filterService.updateOrgsFacilities(this.query);
    void this.updateHeaders();
  }

  async clearFacilityFilter(): Promise<void> {
    const organizations = this.query.getSelectedOrganizations();

    if (organizations && organizations.length > 0) {
      const promises: Promise<void>[] = [];
      organizations.forEach(s => {
        promises.push(new Promise(res => {
          this.deselectFacilities(s);
          res();
        }));
      });

      await Promise.all(promises);
      this.filterService.updateOrgsFacilities(this.query);
    }
  }

  private setOrganizations() {
    if (!this.query.rootItems) {
      return;
    }

    this.availableOrganizations = this.query.rootItems;
  }

  onOrgSearchChanged(searchText: string): void {
    this.orgSearchText = searchText;

    this.filterOrgs();
  }

  filterOrgs(): void {
    if (!this.query.rootItems) {
      return;
    }

    if (!this.orgSearchText || this.orgSearchText === '') {
      this.availableOrganizations = this.query.rootItems;
    } else {
      this.availableOrganizations = this.query.rootItems.filter(o => o.getLabel().toLowerCase().startsWith(this.orgSearchText.toLowerCase()));
    }

    if (this.filterSelectedOnly) {
      this.availableOrganizations = this.availableOrganizations.filter(o => o.isSelected);
    }
  }

  toggleFilterSelectedOnly(): void {
    this.filterSelectedOnly = !this.filterSelectedOnly;

    this.filterOrgs();
  }
}
