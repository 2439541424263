import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { TranslateModule } from '@ngx-translate/core';
import {
  QuidelCoreModule, NavigationGuard, NavigationKeys,
  AssaysNavigationKeys, FacilitiesNavigationKeys, UsersNavigationKeys, OrganizationsNavigationKeys,
  InstrumentsNavigationKeys, ReportNavigationKeys, PairedDevicesNavigationKeys, OrphansNavigationKeys,
  ResultsApiNavigationKeys
} from './core';

import { MsalRedirectComponent } from '@azure/msal-angular';

import { SummaryComponent } from './summary/summary.component';
import { TestResultsComponent } from './test-results/test-results.component';
import { MappingComponent } from './mapping/mapping.component';
import { TestResultsDetailsDialogComponent } from './test-results/details/test-results-details-dialog.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { ExportFileModalComponent } from './modals/export-file/export-file-modal/export-file-modal.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ForbiddenComponent } from './forbidden/forbidden.component';

@NgModule({
  declarations: [
    TestResultsComponent,
    TestResultsDetailsDialogComponent,
    MappingComponent,
    SummaryComponent,
    NavBarComponent,
    ExportFileModalComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    QuidelCoreModule,
    DragDropModule,
    ScrollingModule,
    RouterModule.forRoot(
      [
        /*
         * Needed for login on page load for PathLocationStrategy.
         * See FAQ for details:
         * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/FAQ.md#pathlocationstrategy
        */
        {
          path: NavigationKeys.Auth, component: MsalRedirectComponent
        },
        {
          // If the redirectTo is changed to point somewhere else, please be sure to update the catch-all route as well
          path: NavigationKeys.Default, redirectTo: NavigationKeys.Summary, pathMatch: 'full',
        },
        {
          path: NavigationKeys.Summary, component: SummaryComponent,
          canActivate: [NavigationGuard]
        },
        {
          path: NavigationKeys.Results, component: TestResultsComponent,
          canActivate: [NavigationGuard]
        },
        {
          path: NavigationKeys.Mapping, component: MappingComponent,
          canActivate: [NavigationGuard]
        },
        {
          path: AssaysNavigationKeys.Module, loadChildren: () => import('./admin/assays/assays.module').then(m => m.AssaysModule),
          canActivate: [NavigationGuard]
        },
        {
          path: FacilitiesNavigationKeys.Module, loadChildren: () => import('./admin/facility/facility.module').then(m => m.FacilityModule),
          canActivate: [NavigationGuard]
        },
        {
          path: UsersNavigationKeys.Module, loadChildren: () => import('./admin/user/user.module').then(m => m.UserModule),
          canActivate: [NavigationGuard]
        },
        {
          path: OrganizationsNavigationKeys.Module, loadChildren: () => import('./admin/organization/organization.module').then(m => m.OrganizationModule),
          canActivate: [NavigationGuard]
        },
        {
          path: InstrumentsNavigationKeys.Module, loadChildren: () => import('./admin/instruments/instruments.module').then(m => m.InstrumentsModule),
          canActivate: [NavigationGuard]
        },
        {
          path: PairedDevicesNavigationKeys.Module, loadChildren: () => import('./admin/paired-devices/paired-devices.module').then(m => m.PairedDevicesModule),
          canActivate: [NavigationGuard]
        },
        {
          path: OrphansNavigationKeys.Module, loadChildren: () => import('./admin/orphans/orphans.module').then(m => m.OrphansModule),
          canActivate: [NavigationGuard]
        },
        {
          path: ReportNavigationKeys.Module, loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
          canActivate: [NavigationGuard]
        },
        {
          path: ResultsApiNavigationKeys.Module, loadChildren: () => import('./admin/results-api/results-api.module').then(m => m.ResultsApiModule),
          canActivate: [NavigationGuard]
        },
        {
          path: NavigationKeys.Forbidden, component: ForbiddenComponent       
        },
        {
          path: '**', redirectTo: NavigationKeys.Summary,
        }
      ])
  ],
  exports: [
    RouterModule,
    TranslateModule,
    SummaryComponent,
    TestResultsComponent,
    TestResultsDetailsDialogComponent,
    MappingComponent,
    NavBarComponent
  ]
})
export class AppRoutingModule { }
