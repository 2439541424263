import { Component, OnInit, OnDestroy } from '@angular/core';
import { FavoritesUIQuery, FavoriteItem } from './filter-custom-favorites.model';
import { FilterService } from '../../../services/filter.service';
import { TranslateService } from '@ngx-translate/core';
import { UIFilterBase } from '../UIFilterBase';
import { FilterElement } from '../FilterElement';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AddEditModalComponent } from './modals/add-edit-modal/add-edit-modal/add-edit-modal.component';
import { FavoriteModalTypes, FavoriteModalData } from './modals/add-edit-modal/add-edit-modal/add-edit-modal.component.model';
import { ConfirmationModalComponent, ConfirmationModalInput } from '../../confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'core-filter-custom-favorites',
  templateUrl: './filter-custom-favorites.component.html',
  styleUrls: ['./filter-custom-favorites.component.scss']
})
export class FilterCustomFavoritesComponent extends UIFilterBase<FavoritesUIQuery> implements OnInit, OnDestroy {

  private static FilterCustomFavoritesKey = 'FilterCustomFavorites';

  private confirmationDialogRef: MatDialogRef<ConfirmationModalComponent, boolean>;
  private addEditDialogRef: MatDialogRef<AddEditModalComponent, boolean>;

  selectedFavorite: FilterElement<FavoriteItem>;

  constructor(
    public filterService: FilterService,
    translateService: TranslateService,
    private dialog: MatDialog
  ) {
    super(filterService, translateService, FilterCustomFavoritesComponent.FilterCustomFavoritesKey);
  }

  ngOnInit(): void {
    this.initialize();

    this.refreshAfterSelection();
  }

  ngOnDestroyInternal(): void {
    // Required by base component
  }

  private initialize() {
    const query = this.filterService.getFavoritesQuery();
    this.initializeQuery(query);
  }

  async selectItem(fav: FilterElement<FavoriteItem>): Promise<boolean> {

    if (this.query.isDirty()) {
      const confirmed = await this.showConfirmationDialog({ titleResourceKey: 'LosingChangesAtDirtyFilter' });
      if (!confirmed) {
        return false;
      }
    }

    const previousFavorite = this.query.getSelectedFavorite();
    if (previousFavorite) {
      if (previousFavorite.item.getId() === fav.item.getId()) {
        return true;
      }

      previousFavorite.toggleSelected();
    }

    if (!fav.isSelected) {
      console.debug('selected');
      this.filterService.deserializeStateFrom(fav.item);
    } else {
      console.debug('deselected');
    }
    console.debug(fav);


    fav.toggleSelected();

    this.refreshAfterSelection();

    return true;
  }

  private refreshAfterSelection() {
    this.filterService.updateFavoritesQuery(this.query);
    this.selectedFavorite = this.query.getSelectedFavorite();
  }

  async addFavorite(): Promise<void> {
    const modalData: FavoriteModalData = { mode: FavoriteModalTypes.Add };
    await this.showAddEditDialog(modalData);
  }

  async editFavorite(fav: FilterElement<FavoriteItem>): Promise<void> {

    const favoriteSelected = await this.selectItem(fav);
    if (!favoriteSelected) {
      return;
    }

    let modalType = FavoriteModalTypes.Edit;
    if (fav.item.getIsReadOnly()) {
      modalType = FavoriteModalTypes.Readonly;
    }

    const schedule = await this.query.loadFavoriteSchedule(fav.item.getId());
    const modalData: FavoriteModalData = { mode: modalType, favorite: fav.item, schedule };
    const isUpdated = await this.showAddEditDialog(modalData);
    if (isUpdated) {
      await this.updateFavorite(fav);
    }
  }

  async saveDirtyFavorite(fav: FilterElement<FavoriteItem>): Promise<void> {
    const confirmed = await this.showConfirmationDialog({ titleResourceKey: 'ConfirmFavoriteDirtySave', parameter: fav.getLabel() });
    if (confirmed) {
      await this.updateFavorite(fav);
    }

  }

  async deleteFavorite(fav: FilterElement<FavoriteItem>): Promise<void> {
    const confirmed = await this.showConfirmationDialog({ titleResourceKey: 'ConfirmFavoriteDeletion', parameter: fav.getLabel() });
    if (confirmed) {
      await this.query.deleteFavorite(fav.item);
    }
  }

  private async showAddEditDialog(modalData: FavoriteModalData): Promise<boolean> {

    const modalWidth = 0.6 * window.outerWidth;
    this.addEditDialogRef = this.dialog.open(AddEditModalComponent, {
      width: `${modalWidth}px`,
      data: modalData
    });

    const result = await this.addEditDialogRef.afterClosed().toPromise();
    return result;
  }

  private async updateFavorite(fav: FilterElement<FavoriteItem>) {
    const shouldForceSelection = await this.query.updateFavorite(fav.item);
    if (shouldForceSelection) {
      fav.isSelected = true;
    }
  }

  private showConfirmationDialog(inputData: ConfirmationModalInput): Promise<boolean> {

    const modalWidth = 0.16 * window.outerWidth;
    this.confirmationDialogRef = this.dialog.open(ConfirmationModalComponent, {
      width: `${modalWidth}px`,
      data: inputData
    });

    const result = this.confirmationDialogRef.afterClosed().toPromise();
    return result;
  }

  async clearFilter(): Promise<void> {
    if (this.selectedFavorite && this.selectedFavorite.isSelected) {

      let confirmed = true;
      if (this.query.isDirty()) {
        confirmed = await this.showConfirmationDialog({ titleResourceKey: 'ConfirmFavoriteDirtyReset', parameter: this.selectedFavorite.getLabel() });
      }

      if (confirmed) {
        void this.filterService.resetFilters(true);
        this.query = this.filterService.getFavoritesQuery();
        this.refreshAfterSelection();
      }
    }
  }

}
