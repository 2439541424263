import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'truncateNumber' })
export class TruncateNumberPipe implements PipeTransform {
  transform(originalValue: string): string {
    try {
      // Check to make sure we haven't already trunacted this text
      if (String(originalValue[originalValue.length - 1]).match(/\d/g)) {
        const value = Number.parseInt(originalValue.replace(',', ''), 10);

        if (!Number.isNaN(value)) {
          let truncated = originalValue;
          let truncatedValue: number;
          let truncatedSuffix: string;

          const length = String(value).match(/\d/g).length;

          if (length >= 10) {
            truncatedValue = (value / 1000000000);
            truncatedSuffix = 'B';
          } else if (length >= 7) {
            truncatedValue = (value / 1000000);
            truncatedSuffix = 'M';
          } else if (length >= 6) { // We purposely leave numbers < 100k alone
            truncatedValue = (value / 1000);
            truncatedSuffix = 'K';
          }

          if (truncatedValue) {
            truncated = Number((/^-?\d+(?:\.\d{0,1})?/.exec(truncatedValue.toString()))[0]).toLocaleString() + truncatedSuffix;
          }

          if (truncated !== originalValue) {
            return truncated;
          }
        }
      }

      return originalValue;
    } catch (e) {
      console.error(e);
    }
  }
}
