export enum UserGroup {
    SystemAdmin = 'System Admin',
    TechSupportAdmin = 'Technical Support Admin',
    SalesAdmin = 'Sales Admin',
    OrgAdmin = 'Organizational Administrator',
    Org = 'Organization',
    State = 'State',
    Unregistered = 'Unregistered',
    Country = 'Country',
    County = 'County',
    Global = 'Global',
    NetworkOrganizationAdmin = 'Network Organization Admin'
}
