import { UIQuerySimpleFilter, IGetLabel, FilterElement, IGetId, IEquatable } from '../FilterElement';
import { OnDestroy, Injectable } from '@angular/core';
import moment_from from 'moment';
import { UIQueryDates } from '../../../services/query.service.model';
import { FilterStateDates, FilterStateDatesLast, FilterStateDatesFixed } from '../../../services/metadata.service.models';
import { ConfigurationService } from '../../../services/configuration.service';

const moment = moment_from; // https://github.com/jvandemo/generator-angular2-library/issues/221

export enum TimeRangeTypes {
  Day,
  Week,
  Month,
  Year
}

export const DateUIQueryKey = 'DateUIQuery';

export const TimeRangeDescriptions = new Map<number, string>([
  [TimeRangeTypes.Day, `${DateUIQueryKey}.Days`],
  [TimeRangeTypes.Week, `${DateUIQueryKey}.Weeks`],
  [TimeRangeTypes.Month, `${DateUIQueryKey}.Months`],
  [TimeRangeTypes.Year, `${DateUIQueryKey}.Years`]
]);

export abstract class DatesFilterItemBase implements IGetLabel, IGetId, IEquatable<DatesFilterItemBase>  {

  abstract getId(): number;
  abstract getLabel(): string;

  abstract getDates(): UIQueryDates;

  abstract getDescription(): string;

  abstract getDescriptionTranslate(): string;

  isSameDate(date1: Date, date2: Date): boolean {
    const mDate1 = moment(date1);
    const mDate2 = moment(date2);
    if (mDate1.isSame(mDate2, 'day')) {
      return true;
    }

    return false;
  }

  equals(other: DatesFilterItemBase): boolean {
    const otherDates = other.getDates();
    const currentDates = this.getDates();

    return this.isSameDate(currentDates.startDate, otherDates.startDate) &&
      this.isSameDate(currentDates.endDate, otherDates.endDate);
  }
}

export class LastDaysFilterItem extends DatesFilterItemBase {

  units?: number;
  timeRange: TimeRangeTypes;

  public label: string;

  constructor(label: string, defaultDays: number) {
    super();
    this.label = label;

    this.setValue(defaultDays, TimeRangeTypes.Day);
  }

  private _timeRangeDescription: string;
  get timeRangeDescription(): string {
    return this._timeRangeDescription;
  }
  set timeRangeDescription(value: string) {
    this._timeRangeDescription = value;
    const description = value as unknown as string;
    const enumIndex = this.getTimeRangeType(description);
    this.timeRange = TimeRangeTypes[enumIndex as unknown as string] as TimeRangeTypes;
    this._timeRangeDescription = value;
  }

  setValue(units: number, timeRange: TimeRangeTypes): void {
    this.timeRange = timeRange;
    this.units = units;
    this.timeRangeDescription = TimeRangeDescriptions.get(this.timeRange);
  }

  getLabel(): string {
    return this.label;
  }

  getId(): number {
    return 2;
  }

  getDates(): UIQueryDates {
    let startDate: moment_from.Moment;

    // https://momentjs.com/docs/#/manipulating/start-of/
    const today = moment().utc().startOf('day');
    // https://momentjs.com/docs/#/manipulating/end-of/
    const endDate = moment(today).utc().endOf('day').toDate();

    if (this.timeRange.toString() === TimeRangeTypes[TimeRangeTypes.Day]) {
      startDate = today.add(-this.units, 'days');
    }

    if (this.timeRange.toString() === TimeRangeTypes[TimeRangeTypes.Week]) {
      startDate = today.add(-this.units, 'weeks');
    }

    if (this.timeRange.toString() === TimeRangeTypes[TimeRangeTypes.Month]) {
      startDate = today.add(-this.units, 'months');
    }

    if (this.timeRange.toString() === TimeRangeTypes[TimeRangeTypes.Year]) {
      startDate = today.add(-this.units, 'years');
    }

    // https://momentjs.com/docs/#/manipulating/utc/
    return {
      startDate: startDate.utc().toDate(),
      endDate
    };
  }

  getDescription(): string {
    return `Past ${this.units}`;
  }

  getDescriptionTranslate(): string {
    return `${this.timeRangeDescription}`;
  }

  private getTimeRangeType(description: string): number {
    for (let i = 0; i < TimeRangeDescriptions.size; i++) {
      const currentDescription = TimeRangeDescriptions.get(i);
      if (currentDescription === description) {
        return i;
      }
    }
  }
}

export class FixedDatesFilterItem extends DatesFilterItemBase {

  public dateFrom?: moment_from.Moment;
  public dateTo?: moment_from.Moment;
  public label: string;

  constructor(label: string, defaultDays: number) {
    super();
    this.label = label;

    this.dateTo = moment().utc().endOf('day');
    this.dateFrom = moment().utc().add(-defaultDays, 'days').startOf('day');
  }

  setValue(dateFromUtc: moment_from.Moment, dateToUtc: moment_from.Moment): void {

    this.dateFrom = dateFromUtc.startOf('day');
    this.dateTo = dateToUtc.endOf('day');
  }

  getLabel(): string {
    return this.label;
  }

  getId(): number {
    return 1;
  }

  getDates(): UIQueryDates {
    const startDate: Date = this.dateFrom.toDate();
    const endDate: Date = this.dateTo.toDate();

    return { startDate, endDate };
  }

  getDescription(): string {
    return `${this.getDateRangeDescription()} (${this.getFormattedStartDate()} - ${this.getFormattedEndDate()})`;
  }

  getDateRangeDescription(): string {
    const diffDays = this.dateTo.diff(this.dateFrom, 'days');
    const selectedDays = diffDays + 1; // Because start and end dates are inclusive it's always 1 more day selected
    return `Selected ${selectedDays} Days`;
  }

  getFormattedStartDate(): string {
    return this.dateFrom.format('MMMM Do, YYYY');
  }

  getFormattedEndDate(): string {
    return this.dateTo.format('MMMM Do, YYYY');
  }

  getDescriptionTranslate(): string {
    return '';
  }
}

@Injectable({
  providedIn: 'root'
})
export class DateUIQuery extends UIQuerySimpleFilter<DatesFilterItemBase> implements OnDestroy {

  public timeRangeMap: Map<number, string>;

  configuration: ConfigurationService;
  defaultSearchDays = 30;

  constructor(configurationService: ConfigurationService) {
    super();

    this.configuration = configurationService;

    if (this.configuration.getConfiguration().settings) {
      this.defaultSearchDays = +this.configuration.getConfiguration().settings.get('defaultSearchRangeDays');
    }

    this.initialize();
  }

  protected initialize(): void {
    this.initializeItems();
  }

  private initializeItems() {
    this.items = [
      FilterElement.Build(new FixedDatesFilterItem(`${DateUIQueryKey}.RunDate`, this.defaultSearchDays)),
      FilterElement.Build(new LastDaysFilterItem(`${DateUIQueryKey}.FixResult`, this.defaultSearchDays)),
    ];

    this.timeRangeMap = TimeRangeDescriptions;
    this.getLastDays().isSelected = true;

    this.setDefaults();
  }

  protected setDefaults(selectedItems: FilterElement<DatesFilterItemBase>[] = null): void {
    if (this.configuration.getConfiguration().settings) {
      this.defaultSearchDays = +this.configuration.getConfiguration().settings.get('defaultSearchRangeDays');
    }

    this.setDefaultsItems(selectedItems);

    this.defaultItems.forEach(i => {
      if (i) {
        if (i.item instanceof FixedDatesFilterItem) {
          const dateFrom = i.item.dateFrom;
          const dateTo = i.item.dateTo;
          const dateItem = new FixedDatesFilterItem(`${DateUIQueryKey}.RunDate`, this.defaultSearchDays);
          dateItem.dateFrom = dateFrom;
          dateItem.dateTo = dateTo;
          i.item = dateItem;
        } else if (i.item instanceof LastDaysFilterItem) {
          const units = i.item.units;
          const timeRange = i.item.timeRange;
          const dateItem = new LastDaysFilterItem(`${DateUIQueryKey}.FixResult`, this.defaultSearchDays);
          dateItem.units = units;
          dateItem.timeRange = timeRange;
          i.item = dateItem;
        }
      }
    });
  }

  public reset(): void {
    this.initializeItems();
    this.resetOccurred.next();
  }

  public getFixedDates(): FilterElement<FixedDatesFilterItem> {
    return this.items[0] as FilterElement<FixedDatesFilterItem>;
  }

  public getLastDays(): FilterElement<LastDaysFilterItem> {
    return this.items[1] as FilterElement<LastDaysFilterItem>;
  }

  private isFixDatesSelected(): boolean {
    return this.getFixedDates().isSelected;
  }

  private isLastDaysSelected(): boolean {
    return this.getLastDays().isSelected;
  }

  private getTimeRange(timeRangeValue: string): TimeRangeTypes {
    if (timeRangeValue === TimeRangeTypes[TimeRangeTypes.Day]) {
      return TimeRangeTypes.Day;
    } else if (timeRangeValue === TimeRangeTypes[TimeRangeTypes.Month]) {
      return TimeRangeTypes.Month;
    } else if (timeRangeValue === TimeRangeTypes[TimeRangeTypes.Week]) {
      return TimeRangeTypes.Week;
    } else if (timeRangeValue === TimeRangeTypes[TimeRangeTypes.Year]) {
      return TimeRangeTypes.Year;
    }

    return undefined;
  }

  public getSelected(): FilterElement<DatesFilterItemBase> | undefined {
    if (this.isFixDatesSelected()) {
      return this.getFixedDates();
    }

    if (this.isLastDaysSelected()) {
      return this.getLastDays();
    }

    return undefined;
  }

  public setDefaultState(dates: FilterStateDates): void {
    if (!dates || (!dates.lastDays && !dates.fixed)) {
      const lastDaysItem = this.getLastDays();
      lastDaysItem.isSelected = true;
      const fixedItem = this.getFixedDates();
      fixedItem.isSelected = false;
    } else {
      const lastDaysItem = this.getLastDays();
      const fixedItem = this.getFixedDates();
      if (dates.lastDays) {
        const timeRange = this.getTimeRange(dates.lastDays.timeRange);
        lastDaysItem.item.setValue(dates.lastDays.units, timeRange);
        lastDaysItem.isSelected = true;
        fixedItem.isSelected = false;

      } else if (dates.fixed) {
        fixedItem.item.setValue(moment.utc(dates.fixed.dateFrom), moment.utc(dates.fixed.dateTo));
        fixedItem.isSelected = true;
        lastDaysItem.isSelected = false;
      }
    }

    this.setDefaults();
  }

  public serialize(): FilterStateDates {
    const result: FilterStateDates = {};
    if (this.isLastDaysSelected()) {
      const lastDays = this.getLastDays();
      const lastDaysSerialized: FilterStateDatesLast = {
        units: lastDays.item.units,
        timeRange: lastDays.item.timeRange.toString()
      };
      result.lastDays = lastDaysSerialized;
    } else if (this.isFixDatesSelected()) {
      const fixedDays = this.getFixedDates();
      const dates = fixedDays.item.getDates();
      const fixedDaysSerialized: FilterStateDatesFixed = {
        dateFrom: dates.startDate,
        dateTo: dates.endDate
      };
      result.fixed = fixedDaysSerialized;
    }

    return result;
  }
}
