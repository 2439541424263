import { Injectable } from '@angular/core';
import { RoleAccessService, UserGroup } from '../../core';

export enum TestResultDetailsFieldAccess {
    PatientId = 'PatientId',
    SignalToCutOff = 'SignalToCutOff',
    KitLotNumber = 'KitLotNumber',
    TestFlag = 'TestFlag',
    StorageDateAndTime = 'StorageDateAndTime',
    SerialNumber = 'SerialNumber',
    RegistrationDate = 'RegistrationDate',
    PairedDeviceType = 'PairedDeviceType',
    PairedDeviceId = 'PairedDeviceId',
    FirmwareRevision = 'FirmwareRevision',
    ContactName = 'ContactName',
    ContactEmail = 'ContactEmail',
    ContactPhoneNumber = 'ContactPhoneNumber',
    OrganizationDistributor = 'OrganizationDistributor',
    CellInformation = 'CellInformation',
    OrderNumber = 'OrderNumber'
}


@Injectable({
    providedIn: 'root'
})

export class TestResultDetailsAccessService {

    constructor(private roleAccessService: RoleAccessService) {}

    public hasAccess(fieldName: string): boolean {

        let accessGranted = false;
        switch (fieldName) {
            case TestResultDetailsFieldAccess.CellInformation:
                accessGranted = this.isSysAdmin();
                break;
            case TestResultDetailsFieldAccess.OrderNumber:
            case TestResultDetailsFieldAccess.PatientId:
                accessGranted = this.isSysAdminOrOrgAdminOrOrg();
              break;
            case TestResultDetailsFieldAccess.SignalToCutOff:
                accessGranted = this.isSysAdminOrGlobal();
            break;
            default:
                accessGranted =  this.isSysAdminOrGlobalOrOrgAdminOrOrg();
                break;
        }

        return accessGranted;
    }

    private isSysAdmin(): boolean {
        const role = this.roleAccessService.getRole();
        let result = false;
        if (role) {
            if (role === UserGroup.SystemAdmin) {
                result = true;
            }
        }

        return result;
    }
      private isSysAdminOrGlobalOrOrgAdminOrOrg(): boolean {
        const role = this.roleAccessService.getRole();
        let result = false;
        if (role) {
            if (role === UserGroup.SystemAdmin ||
                role === UserGroup.Global ||
                role === UserGroup.OrgAdmin ||
                role === UserGroup.Org
                ) {
                result = true;
            }
        }

        return result;
    }

    private isSysAdminOrGlobal(): boolean {
        const role = this.roleAccessService.getRole();
        let result = false;
        if (role) {
            if (role === UserGroup.SystemAdmin ||
                role === UserGroup.Global 
                ) {
                result = true;
            }
        }

        return result;
    }

    private isOrgAdminOrOrg(): boolean {
        const role = this.roleAccessService.getRole();
        let result = false;
        if (role) {
            if (role === UserGroup.OrgAdmin ||
                role === UserGroup.Org
                ) {
                result = true;
            }
        }
        return result;
    }

    private isSysAdminOrOrgAdminOrOrg(): boolean {
      const role = this.roleAccessService.getRole();
      let result = false;
      if (role) {
          if (role === UserGroup.SystemAdmin ||
              role === UserGroup.OrgAdmin ||
              role === UserGroup.Org
              ) {
              result = true;
          }
      }

      return result;
  }}
