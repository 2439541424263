import { Component } from '@angular/core';

@Component({
  selector: 'core-legend',
  templateUrl: './legend.component.html',
  styleUrls: ['./legend.component.scss'],
})
export class LegendComponent {

  isExpanded = true;

  //
  // Converts a percentage to one of the 5 pre-determined colors
  // Note that these colors were specifically chosen to best aide those with color blindness
  //
  public percentToColor(p: number): string {
    switch (true) {
      case (p >= 0 && p <= 5):
        // Blue
        return '88CCEE';
      case (p > 5 && p <= 10):
        // Green
        return '117733';
      case (p > 10 && p <= 15):
        // Purple
        return '332288';
      case (p > 15 && p < 20):
        // Brown
        return 'DDCC77';
      case (p >= 20 && p <= 100):
        // Red
        return 'CC6677';
      default:
        // Grey - indicates invalid percentage
        return 'A0A0A0';
    }
  }

  toggleExpand(): void {
    this.isExpanded = !this.isExpanded;
  }
}
