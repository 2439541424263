import { PagingInformation, SearchResultsPage, PageDirection } from './common.service.models';
import { TestResultsQuery } from './query.service.model';

export interface Marker {
  percentPositive: number;
  numberPositives: number;
  latitude: number;
  longitude: number;
  numberTests: number;
  county: string;
  zip: string;
  country: string;
  myFacilityResult: string;
}

export interface FacilityResult {
  latitude: number;
  longitude: number;
  facilityDescriptions: string[];
}

export interface FacilityMarker {
  facilityResults: FacilityResult[];
}

export interface MapSearchResult {
  markers: Marker[];
  facilityMarkers: FacilityMarker[];
  totalTests: number;
}

export interface TestResultSummary {
  testCompletionDateUtc: Date;
  storageDateUtc: Date;
  serialNumber: string;
  facilityId: number;
  facilityName: string;
  countryCode: string;
  stateCode: string;
  countyName: string;
  organizationId: number;
  organizationName: string;
  testName: string;
  sampleTypeCode: string;
  sampleTypeName: string;
  resultCode: string;
  analytes: AnalyteSummary[];
  id: string;
  _ts: number;
  operatorId: string;
}

export interface AnalyteSummary {
  analyteName: string;
  resultCode: string;
}

export interface TestResultSummariesResponse {
  pagingInformation: PagingInformation;
  totalResults: number;
  results: TestResultSummary[];
}

export interface TestResultGroup {
  key: string;
  name: string;
  value: any;
  count: number;
  continuationToken: string;
  expanded: boolean;
  innerGroups: TestResultGroup[];
}

export interface TestResultGroupsResponse {
  pagingInformation: PagingInformation;
  totalResults: number;
  results: TestResultGroup[];
}

export interface SearchSummaryTest {
  testName: string;
  totalTests: number;
  totalPositive: number;
  totalNegative: number;
  totalInvalid: number;
  instrumentsTransmitting: number;
  analytes: SearchSummaryTest[];
}

export interface SearchSummaryInstrument {
  isReporting: boolean;
  serialNumber: string;
  facilityId: number;
  facilityName: string;
  streetAddress1: string;
  stateCode: string;
  zipCode: string;
}

export interface SearchConnectedInstrumentsResultsPage extends SearchResultsPage<SearchSummaryInstrument> {
  totalReportingInstruments?: number;
}

export enum DateGroupType {
  Invalid = 0,
  Day = 1,
  Week = 2,
  Month = 3,
  Year = 4
}

export enum ResultGroupType {
  Assay = 0,
  Analyte = 1
}

export interface DateGroupTypeTestQuery {
  query: TestResultsQuery;
  dateGroupType: DateGroupType;
}

export interface ResultGroupTypeTestQuery {
  query: TestResultsQuery;
  resultGroupType: ResultGroupType;
}

export interface SearchQueryContainer {
  queryParameters: TestResultsQuery;
  pageNumber?: number;
  pageSize?: number;
  sortColumn?: string;
  sortDirection?: number;
  groupBy?: string[];
  multiSort?: string[];
  pageDirection?: PageDirection;
  continuationToken?: string;
}
