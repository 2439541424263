import { IGetLabel, UIQuerySimpleFilter, FilterElement, IGetId, IEquatable } from '../FilterElement';
import { Injectable, OnDestroy } from '@angular/core';
import { InitDataSelectionLists, SampleType } from '../../../services/metadata.service.models';
import { MetadataService } from '../../../services/metadata.service';

export class ResultTypesItem implements IGetLabel, IGetId, IEquatable<ResultTypesItem> {
  sampleType: SampleType;

  constructor(sampleType: SampleType) {
    this.sampleType = sampleType;
  }

  getLabel(): string {
    return this.sampleType.name;
  }

  getId(): number {
    return this.sampleType.sampleTypeId;
  }

  equals(other: ResultTypesItem): boolean {
    return this.sampleType.sampleTypeId === other.sampleType.sampleTypeId;
  }

  toString(): string {
    return this.getLabel();
  }

}

@Injectable({
  providedIn: 'root'
})
export class ResultTypesUIQuery extends UIQuerySimpleFilter<ResultTypesItem> implements OnDestroy {

  constructor(private metadataService: MetadataService) {
    super();

    this.initialize();
  }

  protected initialize(): void {
    this.subscription.add(this.metadataService.fetchInitDataSelectionLists().subscribe((d: InitDataSelectionLists) => {

      if (d.SampleTypes) {
        this.items = [];
        d.SampleTypes.forEach(t => {
          this.items.push(FilterElement.Build(new ResultTypesItem(t)));
        });

        // example for testing defaults
        // const firstItem = this.items[0];
        // const secondItem = this.items[1];
        // firstItem.isSelected = true;
        // secondItem.isSelected = true;
        // this.setDefaults([firstItem, secondItem]);

        this.setDefaults();
      }
    }));
  }

  protected setDefaults(selectedItems: FilterElement<ResultTypesItem>[] = null): void {
    this.setDefaultsItems(selectedItems);
  }

  public setDefaultState(resultsIds: number[]): void {
    if (!resultsIds) {
      this.items.forEach(i => i.isSelected = false);
    } else {
      for (const id of resultsIds) {
        const matchingItem = this.items.find(i => i.item.getId() === id);
        if (matchingItem) {
          matchingItem.isSelected = true;
        }
      }
    }

    this.setDefaults();
  }

  public serialize(): number[] {
    if (!this.items) {
      return undefined;
    }

    const selectedIds = this.items.filter(i => i.isSelected).map(e => e.item.getId());
    return selectedIds;
  }

}
