import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ConfigurationService } from './configuration.service';
import { TestResultsQuery } from './query.service.model';
import {
  TestResultSummariesResponse, SearchSummaryTest, ResultGroupTypeTestQuery,
  TestResultGroupsResponse, SearchConnectedInstrumentsResultsPage, MapSearchResult, DateGroupTypeTestQuery, SearchQueryContainer
} from './search.service.models';
import { Observable, BehaviorSubject } from 'rxjs';
import {
  TestResult_PatientsByRunDateRow, TestResult_PatientsByAssayRow,
  TestResult_PatientsByFacilityAssayRow, TestResult_PatientsByFacilityResultRow,
  TestResult_PatientsByResultRow,
  TestResult_TestVolumeByTypeRow,
  TestResult_PercentPositiveByRunDateRow,
  TestResult_PatientResultTrendRow,
  TestResult_QualityControlRow,
  TestResult_PatientsCoInfectionRow
} from './search.service.testresult.models';
import { PageDirection } from './common.service.models';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  private apiUrl: string;
  private instrumentResultsV1BaseUrl = 'api/v1.0/Search';
  private reportsBaseIrl = 'api/v1.0/reports';

  private getMapQueryUrl = this.instrumentResultsV1BaseUrl + '/mapquery';
  private getGroupsUrl = this.instrumentResultsV1BaseUrl + '/getSearchGroups';
  private getSearchUrl = this.instrumentResultsV1BaseUrl + '/searchTestResults';
  private getTestResultsCountUrl = this.instrumentResultsV1BaseUrl + '/getTestResultsCount';
  private getSearchSummariesUrl = this.instrumentResultsV1BaseUrl + '/searchTestSummaries';
  private getSearchConnectedInstrumentsUrl = this.instrumentResultsV1BaseUrl + '/searchConnectedInstruments';
  public searchResultsCompletedSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public searchResultsCompleted: Observable<number> = this.searchResultsCompletedSubject.asObservable();

  // Report Urls
  private getReportPatientByRunDateUrl = this.reportsBaseIrl + '/patientsByRunDate';
  private getReportPatientByAssayUrl = this.reportsBaseIrl + '/patientsByAssay';
  private getReportPatientByFacilityAssayUrl = this.reportsBaseIrl + '/patientsByFacilityAssay';
  private getReportPatientByFacilityResultUrl = this.reportsBaseIrl + '/patientsByFacilityResult';
  private getReportPatientByResultUrl = this.reportsBaseIrl + '/patientsByResult';
  private getReportTestVolumeByTypeUrl = this.reportsBaseIrl + '/testVolumeByType';
  private getReportPercentPositiveByRunDateUrl = this.reportsBaseIrl + '/percentPositiveByRunDate';
  private getReportPatientResultTrendsUrl = this.reportsBaseIrl + '/patientResultTrends';
  private getReportPatientResultTrendDetailsUrl = this.reportsBaseIrl + '/patientResultTrendDetails';
  private getReportQualityControlUrl = this.reportsBaseIrl + '/qualityControlByFacility';
  private getReportPatientCoInfectionsUrl = this.reportsBaseIrl + '/patientCoInfections';

  // Constructor
  constructor(private httpClient: HttpClient, configurationService: ConfigurationService) {

    const config = configurationService.getConfiguration();

    this.apiUrl = config.searchServiceEndpoint;
    if (!this.apiUrl.endsWith('/')) {
      this.apiUrl = `${this.apiUrl}/`;
    }
  }

  private getFullUrl(subPath: string) {
    return this.apiUrl + subPath;
  }

  // InstrumentResults

  public getMapQuery(search: TestResultsQuery): Observable<{ [key: string]: MapSearchResult }> {
    return this.httpClient.post<{ [key: string]: MapSearchResult }>(this.getFullUrl(this.getMapQueryUrl), search);
  }

  public getSearchGroups(
    search: TestResultsQuery,
    pageNumber: number,
    pageSize: number,
    sortColumn: string = null,
    multiSort: string[] = null,
    sortDirection: number = 0,
    groups: string[] = null,
    pageNavigation: PageDirection = PageDirection.FirstPage,
    continuationToken: string = null): Observable<TestResultGroupsResponse> {
    const searchQueryContainer: SearchQueryContainer = {
      queryParameters: search,
      pageNumber,
      pageSize,
      pageDirection: pageNavigation
    };

    if (continuationToken) {
      searchQueryContainer.continuationToken = continuationToken;
    }

    if (sortColumn) {
      searchQueryContainer.sortColumn = sortColumn;
    } else if (multiSort) {
      searchQueryContainer.multiSort = multiSort;
    }

    searchQueryContainer.sortDirection = sortDirection;

    if (groups) {
      searchQueryContainer.groupBy = groups;
    }

    return this.httpClient.post<TestResultGroupsResponse>(this.getFullUrl(this.getGroupsUrl), searchQueryContainer);
  }

  public search(search: TestResultsQuery, pageNumber: number, pageSize: number, sortColumn: string = null,
    multiSort: string[] = null, sortDirection: number = 0): Observable<TestResultSummariesResponse> {

    const searchQueryContainer: SearchQueryContainer = {
      queryParameters: search,
      pageNumber,
      pageSize,
    };

    if (sortColumn) {
      searchQueryContainer.sortColumn = sortColumn;
    } else if (multiSort) {
      searchQueryContainer.multiSort = multiSort;
    }

    searchQueryContainer.sortDirection = sortDirection;

    return this.httpClient.post<TestResultSummariesResponse>(this.getFullUrl(this.getSearchUrl), searchQueryContainer);
  }

  public getTestResultsCount(serialNumber: string): Observable<number> {

    return this.httpClient.post<number>(this.getFullUrl(this.getTestResultsCountUrl), null, { params: new HttpParams().set('serialNumber', serialNumber) });
  }

  public getSearchSummaries(search: TestResultsQuery): Observable<SearchSummaryTest[]> {
    return this.httpClient.post<SearchSummaryTest[]>(this.getFullUrl(this.getSearchSummariesUrl), search)
      .pipe(map((searchSummaries: SearchSummaryTest[]) => {
        if (searchSummaries) {
          searchSummaries.forEach((searchSummary: SearchSummaryTest) => {
            if (isNaN(searchSummary.instrumentsTransmitting)) {
              searchSummary.instrumentsTransmitting = 0;
            }

            if (isNaN(searchSummary.totalInvalid)) {
              searchSummary.totalInvalid = 0;
            }

            if (isNaN(searchSummary.totalNegative)) {
              searchSummary.totalNegative = 0;
            }

            if (isNaN(searchSummary.totalPositive)) {
              searchSummary.totalPositive = 0;
            }

            if (isNaN(searchSummary.totalTests)) {
              searchSummary.totalTests = 0;
            }
          });
        }

        return searchSummaries;
      }));
  }

  public getSearchConnectedInstruments(search: TestResultsQuery, pageNumber: number, pageSize: number, sortColumn: string = null, sortDirection: number = 0)
    : Observable<SearchConnectedInstrumentsResultsPage> {
    const searchQueryContainer: SearchQueryContainer = {
      queryParameters: search,
      pageNumber,
      pageSize
    };

    if (sortColumn) {
      searchQueryContainer.sortColumn = sortColumn;
      searchQueryContainer.sortDirection = sortDirection;
    }

    return this.httpClient.post<SearchConnectedInstrumentsResultsPage>(this.getFullUrl(this.getSearchConnectedInstrumentsUrl), searchQueryContainer);
  }

  // Reports
  public getReportPatientsByRunDate(query: DateGroupTypeTestQuery): Observable<TestResult_PatientsByRunDateRow[]> {
    return this.httpClient.post<TestResult_PatientsByRunDateRow[]>(this.getFullUrl(this.getReportPatientByRunDateUrl), query);
  }

  public getReportPatientsCoInfections(search: TestResultsQuery): Observable<TestResult_PatientsCoInfectionRow[]> {
    return this.httpClient.post<TestResult_PatientsCoInfectionRow[]>(this.getFullUrl(this.getReportPatientCoInfectionsUrl), search);
  }

  public getReportPatientsByAssay(search: TestResultsQuery): Observable<TestResult_PatientsByAssayRow[]> {
    return this.httpClient.post<TestResult_PatientsByAssayRow[]>(this.getFullUrl(this.getReportPatientByAssayUrl), search);
  }

  public getReportPatientsByFacilityAssay(search: TestResultsQuery): Observable<TestResult_PatientsByFacilityAssayRow[]> {
    return this.httpClient.post<TestResult_PatientsByFacilityAssayRow[]>(this.getFullUrl(this.getReportPatientByFacilityAssayUrl), search);
  }

  public getReportPatientsByFacilityResult(search: TestResultsQuery): Observable<TestResult_PatientsByFacilityResultRow[]> {
    return this.httpClient.post<TestResult_PatientsByFacilityResultRow[]>(this.getFullUrl(this.getReportPatientByFacilityResultUrl), search);
  }

  public getReportPatientsByResult(search: ResultGroupTypeTestQuery): Observable<TestResult_PatientsByResultRow[]> {
    return this.httpClient.post<TestResult_PatientsByResultRow[]>(this.getFullUrl(this.getReportPatientByResultUrl), search);
  }

  public getReportTestVolumeByType(search: TestResultsQuery): Observable<TestResult_TestVolumeByTypeRow[]> {
    return this.httpClient.post<TestResult_TestVolumeByTypeRow[]>(this.getFullUrl(this.getReportTestVolumeByTypeUrl), search);
  }

  public getReportPercentPositiveByRunDate(query: DateGroupTypeTestQuery): Observable<TestResult_PercentPositiveByRunDateRow[]> {
    return this.httpClient.post<TestResult_PercentPositiveByRunDateRow[]>(this.getFullUrl(this.getReportPercentPositiveByRunDateUrl), query);
  }

  public getReportPatientResultTrends(query: DateGroupTypeTestQuery): Observable<TestResult_PatientResultTrendRow[]> {
    return this.httpClient.post<TestResult_PatientResultTrendRow[]>(this.getFullUrl(this.getReportPatientResultTrendsUrl), query);
  }

  public getReportQualityControl(search: TestResultsQuery): Observable<TestResult_QualityControlRow[]> {
    return this.httpClient.post<TestResult_QualityControlRow[]>(this.getFullUrl(this.getReportQualityControlUrl), search);
  }

  public getReportPatientResultTrendDetails(query: DateGroupTypeTestQuery): Observable<TestResult_PatientResultTrendRow[]> {
    return this.httpClient.post<TestResult_PatientResultTrendRow[]>(this.getFullUrl(this.getReportPatientResultTrendDetailsUrl), query);
  }

}
