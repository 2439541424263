import {
  Component, OnInit, OnDestroy, HostListener, ViewChild,
  ViewChildren, ElementRef, Renderer2, QueryList, NgZone, Input, Inject, Optional
} from '@angular/core';
import {
  SearchService, QueryService, BaseComponent, TitleService, TestResultResponse,
  TestResultSummary, TestResultSummariesResponse, DateConstants, ThemeService, TestResultsService, MetadataService,
  InitDataSelectionLists, SampleType, TestResultGroup, OrgFacilities, Geo, PageDirection, ApiError, ApiStatusDescription, TranslateNotificationKeys, TestResultsQuery, RoleAccessService, UserGroup
} from '../core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PageEvent, MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { TestResultsDetailsDialogComponent } from './details/test-results-details-dialog.component';
import { TestResultAccessService, TestResultFieldAccess } from './test-result.access.service';
import { SortOrder, ColumnSort } from '../utils/sorting';
import { CdkDragDrop, CdkDragStart, CdkDragEnd } from '@angular/cdk/drag-drop';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import * as moment_from from 'moment';
import { HttpErrorResponse } from '@angular/common/http';
import { TestResultsPaginator } from './test-results.paginator';
import { GoogleAnalyticsService } from '../core/services/google-analytics.service';
const moment = moment_from; // https://github.com/jvandemo/generator-angular2-library/issues/221

export const ResultCodeStrings = {
  positive: '',
  negative: '',
  invalid: '',
  passed: '',
  failed: '',
  unknown: ''
};

@Component({
  selector: 'app-test-results',
  templateUrl: './test-results.component.html',
  styleUrls: ['./test-results.component.scss'],
  providers: [{
    provide: MatPaginatorIntl,
    useClass: TestResultsPaginator
  }]
})
export class TestResultsComponent extends BaseComponent implements OnInit, OnDestroy {

  @Input() canGroup = true;
  @Input() detailQuery: TestResultGroup[];

  searchData: TestResultSummariesResponse;

  public runDateFormat = DateConstants.YearMonthDayWithoutTimeFormat;
  public pTableScrollHeightOffset = 30;

  public dataSource: TestResultSummary[];
  public groupSource: TestResultGroup[];
  public totalRecords = 0;
  public pageSizeOptions: number[] = [10, 20, 50, 100];
  public currentPage = 1;
  public rowsPerPage = 50;
  private currentPageDirection = PageDirection.FirstPage;
  private currentPagingContinuationToken: string = null;
  public columnWidths: string;

  sort: object = new Object();
  sortOrder: SortOrder[] = [];

  public headers: string[] = [];
  FieldAccess = TestResultFieldAccess;

  public showGroupPlaceholder = true;
  public groupedHeaders: string[] = [];
  public appliedGroupedHeaders: string[] = [];
  private defaultSortHeader = 'RunDate';

  private headerSortMap = {
    RunDate: 'TestCompletionDate',
    StorageDate: 'StorageDate',
    FacilityName: 'FacilityName',
    Country: 'FacilityCountryCode',
    State: 'FacilityStateCode',
    County: 'FacilityCountyName',
    Organization: 'OrganizationName',
    ResultType: 'SampleTypeName',
    Assay: 'TestName',
    SummaryResult: 'Result',
    SerialNumber: 'SerialNumber',
    Operator: 'OperatorId',
    PatientAge: 'PatientAge'
  };

  private headerGroupMap = {
    RunDate: 'TestCompletionDate',
    StorageDate: 'StorageDate',
    FacilityName: 'FacilityId',
    Country: 'FacilityCountryId',
    State: 'FacilityStateId',
    County: 'FacilityCountyId',
    Organization: 'OrganizationId',
    ResultType: 'SampleTypeName',
    Assay: 'TestName',
    SummaryResult: 'Result',
    SerialNumber: 'SerialNumber',
    Operator: 'OperatorId',
    PatientAge: 'PatientAge'
  };

  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild('header') headerElement: ElementRef;
  @ViewChildren('headerColumn', { read: ElementRef }) headerItems: QueryList<ElementRef>;

  private areResultsLoaded = false;
  private isDragging = false;
  private sampleTypes: SampleType[];

  constructor(private searchService: SearchService,
    private queryService: QueryService,
    private titleService: TitleService,
    private dialog: MatDialog,
    private themeService: ThemeService,
    public accessService: TestResultAccessService,
    private renderer: Renderer2,
    private testResultService: TestResultsService,
    private metadataService: MetadataService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private roleAccessService: RoleAccessService,
    private googleAnalyticsService : GoogleAnalyticsService,
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: TestResultGroup[],
  ) {
    super();

    if (injectedData) {
      this.canGroup = false;
      this.detailQuery = injectedData;
    }
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.themeService.enableTestResultsTheme();

      for (const value in TestResultFieldAccess) {
        if (this.accessService.hasAccess(value)) {
          this.headers.push(value);
        }
      }

      this.sort = new Object();
      this.sortOrder = [];
      this.sort[this.defaultSortHeader] = ColumnSort.Descending;
      this.sortOrder.push({ property: this.defaultSortHeader, direction: ColumnSort.Descending });
      this.sortOrder.push({ property: this.headerSortMap.StorageDate, direction: ColumnSort.Descending });

      this.updateIEGridColumnWidths();

      this.titleService.updateTitleTranslateKey('TestResults.Title');

      this.subscription.add(this.queryService.searchRequested.subscribe(() => {
        this.resetCurrentPageAndSearch();
      }));

      this.subscription.add(this.metadataService.fetchInitDataSelectionLists().subscribe((d: InitDataSelectionLists) => {
        this.sampleTypes = d.SampleTypes;
        this.resetCurrentPageAndSearch();
      }));
    });
  }

  private resetCurrentPageAndSearch() {
    if (this.paginator.pageIndex === 0) {
      this.onSearch();
    } else {
      // Changing the page to first page will cause search to fire from event
      this.paginator.firstPage();
    }
  }

  ngOnDestroyInternal(): void {
    // Required by base component
  }

  public onSearch(): void {
    this.pageSearch(this.currentPage, this.rowsPerPage);
  }

  private pageSearch(pageNumber: number, pageSize: number) {

    let actualPageSize = pageSize;
    this.groupSource = [];
    this.dataSource = [];

    const currentQuery = { ...this.queryService.getCurrentQuery() };
    console.debug(currentQuery);           
    
    let sortColumn = null;
    const sortColumns = [];
    let sortDirection = 0;

    if (this.sortOrder.length > 1) {
      this.sortOrder.forEach(s => {
        sortColumns.push(this.headerSortMap[s.property]);
      });
      sortDirection = this.sortOrder[0].direction === ColumnSort.None ? 0 : this.sortOrder[0].direction - 1;
    } else if (this.sortOrder.length === 1) {
      sortColumn = this.headerSortMap[this.sortOrder[0].property];
      sortDirection = this.sortOrder[0].direction === ColumnSort.None ? 0 : this.sortOrder[0].direction - 1;
    } else {
      sortColumn = this.headerSortMap.RunDate;
      sortDirection = ColumnSort.Descending - 1;
    }

    if (this.groupedHeaders && this.groupedHeaders.length > 0) {

      const groupBy = this.groupedHeaders.map(g => this.headerGroupMap[g]);

      if (this.currentPageDirection === PageDirection.LastPage) {
        const lastItemNumberFromPreviousPage = ((pageNumber - 1) * actualPageSize);
        actualPageSize = this.totalRecords - lastItemNumberFromPreviousPage;
      }   

      this.googleAnalyticsService.logWithLabels('Search Test Results with Grouped Headers', 'Test Results', 'Search With Grouped Headers','filters', currentQuery, 'groupedHeaders', this.groupedHeaders);
      this.subscription.add(this.searchService.getSearchGroups(currentQuery, pageNumber, actualPageSize, sortColumn, sortColumns, sortDirection,
        groupBy, this.currentPageDirection, this.currentPagingContinuationToken).subscribe(data => {
          console.debug(data);

          this.appliedGroupedHeaders = [...this.groupedHeaders];
          this.groupSource = data.results;
          this.totalRecords = data.totalResults;

          if (this.groupSource) {
            this.groupSource.forEach(g => this.initializeGroups(g));
          }

          this.areResultsLoaded = true;

          this.searchService.searchResultsCompletedSubject.next(this.totalRecords);
        }, (error: HttpErrorResponse) => {
          const apiError = error.error as ApiError;
          if (apiError.statusDescription === ApiStatusDescription.SearchCriteriaTooBroad) {
            const message = this.translate.instant(`${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.SearchCriteriaTooBroadWithGroupingMessage}`);
            const title = this.translate.instant(`${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.SearchCriteriaTooBroadTitle}`);
            this.toastr.error(message, title);
          }
        }));
    } else {

      this.appliedGroupedHeaders = [];

      if (this.detailQuery) {
        this.initializeDetailQuery(currentQuery);
      }

      this.googleAnalyticsService.logWithLabels('Search', 'Test Results', 'Search','filters', currentQuery);
      this.subscription.add(this.searchService.search(currentQuery, pageNumber, actualPageSize, sortColumn, sortColumns, sortDirection).subscribe(data => {

        this.searchData = data;

        this.dataSource = this.searchData.results;
        this.totalRecords = this.searchData.totalResults;

        console.debug(this.searchData);
        console.debug(this.totalRecords);

        this.areResultsLoaded = true;

        // When running in Pop Up Dialog, do not refresh the Search Results count in the Nav-Bar when searching the test results of a group
        if (this.injectedData === null) {
          this.searchService.searchResultsCompletedSubject.next(this.totalRecords);
        }  

        setTimeout(() => {
          this.updateColumnSizes();
        });
      }, (error: HttpErrorResponse) => {
        const apiError = error.error as ApiError;
        if (apiError.statusDescription === ApiStatusDescription.SearchCriteriaTooBroad) {
          const message = this.translate.instant(`${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.SearchCriteriaTooBroadMessage}`);
          const title = this.translate.instant(`${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.SearchCriteriaTooBroadTitle}`);
          this.toastr.error(message, title);
        }
      }));
    }
  }

  private initializeDetailQuery(query: TestResultsQuery) {
    let orgFacQuery: OrgFacilities;
    let geoQuery: Geo;

    this.detailQuery.forEach(q => {
      switch (q.key) {
        case this.headerGroupMap.Assay:
          {
            query.testTypes = [q.value];
          }
          break;
        case this.headerGroupMap.Country:
          {
            if (geoQuery) {
              geoQuery.countryId = q.value;
            } else {
              geoQuery = { countryId: q.value };
            }

            if(q.innerGroups != null && q.innerGroups.length > 0) {
              q.innerGroups.forEach(inner => {
                if(inner.key == this.headerGroupMap.State) {
                  if(geoQuery.stateIds) {
                    if(!geoQuery.stateIds.includes(inner.value)) {
                      geoQuery.stateIds.push(inner.value);
                    }
                  } else {
                    geoQuery.stateIds = [inner.value];
                  }
                }

                if(inner.innerGroups != null && inner.innerGroups.length > 0) {
                  inner.innerGroups.forEach(county => {
                    if(county.key == this.headerGroupMap.County) {
                      if(geoQuery.countyIds) {
                        if(!geoQuery.countyIds.includes(county.value)) {
                          geoQuery.countyIds.push(county.value);
                        }
                      } else {
                        geoQuery.countyIds = [county.value];
                      }
                    }
                  });
    
                }
  
              });
            }
          }
          break;
        case this.headerGroupMap.County:
          {
            if (geoQuery) {
              geoQuery.countyIds = [q.value];
            } else {
              geoQuery = { countyIds: [q.value] };
            }
          }
          break;
        case this.headerGroupMap.FacilityName:
          {
            if (orgFacQuery) {
              orgFacQuery.facilityIds = [q.value];
            } else {
              orgFacQuery = {
                facilityIds: [q.value]
              };
            }
          }
          break;
        case this.headerGroupMap.Operator:
          {
            query.operator = q.value;
          }
          break;
        case this.headerGroupMap.Organization:
          {
            if (orgFacQuery) {
              orgFacQuery.organizationId = q.value;
            } else {
              orgFacQuery = {
                organizationId: q.value
              };
            }
            
            if(q.innerGroups != null && q.innerGroups.length > 0) {
              q.innerGroups.forEach(inner => {
                if(inner.key == this.headerGroupMap.FacilityName) {
                  if(orgFacQuery.facilityIds) {
                    if(!orgFacQuery.facilityIds.includes(inner.value)) {
                      orgFacQuery.facilityIds.push(inner.value);
                    }
                  } else {
                    orgFacQuery.facilityIds = [inner.value];
                  }
                }
              });
            }
          }
          break;
        case this.headerGroupMap.PatientAge:
          {
            query.patientAge = q.value;
          }
          break;
        case this.headerGroupMap.ResultType:
          {
            query.sampleTypeNames = [q.value];
          }
          break;
        case this.headerGroupMap.RunDate:
          {
            const startDate = moment.utc(q.value).startOf('day').toDate();
            const endDate = moment.utc(q.value).endOf('day').toDate();
            query.dates = { startDate, endDate };
          }
          break;
        case this.headerGroupMap.StorageDate:
          {
            const startDate = moment.utc(q.value).startOf('day').toDate();
            const endDate = moment.utc(q.value).endOf('day').toDate();
            query.storageDates = { startDate, endDate };
          }
          break;
        case this.headerGroupMap.SerialNumber:
          {
            query.serialNumber = q.value;
          }
          break;
        case this.headerGroupMap.State:
          {
            if (geoQuery) {
              geoQuery.stateIds = [q.value];
            } else {
              geoQuery = { stateIds: [q.value] };
            }
          }
          break;
        case this.headerGroupMap.SummaryResult:
          {
            query.result = q.value;
          }
          break;
      }
    });

    if (orgFacQuery) {
      // return data for only 1 organization. Ignore other selected organizations in the filter
      query.orgsFacilities = [];
      query.orgsFacilities.push(orgFacQuery);
    }

    if (geoQuery) {
      query.geo = geoQuery;
    }
  }

  private initializeGroups(group: TestResultGroup) {
    group.expanded = false;

    if (group.innerGroups) {
      group.innerGroups.forEach(g => this.initializeGroups(g));
    }
  }

  loadResultsLazy(event: PageEvent) {
    console.debug('loadResultsLazy');
    console.debug(event);

    this.rowsPerPage = event.pageSize;
    this.currentPage = event.pageIndex + 1;

    if (this.groupedHeaders && this.groupedHeaders.length > 0) {
      const lastPageIndex = Math.floor(event.length / event.pageSize);
      if (event.pageIndex === 0) {
        this.currentPageDirection = PageDirection.FirstPage;
        this.currentPagingContinuationToken = null;
      } else if (event.pageIndex === lastPageIndex) {
        this.currentPageDirection = PageDirection.LastPage;
        this.currentPagingContinuationToken = null;
      } else if (event.pageIndex > event.previousPageIndex) {
        this.currentPageDirection = PageDirection.NextPage;
        this.currentPagingContinuationToken = this.groupSource[this.groupSource.length - 1].continuationToken;
      } else if (event.pageIndex < event.previousPageIndex) {
        this.currentPageDirection = PageDirection.PreviousPage;
        this.currentPagingContinuationToken = this.groupSource[0].continuationToken;
      }
    } else {
      this.currentPageDirection = PageDirection.FirstPage;
      this.currentPagingContinuationToken = null;
    }

    this.onSearch();
  }

  isMeasuredValue(resultCode: string): boolean {
    return !ResultCodeStrings.hasOwnProperty(resultCode);
  }

  onRowSelected(selectedResult: TestResultSummary) {
    if(!this.accessService.hasAccessToDetails()) {
      const message = this.translate.instant(`${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.TestResultDetailNoAccessMessage}`);
      this.toastr.error(message);      
    }
    else {
      const selectedId = selectedResult.id;
      this.subscription.add(this.testResultService.getTestResults(selectedId).subscribe(data => {
        this.openDialog(data);
      }));
    }
  }

  onHeaderClicked(header) {
    let sortDirection = ColumnSort.None;

    switch (this.sort[header]) {
      case ColumnSort.None:
        sortDirection = ColumnSort.Ascending;
        break;
      case ColumnSort.Ascending:
        sortDirection = ColumnSort.Descending;
        break;
      case ColumnSort.Descending:
        sortDirection = ColumnSort.None;
        break;
      default:
        sortDirection = ColumnSort.Ascending;
        break;
    }

    this.sort = new Object();
    this.sortOrder = [];
    this.sort[header] = sortDirection;

    if (sortDirection !== ColumnSort.None) {
      this.sortOrder.push({ property: header, direction: sortDirection });
    }

    if (header !== this.headerSortMap.StorageDate) {
      this.sortOrder.push({ property: this.headerSortMap.StorageDate, direction: sortDirection });
    }

    this.onSearch();
  }

  onHeaderDropped(item: CdkDragDrop<any>) {
    if (this.groupedHeaders.includes(item.item.data)) {
      return;
    }

    this.groupedHeaders.push(item.item.data);
    this.showGroupPlaceholder = this.groupedHeaders.length === 0;

    this.showGroupsChangedToast();
  }

  onGroupAreaDragEnter(item: CdkDragDrop<any>) {
    this.showGroupPlaceholder = false;
  }

  onGroupAreaDragExit(item: CdkDragDrop<any>) {
    this.showGroupPlaceholder = this.groupedHeaders.length === 0;
  }

  onDragStarted(event: CdkDragStart) {
    this.isDragging = true;
  }

  onDragEnded(event: CdkDragEnd) {
    this.isDragging = false;
    this.showGroupPlaceholder = this.groupedHeaders.length === 0;
  }

  removeGroup(header) {
    this.groupedHeaders.splice(this.groupedHeaders.indexOf(header), 1);
    this.showGroupPlaceholder = this.groupedHeaders.length === 0;

    this.showGroupsChangedToast();
  }

  openDialog(rowDetails: TestResultResponse): void {

    const modalWidth = 0.86 * window.outerWidth;
    const dialogRef = this.dialog.open(TestResultsDetailsDialogComponent, {
      width: `${modalWidth}px`,
      data: rowDetails
    });

    dialogRef.afterClosed().subscribe(result => {
      console.debug('The dialog was closed');
    });
  }

  onGroupToggle(group: TestResultGroup) {
    group.expanded = !group.expanded;
  }

  showGroupDetailView(event: MouseEvent, group: TestResultGroup) {
    event.preventDefault();
    event.stopImmediatePropagation();

    const groups = this.getGroupDetailHierarchy(group, this.groupSource);

    console.debug(groups);

    this.showGroupDetailDialog(groups);
  }

  private showGroupDetailDialog(groupQuery: TestResultGroup[]): void {
    const dialogRef = this.dialog.open(TestResultsComponent, {
      panelClass: 'no-scrolling-dialog',
      maxHeight: '80vh',
      data: groupQuery
    });

    dialogRef.afterClosed().subscribe(result => {
      console.debug('The dialog was closed');
    });
  }

  private getGroupDetailHierarchy(group: TestResultGroup, groups: TestResultGroup[]): TestResultGroup[] {
    if (!groups) {
      return null;
    }

    const matches: TestResultGroup[] = [];

    const match = groups.find(g => g === group);

    if (match) {
      matches.push(match);
    } else {
      groups.forEach(g => {
        const innerGroups = this.getGroupDetailHierarchy(group, g.innerGroups);
        if (innerGroups) {
          matches.push(g);
          matches.push(...innerGroups);
        }
      });
    }

    return matches.length > 0 ? matches : null;
  }

  updateColumnSizes() {
    if (!this.headerItems || this.isDragging) {
      return;
    }

    const headerWidths = [];

    this.headerItems.forEach(h => {
      headerWidths.push(h.nativeElement.offsetWidth);
    });

    console.debug(JSON.stringify(headerWidths));

    this.columnWidths = headerWidths.map(w => (w + 5) + 'px').join(' ');
  }

  updateIEGridColumnWidths() {
    const widths = this.headers.map(h => '1fr').join(' ');
    this.renderer.setStyle(this.headerElement.nativeElement, '-ms-grid-columns', widths);
  }

  private showGroupsChangedToast() {
    const groupsChangedMessage = this.translate.instant('TestResults.GroupsChanged');
    const duplicateToast = this.toastr.findDuplicate(undefined, groupsChangedMessage, true, false);
    if (!duplicateToast) {
      const result = this.toastr.info(groupsChangedMessage);
      console.debug(result);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    setTimeout(() => {
      this.updateColumnSizes();
    }, 100);
  }
}
