import { IGetLabel, UIQuerySimpleFilter, FilterElement, IGetId, IEquatable } from '../FilterElement';
import { Injectable, OnDestroy } from '@angular/core';
import { MetadataService } from '../../../services/metadata.service';
import { InitDataSelectionLists, TestType } from '../../../services/metadata.service.models';

export class AssayItem implements IGetLabel, IGetId, IEquatable<AssayItem> {
  private testType: TestType;

  constructor(testType: TestType) {
    this.testType = testType;
  }

  getLabel(): string {
    return this.testType.testName;
  }

  getId(): number {
    return this.testType.testTypeId;
  }

  equals(other: AssayItem): boolean {
    return this.testType.testTypeId === other.testType.testTypeId;
  }

  toString(): string {
    return this.getLabel();
  }
}

@Injectable({
  providedIn: 'root'
})
export class AssaysUIQuery extends UIQuerySimpleFilter<AssayItem> implements OnDestroy {

  constructor(private metadataService: MetadataService) {
    super();
  }

  protected initialize(): void {
    this.subscription.add(this.metadataService.fetchInitDataSelectionLists().subscribe((d: InitDataSelectionLists) => {

      if (d.TestTypes) {

        const selectedIds = this.items ? this.items.filter(i => i.isSelected).map(e => e.item.getId()) : [];

        const items: FilterElement<AssayItem>[] = [];
        d.TestTypes.forEach(t => {
          const item = FilterElement.Build(new AssayItem(t));
          item.isSelected = selectedIds && selectedIds.length > 0 ? selectedIds.includes(item.item.getId()) : false;
          items.push(item);
        });

        this.items = items;

        // example for testing defaults
        // const firstItem = this.items[0];
        // firstItem.isSelected = true;
        // this.setDefaults([firstItem]);

        this.setDefaults();

        this.itemsLoaded.next(this.items);
      }
    }));
  }

  public refreshData(): void {
    this.initialize();
  }

  protected setDefaults(selectedItems: FilterElement<AssayItem>[] = null): void {
    this.setDefaultsItems(selectedItems);
  }

  public setDefaultState(assaysIds: number[]): void {
    if (!assaysIds) {
      this.items.forEach(i => i.isSelected = false);
    } else {
      for (const id of assaysIds) {
        const matchingItem = this.items.find(i => i.item.getId() === id);
        if (matchingItem) {
          matchingItem.isSelected = true;
        }
      }
    }

    this.setDefaults();
  }

  public serialize(): number[] {
    if (!this.items) {
      return undefined;
    }

    const selectedIds = this.items.filter(i => i.isSelected).map(e => e.item.getId());
    return selectedIds;
  }

}
