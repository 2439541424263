<body>

  <!-- //FUTURE: How to switch languages
  <div style="margin-top: 100px;">
    <button (click)="useLanguage('en')">en</button>
    <button (click)="useLanguage('de')">de</button>
  </div> -->

  <div class="app-loading" *ngIf="isLoading">
    <div class="logo"></div>
    <svg class="spinner" viewBox="25 25 50 50">
      <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"></circle>
    </svg>
  </div>

  <ng-container *ngIf="!isIframe">
    <ng-container *ngIf="isAuthorized">
      <core-header-bar></core-header-bar>
      <app-nav-bar *ngIf="showNav"></app-nav-bar>
      <div id="container" [style.height]="showNav ? 'calc(100% - 118px)' : 'calc(100% - 76px)'">
        <div id="sidebar" *ngIf="showNav">
          <div id="sidebar-content">
            <core-filter>
            </core-filter>
          </div>
        </div>
        <div id="content" [style.width]="showNav ? 'calc(100% - 150px)' : '100%'">
          <div id="main-content">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="isUnauthorized">
      <app-pending-approval></app-pending-approval>
    </ng-container>
    <ng-container *ngIf="isUserBlockingCookies">
      <app-user-blocking-cookies></app-user-blocking-cookies>
    </ng-container>
  </ng-container>
  <ngx-spinner size="large" type="ball-atom"></ngx-spinner>
</body>
