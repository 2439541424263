// Make sure these values stay in sync with Quidel.MyVirena2.Common.Models.OutputType on the API

export class ReportKeys {
    public static readonly Csv = '0';
    public static readonly PatientsByRunDate = '2';
    public static readonly PatientsByAssay = '3';
    public static readonly PatientsByFacilityAssay = '4';
    public static readonly PatientsByFacilityResult = '5';
    public static readonly PatientsByResult = '6';
    public static readonly PatientResultTrends = '7';
    public static readonly PercentPositiveResults = '8';
    public static readonly QualityControlReport = '9';
    public static readonly TestVolumeByType = '10';
    public static readonly CsvByStorageDate = '11';
    public static readonly PatientCoInfections = '12';
}

export class ReportDateGroupKeys {
    public static readonly Years = '0';
    public static readonly Months = '1';
    public static readonly Weeks = '2';
    public static readonly Days = '3';
}
