import { IGetLabel, UIQuerySimpleFilter, FilterElement, IGetId, IEquatable } from '../FilterElement';
import { Injectable, OnDestroy } from '@angular/core';
import { MetadataService } from '../../../services/metadata.service';
import { UserFavorite, FilterState, UserFavoriteSchedule } from '../../../services/metadata.service.models';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { TranslateNotificationKeys } from '../../../translate/translate.notificationKeys';

export class FavoriteItem implements IGetLabel, IGetId, IEquatable<FavoriteItem> {

  private userFavorite: UserFavorite;
  private isReadOnly: boolean;

  constructor(userFavorite: UserFavorite, isReadOnly: boolean = false) {
    this.userFavorite = userFavorite;
    this.isReadOnly = isReadOnly;

    if (!this.userFavorite.shareWithOrg) {
      this.userFavorite.shareWithOrg = false;
    }
  }

  getLabel(): string {
    return this.userFavorite.name;
  }

  getId(): number {
    return this.userFavorite.userFavoriteId;
  }

  getUserFavorite(): UserFavorite {
    return this.userFavorite;
  }

  getUserFavoriteSchedule(): UserFavoriteSchedule {
    this.userFavorite.schedule.userFavorites_UserFavoriteId = this.userFavorite.userFavoriteId;
    return this.userFavorite.schedule;
  }

  setName(name: string): void {
    this.userFavorite.name = name;
  }

  setShareWithOrg(share: boolean): void {
    this.userFavorite.shareWithOrg = share;
  }

  setSchedule(schedule: UserFavoriteSchedule): void {
    this.userFavorite.schedule = schedule;
  }

  isShareWithOrgEnabled(): boolean {
    return this.userFavorite.shareWithOrg;
  }

  equals(other: FavoriteItem): boolean {
    return this.getId() === other.getId();
  }

  updateFilterState(filterState: FilterState): void {
    this.userFavorite.filterState = filterState;
  }

  getFilterState(): FilterState {
    return this.userFavorite.filterState;
  }

  getIsReadOnly(): boolean {
    return this.isReadOnly;
  }

}


@Injectable({
  providedIn: 'root'
})
export class FavoritesUIQuery extends UIQuerySimpleFilter<FavoriteItem> implements OnDestroy {

  constructor(private metadataService: MetadataService, private toastr: ToastrService,
    private translate: TranslateService) {
    super();

    void this.initialize();
  }

  protected async initialize(): Promise<void> {
    // By this point getMe should be cached so don't have to worry about cancelling an http call
    const user = await this.metadataService.getMe().toPromise();

    this.subscription.add(this.metadataService.getFavoriteForCurrentUser().subscribe((d: UserFavorite[]) => {
      this.items = [];

      d.forEach(f => {
        if (f) {
          const isReadOnly = f.userDetail_UserDetailId !== user.userDetailId;
          this.items.push(FilterElement.Build(new FavoriteItem(f, isReadOnly)));
        }
      });
    }));
  }

  public async addFavorite(model: UserFavorite): Promise<boolean> {

    try {
      const userFavoriteId = await this.metadataService.postInsertUserFavorite(model).toPromise();
      if (userFavoriteId) {

        const userFavorite = await this.metadataService.getUserFavorite(userFavoriteId).toPromise();
        if (userFavorite) {
          const item = FilterElement.Build(new FavoriteItem(userFavorite));
          this.items = [...this.items, item];

          console.debug(this.items);

          return Promise.resolve(true);
        }
      }
    } catch (error) {
      this.toastr.error(undefined, this.translate.instant(`${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.SaveFailure}`));
    }

    return Promise.resolve(false);
  }

  public async updateFavorite(item: FavoriteItem): Promise<boolean> {

    const selectedFavorite = this.getSelectedFavorite();
    let resetDirty = false;
    if (selectedFavorite && selectedFavorite.item.getId() === item.getId() && this.isDirty()) {
      const serlizedFiltersState = await this.filterService.serializeState();
      item.updateFilterState(serlizedFiltersState);
      resetDirty = true;
    }

    try {
      if (!item.getIsReadOnly()) {
        const userFavorite: UserFavorite = item.getUserFavorite();
        await this.metadataService.updateUserFavorite(userFavorite).toPromise();
      } else { // is read only
        const schedule: UserFavoriteSchedule = item.getUserFavoriteSchedule();
        await this.metadataService.updateUserFavoriteSchedule(schedule).toPromise();
      }
    } catch (error) {
      this.toastr.error(undefined, this.translate.instant(`${TranslateNotificationKeys.Prefix}.${TranslateNotificationKeys.SaveFailure}`));
      return Promise.resolve(false);
    }


    if (resetDirty) {
      this.filterService.deserializeStateFrom(item);
      return Promise.resolve(true);
    }

    return Promise.resolve(false);
  }

  public async deleteFavorite(model: FavoriteItem): Promise<boolean> {
    const userFavoriteId = model.getId();
    await this.metadataService.deleteUserFavorite(userFavoriteId).toPromise();

    const itemDeleteId = this.items.findIndex(i => i.item.getId() === userFavoriteId);
    this.items.splice(itemDeleteId, 1);
    this.items = [...this.items];

    console.debug(this.items);

    return Promise.resolve(true);
  }

  public getSelectedFavorite(): FilterElement<FavoriteItem> {
    if (!this.items) {
      return undefined;
    }

    return this.items.find(o => o.isSelected);
  }

  public async loadFavoriteSchedule(userFavoriteId: number): Promise<UserFavoriteSchedule> {
    const favorite = await this.metadataService.getUserFavorite(userFavoriteId).toPromise();

    return Promise.resolve(favorite.schedule);
  }

  public isDirty(): boolean {
    const selectedFavorite = this.getSelectedFavorite();
    if (selectedFavorite && this.filterService.areFiltersDirty()) {
      return true;
    }
    return false;
  }

  protected setDefaults(): void {
    // intentionally blank
  }
}
