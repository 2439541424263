import { Injectable } from '@angular/core';
import { RoleAccessService, UserGroup } from '../core';

export enum TestResultFieldAccess {
  RunDate = 'RunDate',
  StorageDate = 'StorageDate',
  FacilityName = 'FacilityName',
  Country = 'Country',
  State = 'State',
  County = 'County',
  Organization = 'Organization',
  ResultType = 'ResultType',
  Assay = 'Assay',
  SummaryResult = 'SummaryResult',
  SerialNumber = 'SerialNumber',
  Operator = 'Operator',
  PatientAge = 'PatientAge',
}


@Injectable({
  providedIn: 'root'
})

export class TestResultAccessService {

  constructor(private roleAccessService: RoleAccessService) { }

  public hasAccess(fieldName: string): boolean {
    let accessGranted = true;

    if (!this.isSysAdminOrGlobal()) {
      switch (fieldName) {
        case TestResultFieldAccess.Country:
        case TestResultFieldAccess.State:
        case TestResultFieldAccess.County:
        case TestResultFieldAccess.Organization:
          accessGranted = !this.isOrgAdminOrOrg();
          break;
        case TestResultFieldAccess.SerialNumber:
        case TestResultFieldAccess.Operator:
          accessGranted = !this.isPublicUser();
          break;
      }
    } else if (fieldName === TestResultFieldAccess.StorageDate) {
      accessGranted = this.isSysAdmin();
    }

    return accessGranted;
  }

  public hasAccessToDetails(): boolean {
    return this.roleAccessService.getRole() !== UserGroup.SalesAdmin &&
           this.roleAccessService.getRole() !== UserGroup.TechSupportAdmin;
  }

  private isSysAdmin(): boolean {
    const role = this.roleAccessService.getRole();
    let result = false;
    if (role) {
      if (role === UserGroup.SystemAdmin) {
        result = true;
      }
    }

    return result;
  }

  private isSysAdminOrGlobal(): boolean {
    const role = this.roleAccessService.getRole();
    let result = false;
    if (role) {
      if (role === UserGroup.SystemAdmin ||
        role === UserGroup.Global
      ) {
        result = true;
      }
    }

    return result;
  }

  private isPublicUser(): boolean {
    const role = this.roleAccessService.getRole();
    let result = false;
    if (role) {
      if (role === UserGroup.Country ||
        role === UserGroup.State ||
        role === UserGroup.County
      ) {
        result = true;
      }
    }
    return result;
  }

  private isSysAdminOrGlobalOrOrgAdminOrOrg(): boolean {
    const role = this.roleAccessService.getRole();
    let result = false;
    if (role) {
      if (role === UserGroup.SystemAdmin ||
        role === UserGroup.Global ||
        role === UserGroup.OrgAdmin ||
        role === UserGroup.Org
      ) {
        result = true;
      }
    }

    return result;
  }

  private isOrgAdminOrOrg(): boolean {
    const role = this.roleAccessService.getRole();
    let result = false;
    if (role) {
      if (role === UserGroup.OrgAdmin ||
        role === UserGroup.Org
      ) {
        result = true;
      }
    }
    return result;
  }

}
