import { Injectable, OnDestroy } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { FilterService } from '../../services/filter.service';

export interface IGetLabel {
  getLabel(): string;
}

export interface IGetId {
  getId(): number;
}

export interface IEquatable<T> {
  equals(other: T): boolean;
}

export class FilterElement<T extends IGetLabel> implements IGetLabel {
  isSelected: boolean;
  isHidden: boolean;
  item?: T;

  private constructor(item: T) {
    this.item = item;
  }

  public static Build<T extends IGetLabel>(item: T, isHidden: boolean = false, isSelected: boolean = false): FilterElement<T> {
    const element = new FilterElement<T>(item);
    element.isHidden = isHidden;
    element.isSelected = isSelected;

    return element;
  }

  public toggleSelected(): void {
    this.isSelected = !this.isSelected;
  }

  public resetSelection(): void {
    this.isSelected = false;
  }

  getLabel(): string {
    return this.item.getLabel();
  }

}

@Injectable()
export abstract class UIQueryFilterBase implements OnDestroy {

  protected filterService: FilterService;
  protected subscription = new Subscription();
  protected resetOccurred = new Subject();
  public resetOccurred$ = this.resetOccurred.asObservable();

  public abstract hasAtLeastOneFilterSelected(): boolean;

  public abstract reset(): void;

  public abstract isDirty(): boolean;

  protected abstract initialize(): void;

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public setFilterService(filterService: FilterService): void {
    this.filterService = filterService;
  }
}

export abstract class UIQueryHierarchyFilterBase<TRoot extends IGetLabel & IGetId & IEquatable<TRoot>, TChild extends IGetLabel & IGetId & IEquatable<TChild>>
  extends UIQueryFilterBase {

  public rootItems: FilterElement<TRoot>[];
  protected childItemsMap: Map<number, FilterElement<TChild>[]> = new Map<number, FilterElement<TChild>[]>();

  protected rootItemsLoaded = new Subject<FilterElement<TRoot>[]>();
  public rootItemsLoaded$ = this.rootItemsLoaded.asObservable();

  protected defaultRootItem: FilterElement<TRoot>;
  protected defaultChildItems: FilterElement<TChild>[] = [];

  public hasAtLeastOneFilterSelected(): boolean {
    return this.getSelectedRootItem() !== undefined;
  }

  protected getSelectedRootItem(): FilterElement<TRoot> {
    if (!this.rootItems) {
      return undefined;
    }

    return this.rootItems.find(o => o.isSelected);
  }

  protected getSelectedRootItems(): FilterElement<TRoot>[] {
    if (!this.rootItems) {
      return undefined;
    }

    return this.rootItems.filter(o => o.isSelected);
  }

  protected resetInternal(): void {
    if (this.rootItems) {
      this.rootItems.forEach(f => f.resetSelection());
    }

    this.childItemsMap.forEach(e => e.forEach(c => c.resetSelection()));
  }

  protected isDirtyInternal(selectedRootItem: FilterElement<TRoot>, selectedChildItems: FilterElement<TChild>[]): boolean {
    if (selectedRootItem) {
      if (!this.defaultRootItem) {
        return true;
      }

      if (selectedRootItem.isSelected !== this.defaultRootItem.isSelected
        || !selectedRootItem.item.equals(this.defaultRootItem.item)) {
        return true;
      }

    } else if (this.defaultRootItem && this.defaultRootItem.isSelected) {
      return true;
    }

    if (!selectedRootItem && !this.defaultRootItem) {
      return false;
    }

    let selectedDefaultChildItems: FilterElement<TChild>[];
    if (this.defaultChildItems) {
      selectedDefaultChildItems = this.defaultChildItems.filter(e => e.isSelected);
    }
    if (selectedChildItems) {
      if (!selectedDefaultChildItems) {
        return true;
      }

      if (selectedChildItems.length !== selectedDefaultChildItems.length) {
        return true;
      }

      for (const defaultChildItem of selectedDefaultChildItems) {
        const selectedChildItem = selectedChildItems.find(e => e.item.equals(defaultChildItem.item));
        if (!selectedChildItem) {
          return true;
        }
      }

    } else if (selectedDefaultChildItems && selectedDefaultChildItems.length > 0) {
      return true;
    }

    return false;
  }

  protected async getSelectedChildItems(rootItem: FilterElement<TRoot>, getChildItemsFunc: (root: FilterElement<TRoot>) => Promise<FilterElement<TChild>[]>):
    Promise<FilterElement<TChild>[]> {

    if (!rootItem) {
      return undefined;
    }

    const childItems = await getChildItemsFunc(rootItem);
    const selectedChildItems = childItems.filter(f => f.isSelected);
    if (selectedChildItems.length === 0) {
      return undefined;
    }

    return selectedChildItems;
  }

  protected getSelectedCachedChildItems(rootItem: FilterElement<TRoot>): FilterElement<TChild>[] {
    if (!rootItem) {
      return undefined;
    }

    const cachedChildItems = this.childItemsMap.get(rootItem.item.getId());
    if (cachedChildItems) {
      return cachedChildItems.filter(e => e.isSelected);
    }

    return undefined;
  }

  protected async setDefaultStateInternal(rootId: number, getChildItemsFunc: (root: FilterElement<TRoot>) => Promise<FilterElement<TChild>[]>,
    childIds?: number[]): Promise<void> {

    const defaultRootItemOriginal = this.rootItems.find(e => e.item.getId() === rootId);
    if (defaultRootItemOriginal) {
      defaultRootItemOriginal.isSelected = true;
      this.defaultRootItem = { ...defaultRootItemOriginal } as FilterElement<TRoot>;

      if (childIds) {
        const childItems = await getChildItemsFunc(defaultRootItemOriginal);
        const defaultChildItemOriginals = childItems.filter(e => childIds.includes(e.item.getId()));
        defaultChildItemOriginals.forEach(e => e.isSelected = true);

        this.defaultChildItems = defaultChildItemOriginals.map(e => ({ ...e }) as FilterElement<TChild>);
      } else {
        this.defaultChildItems.length = 0;
      }
    } else {
      this.defaultRootItem = undefined;
      this.defaultChildItems.length = 0;
    }
  }
}

export abstract class UIQuerySimpleFilter<T extends IGetLabel & IGetId & IEquatable<T>> extends UIQueryFilterBase {
  items: FilterElement<T>[];
  defaultItems: FilterElement<T>[];

  protected filterService: FilterService;

  protected itemsLoaded = new Subject<FilterElement<T>[]>();
  public itemsLoaded$ = this.itemsLoaded.asObservable();

  protected clone(array: FilterElement<T>[]): FilterElement<T>[] {
    // TODO: better way to deep copy
    // https://stackoverflow.com/questions/35504310/deep-copy-an-array-in-angular-2-typescript
    // return Object.assign([], array); // not working
    // return JSON.parse(JSON.stringify(array)); // getId() missing

    // it's shallow copy, FilterElement are copied by reference
    return array.map(i => ({ ...i })) as FilterElement<T>[];
  }

  protected abstract setDefaults(selectedItems: FilterElement<T>[]): void;

  protected setDefaultsItems(selectedItems: FilterElement<T>[] = null): void {
    if (selectedItems) {
      for (const s of selectedItems) {
        const matchingItem = this.items.find(e => e.item.getId() === s.item.getId());

        if (matchingItem) {
          matchingItem.item = s.item;
        }
      }
    }

    this.defaultItems = this.clone(this.items);
  }

  public isDirty(): boolean {

    // console.debug(this.items);
    // console.debug(this.defaultItems);

    const selectedDefaultItems = this.defaultItems.filter(e => e.isSelected);
    const selectedCurrentItems = this.items.filter(e => e.isSelected);

    if (selectedDefaultItems.length !== selectedCurrentItems.length) {
      return true;
    }

    for (const defItem of this.defaultItems) {
      const currentItem = this.items.find(e => e.item.getId() === defItem.item.getId());

      // console.debug(`currentItem: ${currentItem}`);
      if (currentItem) {
        if (currentItem.isSelected !== defItem.isSelected) {
          // console.debug(`currentItem.isSelected: ${currentItem.isSelected} defItem.isSelected: ${defItem.isSelected}`);
          return true;
        } else if (!defItem.item.equals(currentItem.item)) { // currentItem.isSelected === defItem.isSelected
          // console.debug(defItem);
          // console.debug(currentItem);
          return true;
        }
      } else {
        return true;
      }

    }

    return false;
  }


  public hasAtLeastOneFilterSelected(): boolean {
    let isActive = false;
    if (this.items && this.items.length > 0) {
      isActive = this.items.some(i => {
        return i.isSelected;
      });
    }

    return isActive;
  }

  public getSelectedItems(): T[] {
    if (this.items && this.items.length > 0) {
      const selected = this.items.filter(i => i.isSelected).map(r => r.item);
      return selected;
    }

    return undefined;

  }

  public reset(): void {
    if (!this.items) {
      return;
    }
    this.items.forEach(i => i.resetSelection());

    this.resetOccurred.next();
  }

}


