<div class="test-container" cdkDropListGroup>
  <div class="test-group-container noselect" cdkDropList (cdkDropListDropped)="onHeaderDropped($event)"
    (cdkDropListEntered)="onGroupAreaDragEnter($event)" (cdkDropListExited)="onGroupAreaDragExit($event)"
    #groupList="cdkDropList" cdkDropListOrientation="horizontal" *ngIf="canGroup">
    <span class="group-placeholder" [class.hidden]="!showGroupPlaceholder">Drag here to group results</span>
    <div class="header-column noselect group-column" *ngFor="let header of groupedHeaders; index as i">
      {{ 'TestResults.' + header | translate }}
      <i class="material-icons group-remove" (click)="removeGroup(header)">clear</i>
    </div>
  </div>
  <div class="test-table-container">
    <div class="header" cdkDropList cdkDropListSortingDisabled #mainList="cdkDropList"
      cdkDropListOrientation="horizontal" #header>
      <div class="header-column noselect" *ngFor="let header of headers; index as i" [style.grid-column]="i + 1"
        [style.-ms-grid-column]="i + 1" (click)="onHeaderClicked(header)" #headerColumn cdkDrag [cdkDragData]="header"
        (cdkDragStarted)="onDragStarted($event)" (cdkDragEnded)="onDragEnded($event)" cdkDragBoundary=".test-container">
        {{ 'TestResults.' + header | translate }} <i
          class="material-icons">{{this.sort[header] === 1 ? 'arrow_drop_up' : this.sort[header] === 2 ? 'arrow_drop_down' : ''}}</i>
      </div>
    </div>
    <div class="scroll-container">
      <div class="table">
        <ng-container
          *ngTemplateOutlet="(appliedGroupedHeaders.length === 0 ? rows : groupedRows); context: { dataSource: appliedGroupedHeaders.length === 0 ? dataSource : groupSource, groupedHeaders: appliedGroupedHeaders, headerIndex: 0 }">
        </ng-container>
      </div>
    </div>
    <div class="paginator">
      <mat-paginator #paginator [length]="totalRecords" [pageSize]="rowsPerPage" [pageSizeOptions]="pageSizeOptions"
        (page)="loadResultsLazy($event)" showFirstLastButtons="false">
      </mat-paginator>
    </div>
  </div>
</div>

<ng-template #groupedRows let-dataSource="dataSource" let-groupedHeaders="groupedHeaders" let-headerIndex="headerIndex">
  <ul class="group-list" *ngFor="let result of dataSource; index as i" [style.grid-row]="i + 1"
    [style.-ms-grid-row]="i + 1">
    <ng-container
      *ngTemplateOutlet="groupRow; context: { group: result, row: i, index: headerIndex, groupedHeaders: groupedHeaders }">
    </ng-container>
  </ul>
</ng-template>

<ng-template #rows let-dataSource="dataSource">
  <div class="table-row" #tableRow *ngFor="let result of dataSource; index as i" [style.grid-row]="i + 1"
    [style.-ms-grid-row]="i + 1" (click)="onRowSelected(result)" [style.grid-template-columns]="columnWidths"
    [style.-ms-grid-columns]="columnWidths">
    <ng-container *ngTemplateOutlet="rowData; context: { result: result }"></ng-container>
  </div>
</ng-template>

<ng-template #groupRow let-group="group" let-idx="index" let-row="row" let-groupedHeaders="groupedHeaders">
  <li class="noselect" (click)="onGroupToggle(group)">
    <div [style.margin-left.px]="idx * 40" class="group-label">
      <i class="material-icons group-icon"
        *ngIf="group.innerGroups?.length > 0">{{ group.expanded ? 'arrow_drop_down' : 'arrow_right'}}</i>
      <!-- <span>{{ 'TestResults.' + groupedHeaders[idx] | translate }}</span>
      <span>: </span> -->
      <span class="group-value">{{ group.name }}</span>
      <span>(</span><a
        (click)="showGroupDetailView($event, group)"><span>{{ group.count | number: '1.0-0' }}</span><span>
          records</span></a><span>)</span>
    </div>
  </li>
  <ng-container
    *ngTemplateOutlet="(group.innerGroups?.length > 0 && group.expanded ? groupedRows : null); context: { dataSource: group.innerGroups, groupedHeaders: groupedHeaders, headerIndex: idx + 1 }">
  </ng-container>
</ng-template>

<ng-template #rowData let-result="result">
  <div *ngIf="accessService.hasAccess(FieldAccess.RunDate)">
    {{ result.testCompletionDateUtc | date: runDateFormat : 'UTC'  }}</div>
  <div *ngIf="accessService.hasAccess(FieldAccess.StorageDate)">
    {{ result.storageDateUtc | date: runDateFormat }}</div>
  <div *ngIf="accessService.hasAccess(FieldAccess.FacilityName)" matTooltip="{{ result.facilityName }}">
    {{ result.facilityName }}</div>
  <div *ngIf="accessService.hasAccess(FieldAccess.Country)">{{ result.countryCode }}</div>
  <div *ngIf="accessService.hasAccess(FieldAccess.State)">{{ result.stateCode }}</div>
  <div *ngIf="accessService.hasAccess(FieldAccess.County)" matTooltip="{{ result.countyName }}">
    {{ result.countyName }}</div>
  <div *ngIf="accessService.hasAccess(FieldAccess.Organization)" matTooltip="{{ result.organizationName }}">
    {{ result.organizationName }}</div>
  <div *ngIf="accessService.hasAccess(FieldAccess.ResultType)"
    matTooltip="{{ result.sampleTypeName }}">
    {{ result.sampleTypeName }}</div>
  <div *ngIf="accessService.hasAccess(FieldAccess.Assay)" matTooltip="{{ result.testName }}">
    {{ result.testName }}</div>
  <div *ngIf="accessService.hasAccess(FieldAccess.SummaryResult)" class="centered-column">
    <div *ngIf="!isMeasuredValue(result.resultCode) else measuredValue">
      <img *ngIf="result.resultCode === 'positive'
                  || result.resultCode === 'negative'
                  || result.resultCode === 'invalid'
                  || result.resultCode === 'passed'
                  || result.resultCode === 'failed'"
        src="/assets/images/summary_results/{{result.resultCode}}.png" />
    </div>
    <ng-template #measuredValue>
      {{result.resultCode}}
    </ng-template>
  </div>
  <div *ngIf="accessService.hasAccess(FieldAccess.SerialNumber)" matTooltip="{{ result.serialNumber }}">
    {{ result.serialNumber }}</div>
  <div *ngIf="accessService.hasAccess(FieldAccess.Operator)" matTooltip="{{ result.operatorId }}">
    {{ result.operatorId }}</div>
  <div *ngIf="accessService.hasAccess(FieldAccess.PatientAge)">{{ result.patientAge }}</div>
</ng-template>
