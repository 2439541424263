<div *ngIf="isOpened" class="container">

    <div class="title">
        <span>{{'FilterCustomOperator.Title' | translate}}</span>
        <i class="material-icons clear flipped" (click)="clearFilter()">refresh</i>
    </div>

    <div *ngFor="let i of query.items" (click)="selectItem(i)" class="submenu-item filter-option">
        <div class="submenu-item-container">
            <div>
                <div class="submenu-item">
                    <mat-form-field floatLabel="never">
                        <input matInput (focusout)="operatorChanged($event)" [(value)]="operatorFilter.item.operator"
                            placeholder="{{ 'FilterCustomOperator.OperatorHeader' | translate }}">
                    </mat-form-field>
                </div>
            </div>
            <div class="submenu-item-state" [class.has-filter-selected]="i.isSelected">
            </div>
        </div>
    </div>

</div>
