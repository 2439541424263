import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  TestResult_Result, TestResult_Test, TestResult_TestInformation,
  TestResult_TestInformation_StudyCode, TestResultResponse, DateConstants, BaseComponent
} from '../../core';
import { TestResultDetailsAccessService, TestResultDetailsFieldAccess } from './test-result-details.access.service';

@Component({
  selector: 'app-test-results-details-dialog',
  templateUrl: './test-results-details-dialog.component.html',
  styleUrls: ['./test-results-details-dialog.component.scss']
})
export class TestResultsDetailsDialogComponent extends BaseComponent implements OnInit {

  runDateFormat = DateConstants.YearMonthDayWithoutTimeFormat;
  storageDateFormat = DateConstants.YearMonthDayWithTimeFormat;

  displayColumns: string[];

  tableDataSource: TestResult_Result[];
  test: TestResult_Test;

  testInformation: TestResult_TestInformation;

  studyCodeA: string;
  studyCodeB: string;
  studyCodeC: string;
  studyCodeD: string;
  hideSignalCutoffValue: boolean

  FieldAccess = TestResultDetailsFieldAccess;

  constructor(
    public dialogRef: MatDialogRef<TestResultsDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TestResultResponse,
    public accessService: TestResultDetailsAccessService
  ) {

    super();
    this.defineTableColumns();
    this.testInformation = data.testInformation;
    this.hideSignalCutoffValue = false;
    
    if (this.data.tests && this.data.tests.length > 0) {
      this.test = this.data.tests[0];
      this.tableDataSource = this.test.results;
    }

    if (this.testInformation) {
      const studyCodes = this.testInformation.studyCodes;
      this.processStudyCodes(studyCodes);
    }
  }

  ngOnInit(): void {
  }

  ngOnDestroyInternal(): void {
    // Required by base component
  }

  private defineTableColumns() {

    this.displayColumns = ['analyte', 'result', 'snomedCode'];
    if (this.accessService.hasAccess(TestResultDetailsFieldAccess.SignalToCutOff)) {
      this.displayColumns.push('signalToCutOff');
    }
  }

  private processStudyCodes(studyCodes: TestResult_TestInformation_StudyCode[]) {
    if (studyCodes) {
      studyCodes.forEach(c => {
        if (c) {
          if (c.name === 'StudyCodeA') {
            this.studyCodeA = c.value;
          } else if (c.name === 'StudyCodeB') {
            this.studyCodeB = c.value;
          } else if (c.name === 'StudyCodeC') {
            this.studyCodeC = c.value;
          } else if (c.name === 'StudyCodeD') {
            this.studyCodeD = c.value;
          }
        }
      });
    }
  }

  onDoneClick(): void {
    this.dialogRef.close();
  }

}
