export enum ColumnSort {
    None,
    Ascending,
    Descending
}

export interface SortOrder {
    property: string;
    direction: ColumnSort;
}
