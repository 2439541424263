import { Component, OnInit, OnDestroy } from '@angular/core';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import {
  MetadataService, InactivityService, NavigationKeys, AppInsightsService, UrlService,
  ConfigurationService, Configuration, TranslateBaseComponent
} from './core';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter, map, takeUntil } from 'rxjs/operators';
import { AccountInfo, AuthenticationResult, AuthError, EventMessage, EventType, InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { Subject } from 'rxjs';

declare let gtag: (...args: any[]) => void;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends TranslateBaseComponent implements OnInit, OnDestroy {

  private static readonly PostAuthUrlKey = 'Quidel.MyVirena.PostAuthUrl';
  private static AppTranslateKey = 'App';

  title = 'MyVirena 2 Client';
  private configuration: Configuration;
  public isIframe: boolean;
  public isAuthorized: boolean;
  public isUnauthorized: boolean;
  public isUserBlockingCookies: boolean;
  public showNav: boolean;
  public isLoading = true;
  public previousUrl: string = null;
  public currentUrl: string = null;

  private readonly _destroying$ = new Subject<void>();
   
  constructor(private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private configurationService: ConfigurationService,
    private metadataService: MetadataService,
    private inactivityService: InactivityService,
    private router: Router,
    private appInsightsService: AppInsightsService,
    private urlService: UrlService,
    translate: TranslateService) {
    super(translate, AppComponent.AppTranslateKey);
    this.isIframe = window !== window.parent && !window.opener;

    this.configuration = configurationService.getConfiguration();
    this.appInsightsService.addTelemetryInitializer(this.title, location.origin);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTranslateResourcesLoaded(): void {
  }
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTranslateLanguageChanged(): void {
  }

  ngOnInit(): void {
    this.subscription.add(this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {

        const msalUser = this.checkAndSetActiveAccount();

        if (msalUser && msalUser.idTokenClaims) {
          const postAuthUrl = sessionStorage.getItem(AppComponent.PostAuthUrlKey);
          if (postAuthUrl) {
            window.location.href = postAuthUrl;
            sessionStorage.removeItem(AppComponent.PostAuthUrlKey);
          }

          this.initializeAfterLoginSuccess();
        }
      }));

    this.subscription.add(this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {

        const payload = result.payload as AuthenticationResult;

        /**
         * For the purpose of setting an active account for UI update, we want to consider only the auth response resulting
         * from SUSI flow. "tfp" claim in the id token tells us the policy (NOTE: legacy policies may use "acr" instead of "tfp").
         * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
         */
        if (payload.idTokenClaims['tfp'] === this.configuration.msalConfig.resetPwdPolicy) {
          // Password was reset, need to ensure the user is logged out and logs in to take effect
          this.authService.logoutRedirect();
        }

        return result;
      }));

    this.subscription.add(this.msalBroadcastService.msalSubject$
      .pipe(
        // Optional filtering of events
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        if (result.error instanceof AuthError) {
          let userFlowRequest: RedirectRequest | undefined;
          if (result.error.errorMessage.indexOf('AADB2C90118') > -1) {
            userFlowRequest = {
              scopes: ['openid'],
              authority: this.configuration.msalConfig.resetAuthority
            };
          } else if (result.error.errorMessage.indexOf('AADB2C90077') > -1) {
            this.isUserBlockingCookies = true;
            return;
          }

          sessionStorage.setItem(AppComponent.PostAuthUrlKey, window.location.href);

          this.authService.loginRedirect(userFlowRequest);
          return;
        }
      }));

    this.showNav = this.shouldShowNav(this.router.url);
    this.subscription.add(this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map((data: NavigationEnd) => this.shouldShowNav(data.url, data.urlAfterRedirects))
      ).subscribe((showNav: boolean) => {
        this.showNav = showNav;
      }));
  }

  ngOnDestroyInternal(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }

  useLanguage(language: string): void {
    this.translate.use(language);
  }

  private shouldShowNav(url: string, urlAfterRedirects?: string) {
    const urlToCheck = urlAfterRedirects && url !== urlAfterRedirects
      ? urlAfterRedirects
      : url;
    return urlToCheck.includes(NavigationKeys.Summary) || urlToCheck.includes(NavigationKeys.Results)
      || urlToCheck.includes(NavigationKeys.Mapping) || urlToCheck.includes(NavigationKeys.Reports) || urlToCheck === '/';
  }

  private initializeAfterLoginSuccess() {
    this.isLoading = false;

    this.urlService.currentUrl$.subscribe((currentUrl: string) => {
      this.currentUrl = currentUrl;
    });

    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(
      (event: NavigationEnd) => {
        this.urlService.setPreviousUrl(this.currentUrl);
        this.urlService.setCurrentUrl(event.url);

        gtag('config', this.configurationService.getConfiguration().GoogleAnalyticsTrackingID, {         
          page_path: event.urlAfterRedirects
        });
      }
    );

    this.subscription.add(this.metadataService.getSettings().subscribe((settings: any) => {
      if (!settings) {
        return;
      }

      const msalUser = this.authService.instance.getActiveAccount();
      this.configurationService.setSettings(settings);
      const oid = 'oid';
      const userOid = msalUser.idTokenClaims[oid] as string;
      this.appInsightsService.setUserId(userOid);    
      if (this.configurationService.getConfiguration().isUnauthorized) {
        this.isAuthorized = false;
        this.isUnauthorized = true;
        this.subscription.add(this.metadataService.sendRegistrationEmail(userOid).subscribe((ret) => {
          // subscribe required to ensure execution
        }));
      } else {
        this.isAuthorized = true;
        this.isUnauthorized = false;
      }

      this.inactivityService.refreshSettings();
    }));
  }

  private checkAndSetActiveAccount(): AccountInfo {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     */
    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      const accounts = this.authService.instance.getAllAccounts();
      activeAccount = accounts[0];
      this.authService.instance.setActiveAccount(activeAccount);
    }

    if (activeAccount) {
      console.debug('Active Account set');
    } else {
      console.debug('No account available to set as active account');
    }

    return activeAccount;
  }
}
