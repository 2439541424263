<div *ngIf="isOpened" class="container">

  <div class="title">
    <span>{{'FilterCustomFavorites.Title' | translate}}</span>
    <mat-icon class="material-icons clear flipped" (click)="clearFilter()"
      [ngClass]="{'disabled':!selectedFavorite?.isSelected, 'mat-not-allowed': !selectedFavorite?.isSelected}">refresh
    </mat-icon>
  </div>

  <div class="title" (click)="addFavorite()">
    <div class="addFavorite">
      {{'FilterCustomFavorites.AddFavorite' | translate}}
    </div>
    <div class="icon">
      <mat-icon class="material-icons add">add</mat-icon>
    </div>
  </div>


  <cdk-virtual-scroll-viewport itemSize="48" class="inner-scrollbar-container">

    <div *cdkVirtualFor="let i of query.items" class="submenu-item filter-option">
      <div class="submenu-item-container">
        <div class="submenu-item-state" [class.has-filter-selected]="i.isSelected">
        </div>
        <div class="label" (click)="selectItem(i)">
          {{ i.getLabel() }}
        </div>
        <div class="icon">
          <mat-icon *ngIf="i.isSelected && query.isDirty() && !i.item.getIsReadOnly()"
           (click)="saveDirtyFavorite(i)">save</mat-icon>
        </div>
        <div class="icon">
          <mat-icon (click)="editFavorite(i)">edit</mat-icon>
        </div>
        <div class="icon">
          <mat-icon *ngIf="!i.item.getIsReadOnly()" (click)="deleteFavorite(i)">delete</mat-icon>
        </div>
      </div>
    </div>

  </cdk-virtual-scroll-viewport>


</div>
