import { IGetLabel, UIQuerySimpleFilter, FilterElement, IGetId, IEquatable } from '../FilterElement';
import { OnDestroy, Injectable } from '@angular/core';
import { MetadataService } from '../../../services/metadata.service';

export class OperatorItem implements IGetLabel, IGetId, IEquatable<OperatorItem> {

  operator: string;

  constructor() {
    this.operator = '';
  }

  getLabel(): string {
    return '';
  }

  getId(): number {
    return 1;
  }

  equals(other: OperatorItem): boolean {
    return this.operator === other.operator;
  }

  toString(): string {
    return this.operator;
  }

}

@Injectable({
  providedIn: 'root'
})
export class OperatorUIQuery extends UIQuerySimpleFilter<OperatorItem> implements OnDestroy {

  constructor(private metadataService: MetadataService) {
    super();

    this.initialize();
  }

  public reset(): void {
    this.initialize();
    this.resetOccurred.next();
  }

  public getOperator(): FilterElement<OperatorItem> {
    return this.items[0];
  }

  protected initialize(): void {
    this.items = [
      FilterElement.Build(new OperatorItem())
    ];

    const operator = this.items[0];
    this.setDefaults([operator]);
  }

  protected setDefaults(selectedItems: FilterElement<OperatorItem>[] = null): void {
    this.setDefaultsItems(selectedItems);

    this.defaultItems.forEach(i => {
      if (i) {
        if (i.item) {
          const operator = i.item.operator;
          i.item = new OperatorItem();
          i.item.operator = operator;
        }
      }
    });
  }

  public setDefaultState(operatorValue: string): void {

    const operator = this.getOperator();

    if (operatorValue) {
      operator.isSelected = true;
      operator.item.operator = operatorValue;
    } else {
      operator.isSelected = false;
      operator.item.operator = '';
    }

    this.setDefaults();
  }

  public serialize(): string {
    const operator = this.getOperator();
    if (operator && operator.isSelected) {
      return operator.item.toString();
    } else {
      return undefined;
    }

  }

}

