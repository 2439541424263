import { HttpClient, HttpParams, HttpHeaders, HttpHandler } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { v4 } from 'uuid';

export interface IRequestOptions {
  headers?: HttpHeaders;
  observe?: 'body';
  params?: HttpParams;
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
  body?: any;
}

@Injectable()
export class HttpCachingClient extends HttpClient {
  public static instance: HttpCachingClient = null;

  private uniqueId: string;

  constructor(httpHandler: HttpHandler) {
    super(httpHandler);
    this.uniqueId = v4();
  }

  public invalidate(): void {
    this.uniqueId = v4();
  }

  public getWithCache<T>(endPoint: string, options?: IRequestOptions): Observable<T> {

    if (options) {
      if (options.params) {
        options.params = options.params.set('uniqueid', this.uniqueId);
      } else {
        options.params = new HttpParams().set('uniqueid', this.uniqueId);
      }
    } else {
      options = {
        params: new HttpParams().set('uniqueid', this.uniqueId)
      };
    }

    return this.get<T>(endPoint, options)
      .pipe(
        share()
      );
  }

  public postWithCache<T>(endPoint: string, body: unknown, options?: IRequestOptions): Observable<T> {

    if (options) {
      if (options.params) {
        options.params = options.params.set('uniqueid', this.uniqueId);
      } else {
        options.params = new HttpParams().set('uniqueid', this.uniqueId);
      }
    } else {
      options = {
        params: new HttpParams().set('uniqueid', this.uniqueId)
      };
    }

    return this.post<T>(endPoint, body, options)
      .pipe(
        share()
      );
  }

}

export function httpCachingClientCreator(httpHandler: HttpHandler): HttpCachingClient {
  if (!HttpCachingClient.instance) {
    HttpCachingClient.instance = new HttpCachingClient(httpHandler);
  }

  return HttpCachingClient.instance;
}
