import { IGetLabel, FilterElement, IGetId, IEquatable, UIQueryHierarchyFilterBase } from '../FilterElement';
import { Injectable, OnDestroy } from '@angular/core';
import { MetadataService } from '../../../services/metadata.service';
import { InitDataSelectionLists, Country, State, County, FilterStateGeo } from '../../../services/metadata.service.models';

export class CountryItem implements IGetLabel, IGetId, IEquatable<CountryItem> {
  country: Country;

  constructor(country: Country) {
    this.country = country;
  }

  getLabel(): string {
    return this.country.name;
  }

  getId(): number {
    return this.country.countryId;
  }

  equals(other: CountryItem): boolean {
    if (!other) {
      return false;
    }

    return this.country.countryId === other.country.countryId;
  }
}

export class StateItem implements IGetLabel, IGetId, IEquatable<StateItem> {


  state: State;

  constructor(state: State) {
    this.state = state;
  }

  getLabel(): string {
    return this.state.name;
  }

  getId(): number {
    return this.state.stateId;
  }

  equals(other: StateItem): boolean {
    if (!other) {
      return false;
    }

    return this.state.stateId === other.state.stateId;
  }
}

export class CountyItem implements IGetLabel, IGetId, IEquatable<CountyItem> {

  private county: County;

  constructor(county: County) {
    this.county = county;
  }

  getLabel(): string {
    return this.county.name;
  }

  getId(): number {
    return this.county.countyId;
  }

  equals(other: CountyItem): boolean {
    if (!other) {
      return false;
    }

    return this.county.countyId === other.county.countyId;
  }
}

@Injectable({
  providedIn: 'root'
})
export class GeoUIQuery extends UIQueryHierarchyFilterBase<CountryItem, StateItem> implements OnDestroy {

  public countiesMap: Map<number, FilterElement<CountyItem>[]> = new Map<number, FilterElement<CountyItem>[]>();

  private defaultCountiesMap: Map<number, FilterElement<CountyItem>[]> = new Map<number, FilterElement<CountyItem>[]>();

  constructor(private metadataService: MetadataService) {
    super();

    this.initialize();
  }

  protected initialize(): void {

    this.subscription.add(this.metadataService.fetchInitDataSelectionLists().subscribe((d: InitDataSelectionLists) => {

      if (d.Countries) {
        this.rootItems = [];
        d.Countries.forEach(c => {
          this.rootItems.push(FilterElement.Build(new CountryItem(c)));
        });
      }
    }));

  }

  public isDirty(): boolean {
    const selectedCountry = this.getSelectedCountry();
    const selectedStates = this.getSelectedCachedStates(selectedCountry);
    if (this.isDirtyInternal(selectedCountry, selectedStates)) {
      return true;
    }

    if (selectedStates) {
      for (const selectedState of selectedStates) {
        // isDirtyInternal ensures that the selected and default states match
        const defaultState = this.defaultChildItems.find(e => e.item.equals(selectedState.item));
        const selectedCounties = this.getSelectedCachedCounties(selectedState);
        const defaultCounties = this.defaultCountiesMap.get(defaultState.item.getId());
        let selectedDefaultCounties: FilterElement<CountyItem>[];
        if (defaultCounties) {
          selectedDefaultCounties = defaultCounties.filter(e => e.isSelected);
        }

        if (selectedCounties) {
          if (!selectedDefaultCounties) {
            return true;
          }
          if (selectedCounties.length !== selectedDefaultCounties.length) {
            return true;
          }

          for (const defaultCounty of selectedDefaultCounties) {
            const selectedCounty = selectedCounties.find(e => e.item.equals(defaultCounty.item));
            if (!selectedCounty) {
              return true;
            }
          }

        } else if (selectedDefaultCounties && selectedDefaultCounties.length > 0) {
          return true;
        }
      }
    }

    return false;
  }

  public reset(): void {
    this.resetInternal();
    this.countiesMap.forEach(countiesFilterElements => countiesFilterElements.forEach(f => f.resetSelection()));
  }

  public async getStates(country: FilterElement<CountryItem>): Promise<FilterElement<StateItem>[]> {
    const statesEntries = this.childItemsMap.get(country.item.getId());
    if (!statesEntries) {
      const states = await this.metadataService.getStateSelectionListByCountry(country.item.getId()).toPromise();

      const statesFilter: FilterElement<StateItem>[] = [];
      if (states) {
        states.forEach(f => {
          statesFilter.push(FilterElement.Build(new StateItem(f)));
        });
      }

      this.childItemsMap.set(country.item.getId(), statesFilter);
    }

    return Promise.resolve(this.childItemsMap.get(country.item.getId()));
  }

  public async getCounties(states: FilterElement<StateItem>[]): Promise<FilterElement<CountyItem>[]> {

    const cachedCounties: FilterElement<CountyItem>[] = [];
    const missingStateCountiesIds: number[] = [];
    states.forEach(s => {
      const counties = this.countiesMap.get(s.item.getId());
      if (counties) {
        Array.prototype.push.apply(cachedCounties, counties);
      } else {
        missingStateCountiesIds.push(s.item.getId());
      }
    });

    if (missingStateCountiesIds.length > 0) {
      const countiesSelection = await this.metadataService.getCountySelectionListByStates(missingStateCountiesIds).toPromise();

      if (countiesSelection) {
        missingStateCountiesIds.forEach(stateId => {
          const counties = countiesSelection[stateId];
          const countiesFilter = counties.map(c => FilterElement.Build(new CountyItem(c)));
          this.countiesMap.set(stateId, countiesFilter);
          Array.prototype.push.apply(cachedCounties, countiesFilter);
        });
      }
    }

    return Promise.resolve(cachedCounties);
  }

  public getSelectedCountry(): FilterElement<CountryItem> {
    return this.getSelectedRootItem();
  }

  public async getSelectedStates(): Promise<FilterElement<StateItem>[]> {
    return await this.getSelectedChildItems(this.getSelectedCountry(), this.getStates.bind(this));
  }

  public getSelectedCachedStates(country: FilterElement<CountryItem>): FilterElement<StateItem>[] {
    return this.getSelectedCachedChildItems(country);
  }

  public getCachedCounties(state: FilterElement<StateItem>): FilterElement<CountyItem>[] {
    return this.countiesMap.get(state.item.getId());
  }

  private getSelectedCachedCounties(state: FilterElement<StateItem>): FilterElement<CountyItem>[] {
    const cachedCounties = this.getCachedCounties(state);
    if (cachedCounties) {
      return cachedCounties.filter(e => e.isSelected);
    }

    return undefined;
  }

  public async getSelectedCounties(): Promise<FilterElement<CountyItem>[]> {
    const selectedStates = await this.getSelectedStates();
    if (!selectedStates) {
      return undefined;
    }

    const counties: FilterElement<CountyItem>[] = await this.getCounties(selectedStates);
    const selectedCounties = counties.filter(c => c.isSelected);
    if (selectedCounties.length === 0) {
      return undefined;
    }

    return selectedCounties;
  }

  async setDefaultState(geo: FilterStateGeo): Promise<void> {
    let countryId = -1;
    let statesIds: number[];
    let countiesIds: { [key: number]: number[] };
    if (geo) {
      countryId = geo.countryId;
      statesIds = geo.statesIds;
      countiesIds = geo.countiesIds;
    }

    await this.setDefaultStateInternal(countryId, this.getStates.bind(this), statesIds);

    this.defaultCountiesMap.clear();

    if (!this.defaultRootItem || this.defaultChildItems.length === 0 || !countiesIds) {
      return;
    }

    await this.getCounties(this.defaultChildItems);
    const countyStateIds = Object.keys(countiesIds).map(stateIdString => +stateIdString);
    countyStateIds.forEach(stateId => {
      const countyIds = countiesIds[stateId];
      if (countyIds) {
        const state = this.defaultChildItems.find(e => e.item.getId() === stateId);
        if (state) {
          const stateCounties = this.getCachedCounties(state);
          const defaultCountyOriginals = stateCounties.filter(e => countyIds.includes(e.item.getId()));
          defaultCountyOriginals.forEach(e => e.isSelected = true);

          const defaultCounties = defaultCountyOriginals.map(e => ({ ...e }) as FilterElement<CountyItem>);
          this.defaultCountiesMap.set(stateId, defaultCounties);
        }
      }
    });
  }

  public async serialize(): Promise<FilterStateGeo> {
    const selectedCountry = this.getSelectedCountry();
    if (!selectedCountry) {
      return undefined;
    }

    const countryId = selectedCountry.item.getId();
    const result: FilterStateGeo = {
      countryId
    };

    const selectedStates = await this.getSelectedStates();
    let countyIds: { [key: number]: number[] };
    if (selectedStates) {
      const selectedStatesIds = selectedStates.map(e => e.item.getId());
      result.statesIds = selectedStatesIds;

      selectedStates.forEach(state => {
        const selectedCounties = this.getSelectedCachedCounties(state);
        if (selectedCounties) {
          if (!countyIds) {
            countyIds = {};
          }
          countyIds[state.item.getId()] = selectedCounties.map(e => e.item.getId());
        }
      });
    }

    if (countyIds) {
      result.countiesIds = countyIds;
    }

    return result;
  }
}
