export class NavigationKeys {
  public static readonly Default = '';
  public static readonly Auth = 'auth';
  public static readonly Wildcard = '*';
  public static readonly Mapping = 'mapping';
  public static readonly Results = 'results';
  public static readonly Summary = 'summary';
  public static readonly Reports = 'reports';
  public static readonly ReportsWithWildcard = `${NavigationKeys.Reports}/${NavigationKeys.Wildcard}`;
  public static readonly Exports = 'exports';
  public static readonly Forbidden = 'forbidden';

  // Admin Screens are handed by their specific nav keys classes
}

export class AssaysNavigationKeys {
  public static readonly Module = 'assays';
  public static readonly List = '';
  public static readonly ListFullPath = `${AssaysNavigationKeys.Module}/${AssaysNavigationKeys.List}`;
  public static readonly Edit = 'assay';
  public static readonly EditWithIdArg = `${AssaysNavigationKeys.Edit}/:id`;
  public static readonly EditFullPath = `${AssaysNavigationKeys.Module}/${AssaysNavigationKeys.Edit}`;
  public static readonly EditFullPathWithId = `${AssaysNavigationKeys.Module}/${AssaysNavigationKeys.EditWithIdArg}`;
}

export class UsersNavigationKeys {
  public static readonly Module = 'users';
  public static readonly Search = '';
  public static readonly SearchFullPath = `${UsersNavigationKeys.Module}/${UsersNavigationKeys.Search}`;
  public static readonly Edit = 'user';
  public static readonly EditWithIdArg = `${UsersNavigationKeys.Edit}/:id`;
  public static readonly EditFullPath = `${UsersNavigationKeys.Module}/${UsersNavigationKeys.Edit}`;
  public static readonly EditFullPathWithId = `${UsersNavigationKeys.Module}/${UsersNavigationKeys.EditWithIdArg}`;
  public static readonly Unapproved = 'unapproved';
  public static readonly UnapprovedFullPath = `${UsersNavigationKeys.Module}/${UsersNavigationKeys.Unapproved}`;
}

export class OrganizationsNavigationKeys {
  public static readonly Module = 'organizations';
  public static readonly Search = '';
  public static readonly SearchFullPath = `${OrganizationsNavigationKeys.Module}/${OrganizationsNavigationKeys.Search}`;
  public static readonly Edit = 'organization';
  public static readonly EditWithIdArg = `${OrganizationsNavigationKeys.Edit}/:id`;
  public static readonly EditFullPath = `${OrganizationsNavigationKeys.Module}/${OrganizationsNavigationKeys.Edit}`;
  public static readonly EditFullPathWithId = `${OrganizationsNavigationKeys.Module}/${OrganizationsNavigationKeys.EditWithIdArg}`;
}

export class FacilitiesNavigationKeys {
  public static readonly Module = 'facilities';
  public static readonly Search = '';
  public static readonly SearchFullPath = `${FacilitiesNavigationKeys.Module}/${FacilitiesNavigationKeys.Search}`;
  public static readonly Edit = 'facility';
  public static readonly EditWithIdArg = `${FacilitiesNavigationKeys.Edit}/:id`;
  public static readonly EditWithIdAndOrgId = `${FacilitiesNavigationKeys.Edit}/:id/:orgId`;
  public static readonly EditWithIdAndOrgIdAndOrphanId = `${FacilitiesNavigationKeys.Edit}/:id/:orgId/:orphanId`;
  public static readonly EditFullPath = `${FacilitiesNavigationKeys.Module}/${FacilitiesNavigationKeys.Edit}`;
  public static readonly EditFullPathWithId = `${FacilitiesNavigationKeys.Module}/${FacilitiesNavigationKeys.EditWithIdArg}`;
  public static readonly EditFullPathWithIdAndOrgId = `${FacilitiesNavigationKeys.Module}/${FacilitiesNavigationKeys.EditWithIdAndOrgId}`;
  public static readonly EditFullPathWithIdAndOrgIdAndOrphanId = `${FacilitiesNavigationKeys.Module}/${FacilitiesNavigationKeys.EditWithIdAndOrgIdAndOrphanId}`;
}

export class InstrumentsNavigationKeys {
  public static readonly Module = 'instruments';
  public static readonly Search = '';
  public static readonly SearchFullPath = `${InstrumentsNavigationKeys.Module}/${InstrumentsNavigationKeys.Search}`;
  public static readonly Edit = 'instrument';
  public static readonly EditWithIdArg = `${InstrumentsNavigationKeys.Edit}/:id`;  
  public static readonly EditFullPath = `${InstrumentsNavigationKeys.Module}/${InstrumentsNavigationKeys.Edit}`;
  public static readonly EditFullPathWithId = `${InstrumentsNavigationKeys.Module}/${InstrumentsNavigationKeys.EditWithIdArg}`;
  public static readonly NewFromFacilityEdit = `${InstrumentsNavigationKeys.Edit}/:id/:orgId/:facId`;  
}

export class OrphansNavigationKeys {
  public static readonly Module = 'orphans';
  public static readonly Search = '';
  public static readonly SearchFullPath = `${OrphansNavigationKeys.Module}/${OrphansNavigationKeys.Search}`;
  public static readonly Edit = 'orphan';
  public static readonly EditWithIdArg = `${OrphansNavigationKeys.Edit}/:serialnumber/:deviceaddress/:zipcode`;
  public static readonly EditFullPath = `${OrphansNavigationKeys.Module}/${OrphansNavigationKeys.Edit}`;
  public static readonly EditFullPathWithId = `${OrphansNavigationKeys.Module}/${OrphansNavigationKeys.EditWithIdArg}`;
  public static readonly ResultsList = 'details';
  public static readonly ResultsListWithId = `${OrphansNavigationKeys.ResultsList}/:serialnumber/:deviceaddress/:zipcode`;
  public static readonly ResultsListFullPath = `${OrphansNavigationKeys.Module}/${OrphansNavigationKeys.ResultsList}`;
  public static readonly ResultsListFullPathWithId = `${OrphansNavigationKeys.Module}/${OrphansNavigationKeys.ResultsListWithId}`;
}

export class PairedDevicesNavigationKeys {
  public static readonly Module = 'paired-devices';
  public static readonly Search = '';
  public static readonly SearchFullPath = `${PairedDevicesNavigationKeys.Module}/${PairedDevicesNavigationKeys.Search}`;
  public static readonly Edit = 'paired-device';
  public static readonly EditWithIdArg = `${PairedDevicesNavigationKeys.Edit}/:id`; 
  public static readonly EditFullPath = `${PairedDevicesNavigationKeys.Module}/${PairedDevicesNavigationKeys.Edit}`;  
  public static readonly EditFullPathWithId = `${PairedDevicesNavigationKeys.Module}/${PairedDevicesNavigationKeys.EditWithIdArg}`;
  public static readonly NewFromFacilityEdit = `${PairedDevicesNavigationKeys.Edit}/:id/:orgId/:facId`;  
}

export class ReportNavigationKeys {
  public static readonly Module = 'reports';
}

export class ResultsApiNavigationKeys {
  public static readonly Module = 'result-apis';
  public static readonly List = '';
  public static readonly ListFullPath = `${ResultsApiNavigationKeys.Module}/${ResultsApiNavigationKeys.List}`;
  public static readonly Edit = 'result-api';
  public static readonly EditWithIdArg = `${ResultsApiNavigationKeys.Edit}/:id`;
  public static readonly EditFullPath = `${ResultsApiNavigationKeys.Module}/${ResultsApiNavigationKeys.Edit}`;
  public static readonly EditFullPathWithId = `${ResultsApiNavigationKeys.Module}/${ResultsApiNavigationKeys.EditWithIdArg}`;
}
